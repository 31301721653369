import css from "../../css/component/main/_index.module.scss";
import img_1 from "../../assets/images/man1.png";
import img_2 from "../../assets/images/man2.png";
import img_3 from "../../assets/images/man3.png";
import img_4 from "../../assets/images/man4.png";
import img_5 from "../../assets/images/man5.png";
import logo from "../../assets/images/noNameLogo.png";

import { SectionTitle } from "../main/component/sectionTitle";

export const NuPage2 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>Nahdlatul Ulama</h1>
              <h3>Nahdlatul Ulama (NU)의 역사를 살펴보세요. </h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="Nahdlatul Ulama란?" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>Nahdlatul Ulama (NU)</h1>
            <p className={css.infoBoxWrapper}>
              <b>Nahdlatul Ulama (NU)</b>은{" "}
              <b className={css.green}>“울라마의 부흥”</b>을 의미합니다.
              <br />
              인도네시아의 이슬람 단체으로써, 1926년에 설립되어 현재까지
              인도네시아 이슬람 사회와 정치에 <b>지대한 영향을 미쳐온 단체</b>
              입니다,{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.checkIcon} /> Nahdlatul Ulama
              </h3>
              <p>
                2021년 기준으로 회원 수가 9천만 명 이상에 달해 세계 최대 이슬람
                조직으로 알려져 있고, NU는 학교와 병원에 자금을 지원하고, 빈곤
                완화를 위해 지역 사회를 조직하는 자선 단체로도 활동하고
                있습니다.{" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.peopleIcon} /> 설립과 목적
              </h3>
              <p>
                ‘NU’는 샤피이 학파의 이슬람을 옹호하고, 종교적 가치를 유지하려는
                울라마(이슬람 학자들)와 상인들이 주도하여 설립되었습니다. 당시
                NU는 전통주의 이전통주의슬람을 보호하고 회원들의 경제적 권익을
                수호하려는 목적으로 활동을 시작했습니다.{" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.smailIcon} /> 사회적 활동과 역할
              </h3>
              <p>
                ‘NU’는 단순히 종교 조직에 그치지 않고, 사회적 역할도 활발히
                수행합니다. 학교와 병원 등을 운영하며 교육과 의료 지원을
                제공하고, 빈곤 문제 해결을 위한 다양한 지역사회 활동을 전개하고
                있습니다. 특히 NU의 지도자들은 인도네시아 사회와의 조화를
                강조하며, 이를 “이슬람 누산타라”라고 부르기도 합니다. 이슬람
                누산타라는 인도네시아 문화와 전통을 존중하고 통합하는 온건적
                이슬람을 추구 합니다.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"] + " " + css.gray}>
          <div>
            <div className={css.personItemWrapper}>
              <span className={css.title}></span>
              <span className={css.name}></span>
              <span className={css.info}></span>
            </div>
            <SectionTitle title="NU 조직도" description="" />
          </div>
          <div className={css.personItemWrapper}>
            <img src={img_1} />
            <span className={css.title}>총 회장</span>
            <span className={css.name}>야히아 코릴 스타크푸프</span>
            <span className={css.info}></span>
          </div>{" "}
          <div className={css.personItemWrapper}>
            <img style={{ objectFit: "contain" }} src={img_2} />
            <span className={css.title}>총 비서</span>
            <span className={css.name}>사이풀 바하리</span>
            <span className={css.info}>현 사회부장관 (2024~진행중)</span>
          </div>
          <div className={css.noticeListWrapper + " " + css.center}>
            <div className={css.personItemWrapper}>
              <img src={img_3} />
              <span className={css.title}>안소르(청년회) 회장</span>
              <span className={css.name}>야쿠트 콜릴 카우마스</span>
              <span className={css.info}>전 사회부장관 (2018~2024)</span>
            </div>
            <div className={css.personItemWrapper}>
              <img src={img_4} />
              <span className={css.title}>무슬리마트(여성회) 회장</span>
              <span className={css.name}>코피파 인다르 파라완사</span>
              <span className={css.info}>전 종교부장관 (2020~2024)</span>
            </div>
            <div className={css.personItemWrapper}>
              <img src={img_5} />
              <span className={css.title}>창립 이념과 방향성 유지</span>
              <span className={css.name}>NU고문 및 원로학자</span>
              <span className={css.info}></span>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>Nahdlatul Ulama (NU) 역사</h1>
            <p className={css.infoBoxWrapper}>
              NU는 인도네시아에서 <b>이슬람적 가치와 전통을 보존</b>하면서도
              현대적 사회 변화에 기여하는 조직으로 성장하고 있습니다. 또한
              국제적으로도 인도네시아{" "}
              <b className={css.green}>이슬람의 평화적 이미지를 확산</b>하는 데
              중요한 역할을 맡고 있습니다.{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1926년</h3>
              <p>
                <b>• Nahdlatul Ulama 창립</b>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1945년</h3>
              <p>
                <b>• 인도네시아 독립 투쟁 참여</b>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1952년</h3>
              <p>
                <b>• 인도네시아 정당으로의 활동</b>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1973년</h3>
              <p>
                <b>• 합당 및 정치적 변화 </b>
                <span>
                  • 수하르토 정권 하에서 인도네시아의 여러 이슬람 정당들이
                  하나로 합쳐져 **통합개발당 (PPP)**이라는 이름의 정당이
                  되었습니다.
                </span>
                <span>
                  • 이 시기 NU는 정치적 정당으로서의 역할보다는 종교적, 사회적
                  활동에 집중하기 시작했습니다.
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1984년</h3>
              <p>
                <b>• 탈정치화 선언 </b>
                <span>
                  • **아브두라만 와히드 (Abdurrahman Wahid)**가 NU의 지도자로
                  선출되면서 NU는 정치에서 물러나 순수한 종교 및 사회 활동에
                  전념하기로 했습니다.
                </span>
                <span>
                  • 와히드는 NU의 종교적 역할에 중점을 두며, 정치에서 벗어난
                  탈정치화를 선언했습니다.
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>1998년</h3>
              <p>
                <b>• 민주화와 사회적 영향력 강화 </b>
                <b>• 민주화 운동</b>
                <span>
                  1998년 수하르토 정권의 붕괴와 함께 인도네시아는 민주화를
                  맞이했습니다. 이 시기 NU는 종교적 관용과 다원주의 가치를
                  강조하며, 다양한 사회개혁 운동에 앞장섰습니다.
                </span>
                <b>• 아브두라만 와히드 대통령 선출 (1999년)</b>
                <span>
                  NU의 전 지도자인 와히드가 인도네시아 최초의 민주적 선거를 통해
                  대통령으로 당선되었습니다. 그는 인도네시아의 민주주의와 다문화
                  사회의 중요성을 강조하며 개혁을 추진했습니다.
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>2000년대 이후</h3>
              <p>
                <b>• 사회복지 및 교육 활동</b>

                <span>
                  NU는 인도네시아 전역에서 교육, 의료, 사회복지 사업을 활발히
                  전개하고 있으며, 빈곤층 지원 및 농촌 지역 개발에 주력하고
                  있습니다.
                </span>
                <b> • 종교적 관용 강화</b>
                <span>
                  NU는 종교적 관용과 평화를 중시하는 이슬람 단체로, 다문화와
                  다종교 사회인 인도네시아의 정체성을 지키기 위해 노력하고
                  있습니다.
                </span>
                <b>• 국제적 연대</b>
                <span>
                  NU는 종교적 평화와 관용을 국제적으로 확산하고, 종교 간 대화와
                  협력을 통해 평화와 화합을 구축하기 위한 글로벌 네트워크를
                  확대하고 있습니다.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
