import css from "../../../css/component/main/_index.module.scss";
import { SectionTitle } from "../../main/component/sectionTitle";
import halalProcess from "../../../assets/images/halalProcess.png";
import logo from "../../../assets/images/BPJPH.png";
export const HalalPage3 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>할랄 인증 시장 현황</h1>
              <h3>
                국내외 할랄시장 현황화 국내 인증 현황을 알아볼 수 있습니다.
              </h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="할랄 인증 시장 현황" description="" />
        </div>

        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄인증 현황</h1>
            <p className={css.infoBoxWrapper}>
              <b>2014년 할랄보장법, 2019년/2021년 할랄보장법</b>에 대한 시행규정
              발표로
              <br />
              <b>인도네시아로 수입·유통·판매되는 제품</b>은{" "}
              <b className={css.green}>할랄인증</b>을 요구하고 있습니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              인도네시아의 인구는 2억8000만 명에 육박하며 이중 약 87%가
              무슬림입니다. 2020년 인도네시아 할랄시장 규모는 1840억 달러로 전
              세계 4위 규모(전 세계 할랄 소비 11.3% 차지)이며, 할랄 산업 관련
              모든 분야가 2020년부터 2025년까지 연평균 13% 가량 성장(2816억
              달러)할 것으로 보입니다. 2020년 기준 전체 할랄 산업에서 식품이
              차지하는 비중은 73%로 1350억 달러이며, 2020년~2025년 연평균
              성장률은 14.64%를 기록할 것으로 예상됩니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              인도네시아 정부의 다양한 할랄산업 육성 정책 중 우리 기업에 가장 큰
              영향을 끼칠 정책은 <b>할랄인증 의무화 정책</b>이며, 2014년
              할랄보장법, 2019년/2021년 할랄보장법에 대한 시행규정 발표로
              인도네시아로 수입·유통·판매되는 제품은 할랄인증을 요구하고
              있습니다. 정부령은 제품 유형별로 계도기간을 명시하고 있으며,
              2024년 10월 17일 이후 식품과 음료에 대한 할랄인증 의무화를
              시작으로 화장품, 의약품, 가정용품 등으로 확대될 예정입니다.
            </p>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>국내 할랄인증 현황</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>식품산업 </h3>
              <p>
                <span>
                  CJ제일제당:밥, 김치 등의 할랄 인증 취득 (대상: 마요네즈 할랄
                  인증 획득)
                </span>
                <span>오뚜기:라면 및 소스 제품군의 할랄 인증 취득 </span>
                <span>풀무원:라면등 식품류 할랄 인증 획득 </span>
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>식품산업 영향력 </h3>
              <p>
                <span>
                  시장 확대 [중동 및 동남아시아 시장에서 할랄 인증 제품의 판매
                  증가]{" "}
                </span>
                <span>
                  제품 다양화 [현지 소비자 취향에 맞춘 다양한 할랄 제품 출시]{" "}
                </span>
                <span>
                  브랜드 인지도 상승 [글로벌 할랄 시장에서 브랜드 인지도 상승]{" "}
                </span>
                <span>
                  연구개발 [할랄 인증 기준을 충족하는 신제품 개발 지속]
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>화장품 산업</h3>
              <p>
                <span>
                  아모레: 스킨케어 및 메이크업 제품군의 할랄 인증 획득
                </span>
                <span>md생활건강: 주요 화장품 제품에 할랄 인증 취득</span>
                <span>더페이스샵:라다양한 스킨케어 제품의 할랄 인증 획득</span>
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>화장품 산업 영향력 </h3>
              <p>
                <span>
                  판매 채널 확대 [할랄 인증을 통해 무슬림 국가에서의 유통 채널
                  확장]
                </span>
                <span>
                  소비자 신뢰 확보[무슬림 소비자들로부터 높은 신뢰와 호응]
                </span>
                <span>신제품 개발[할랄 인증 기준에 맞춘 신제품 지속 개발]</span>
                <span>
                  마케팅 전략 강화[할랄 인증 제품을 강조한 마케팅 캠페인 전개]
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>의약품 산업</h3>
              <p>
                <span>
                  대웅제약: 일부 전문 의약품 및 건강보조제에 할랄 인증 취득
                </span>
                <span>한미약품: 비타민 및 특정 치료제에 할랄 인증 획득</span>
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>의약품 산업 영향력 </h3>
              <p>
                <span>
                  시장 확대 [중동 및 동남아시아 시장에서 할랄 인증 제품의 판매
                  증가]
                </span>
                <span>
                  제품 다양화 [현지 소비자 취향에 맞춘 다양한 할랄 제품 출시]
                </span>
                <span>
                  브랜드 인지도 상승 [글로벌 할랄 시장에서 브랜드 인지도 상승]
                </span>
                <span>
                  연구개발 [할랄 인증 기준을 충족하는 신제품 개발 지속]
                </span>
              </p>
            </div>
          </div>
          <div className={css.sectionFirstContentWrapper}>
            <h1>국내 할랄인증 현황 결론</h1>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.tableWrapper}>
              <table className={css["dataTable"]}>
                <thead>
                  <th>제품 유형</th>
                  <th>계도 기간</th>
                </thead>
                <tbody>
                  <tr>
                    <td>식품(식음료)</td>
                    <td>2019.10.17 ~ 2024.10.17</td>
                  </tr>
                  <tr>
                    <td>건강 보조식품, 전통의약품</td>
                    <td>2019.10.17 ~ 2026.10.17</td>
                  </tr>
                  <tr>
                    <td>가정용품, 이슬람 종교용품 문구류, 사무용품</td>
                    <td>2019.10.17 ~ 2026.10.17</td>
                  </tr>
                  <tr>
                    <td>화장품 화학 유전공학제품</td>
                    <td>2019.10.17 ~ 2026.10.17</td>
                  </tr>
                  <tr>
                    <td>의류, 액세서리, 헤드유닛</td>
                    <td>2019.10.17 ~ 2026.10.17</td>
                  </tr>
                  <tr>
                    <td>의료기기 (A Grade)</td>
                    <td>2019.10.17 ~ 2026.10.17</td>
                  </tr>
                  <tr>
                    <td>의약외품(OTC)</td>
                    <td>2019.10.17 ~ 2029.10.17</td>
                  </tr>
                  <tr>
                    <td>의료기기 (B Grade)</td>
                    <td>2019.10.17 ~ 2029.10.17</td>
                  </tr>
                  <tr>
                    <td>의료기기 (C Grade)</td>
                    <td>2019.10.17 ~ 2034.10.17</td>
                  </tr>
                  <tr>
                    <td>재외의약품, 향정신성 의약품</td>
                    <td>2019.10.17 ~ 2034.10.17</td>
                  </tr>
                </tbody>
              </table>{" "}
              <span>출처: Indonesia Halal Report, 2021/2022</span>
            </div>{" "}
            <div className={css.tableWrapper}>
              <table className={css["dataTable"]}>
                <thead>
                  <th>분야</th>
                  <th>2020년 규모 (십억달러)</th>
                  <th>2020-2025 연평균 성장률 (%)</th>
                  <th>2019~2020 성장률 (%)</th>
                </thead>{" "}
                <tbody>
                  <tr>
                    <td>식품</td>
                    <td>135.0</td>
                    <td>14.6</td>
                    <td>-6.4</td>
                  </tr>
                  <tr>
                    <td>패션</td>
                    <td>15.6</td>
                    <td>8.3</td>
                    <td>-5.0</td>
                  </tr>
                  <tr>
                    <td>제약</td>
                    <td>5.1</td>
                    <td>5.8</td>
                    <td>-4.3</td>
                  </tr>
                  <tr>
                    <td>화장품</td>
                    <td>4.2</td>
                    <td>12.6</td>
                    <td>0.7</td>
                  </tr>
                  <tr>
                    <td>미디어</td>
                    <td>20.7</td>
                    <td>9.0</td>
                    <td>-4.7</td>
                  </tr>
                  <tr>
                    <td>관광</td>
                    <td>3.4</td>
                    <td>19.0</td>
                    <td> -70.0</td>
                  </tr>
                </tbody>
              </table>
              <span>출처: Indonesia Halal Report, 2021/2022</span>
            </div>
            <p className={css.contentWrapper}>
              국내 기업들은 할랄 인증을 통해 다양한 산업군에서 글로벌 시장
              진출을 가속화하고 있으며, 할랄 인증은 소비자의 신뢰 확보 및 판매
              증가에 크게 기여하고 있습니다. 할랄 인증 취득 후, 이들 기업은
              제품의 품질과 안전성을 더욱 강화하고, 다양한 신제품을 개발하여
              시장 경쟁력을 높이고 있습니다. 이러한 노력은 중동 및 동남아시아와
              같은 주요 무슬림 시장에서의 성공적인 비즈니스 전개를 가능하게 하고
              있습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
