import { getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { noAuth } from "../../../setting/setting";

const url = noAuth + "/user/reg";
const jwt = () => getJwt();

// ID 중복 확인
export const checkIdDuplication = (info: { id: string }) => {
  return getPostData({
    path: `${url}/duplication/id`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 인증 코드 전송
export const sendVerifyCode = (info: { email: string }) => {
  return getPostData({
    path: `${url}/verify/code/send`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 인증 코드 확인
export const checkVerifyCode = (info: { code: string; email: string }) => {
  return getPostData({
    path: `${url}/verify/code/check`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// SalesPerson 목록 조회
export const getSalesPersonList = (
  info: {
    salesPersonIdx?: number | null;
  } | null
) => {
  return getData({
    path: `${url}/get/sales`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 사용자 등록
export const regUser = async (data: {
  id: string;
  companyName: string;
  managerName: string;
  salesPersonIdx: string;
  phone: string;
  fax: string;
  pw: string;
  email: string;
  file: File;
  postNumber: string;
  address: string;
  detailAddress: string;
}) => {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("companyName", data.companyName);
  formData.append("managerName", data.managerName);
  formData.append("salesPersonIdx", data.salesPersonIdx);
  formData.append("phone", data.phone);
  formData.append("fax", data.fax);
  formData.append("pw", data.pw);
  formData.append("email", data.email);
  formData.append("file", data.file);
  formData.append("postNumber", data.postNumber);
  formData.append("address", data.address);
  formData.append("detailAddress", data.detailAddress);
  return getPostData({
    path: `${url}/`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};
