import css from "../../css/component/main/_index.module.scss";
import Halal_Indonesia_img from "../../assets/images/concert/Halal_Indonesia_img.png";
import bpomLogo from "../../assets/images/concert/bpomLogo.png";
import merit1 from "../../assets/images/concert/merit1.png";
import merit2 from "../../assets/images/concert/merit2.png";
import cart from "../../assets/images/concert/cart1.png";
import indonesiaMarketProduct from "../../assets/images/concert/indonesiaMarketProduct.png";
import halalharamProduct from "../../assets/images/concert/halalharamProduct.png";
import { SectionTitle } from "../main/component/sectionTitle";
export const Concert2 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>인도네시아 진출 이슈</h1>
              <h3> 인도네시아에 진출에 왜 어려움을 겪는지 알아보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="인도네시아 진출 이슈" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아 진출 필수 요소 및 애로사항</h1>
            <p className={css.infoBoxWrapper}>
              당사는 한국 기업의 인도네시아 진출에 있어 언어, 사업자 행정절차,
              HALAL/BPOM 인증, 운영비용(현지사무소, 인력운용 등)에 대해 전문적인
              노하우를 통해 안정적 진출이 가능하도록 솔루션을 제공합니다.{" "}
            </p>
          </div>{" "}
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              당사는 한국 기업의 인도네시아 진출에 있어 언어, 사업자 행정절차,
              HALAL/BPOM 인증, 운영비용(현지사무소, 인력운용 등)에 대해 전문적인
              노하우를 통해 안정적 진출이 가능하도록 솔루션을 제공합니다. 이
              모든 진출 전략에 대한 컨설팅과 지원은 HALAL & BPOM 인증을 받는
              기간 동안 준비 되어 시간적, 비용적으로 효율성을 높일 수 있습니다.{" "}
            </p>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄(Halal) 인증</h1>
            <p className={css.infoBoxWrapper}>
              <b className={css.green}>할랄</b>이란 이슬람 경전인{" "}
              <b>
                ‘코란’에서는 ‘받아들일 수 있는, 허용된, 합법적인’ 이라는 의미
              </b>
              입니다. <br />이 용어가 식품이나 기타 소비재와 접목됐을 때는{" "}
              <b className={css.green}>
                ‘무슬림이 사용하거나 소비하도록 신이 허용된’
              </b>
              이라는 말로 이해할 수 있습니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.iconImgWrapper} style={{ height: "auto" }}>
              <img
                src={Halal_Indonesia_img}
                style={{
                  maxWidth: "236px",
                  height: "auto",
                  margin: "0 auto",
                }}
              />
              <span>인도네시아 신(新)할랄인증 마크</span>
            </div>
          </div>{" "}
          <div
            className={
              css.sectionTwoContentWrapper + " " + css.row + " " + css.mobileCol
            }
          >
            <div className={css.contentInfoWrapper}>
              <h3>BPJPH</h3>
              <p>
                BPJPH (Badan Penyelenggara Jaminan Produk Hala)는 인도네시아
                종교부 산하 할랄인증을 담당하는 정부기관 입니다.BPJPH에 할랄
                인증을 신청하고 최종 인증서를 발급받을 수 있습니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>할랄시장 규모</h3>
              <p>
                인도네시아 전체인구의 86.7% (2억 3천만명) 무슬림입니다.
                인도네시아는 1,840억 달러의 할랄시장 규모를 가지고 있습니다.
                (2020년 / GIER 자료 참조){" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>의무화를 통한 인증강화</h3>
              <p>
                2019년 식품에 대한 할랄 인증 의무화 방침 후 5년간의 유예기간을
                두었습니다.2024년 10월 17일 모든 식품 및 음료에 대한 할랄 인증
                의무화를 시작했습니다.2026년까지 10월 화장품, 의약, 식약
                의무화를 진행할 예정입니다.
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄 | 하람 식품</h1>
          </div>
          <div
            className={
              css.sectionTwoContentWrapper + " " + css.row + " " + css.mobileCol
            }
          >
            <div className={css.contentInfoWrapper + " " + css.green}>
              <h3>
                <span className={css.doubleCheckIcon} />
                할랄 식품
              </h3>
              <p className={css.space}>
                받아들일 수 있는, 허용된, 합법적인, 품질 | 안전 | 위생 | 청결 |
                영양에 있어 인증된 식품{" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3 className={css.gray}>
                <span className={css.xIcon} />
                하람 식품
              </h3>
              <p className={css.space}>
                허락되지 않은, 금지된, 인증되지 않은, 대표적으로 이슬람 금기
                식품인 돼지고기, 민물고기 등의 식품{" "}
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>BPOM 인증</h1>
            <p className={css.infoBoxWrapper}>
              <b className={css.green}>
                {" "}
                BPOM(Badan Pengawas Obat dan Makanan)
              </b>
              은 <b>인도네시아 식약청</b>으로 의약품, 식품, 건강보조식품,
              전통의약품, 화장품에 대한 허가를 담당합니다.
              <br /> 수입자가 BPOM 규정을 지키지 않을 경우, 식약청은 이미 유통된
              제품이라 하더라도{" "}
              <b className={css.green}>
                유통된 마트 일체에서 해당 위반 제품을 회수
              </b>
              하도록 조치하고 있습니다.{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.iconImgWrapper} style={{ height: "auto" }}>
              <img
                src={bpomLogo}
                style={{
                  maxWidth: "236px",
                  height: "auto",
                  margin: "0 auto",
                }}
              />
              <span>인도네시아 식약청(BPOM) 마크</span>
            </div>
          </div>{" "}
          <div
            className={
              css.sectionTwoContentWrapper + " " + css.row + " " + css.mobileCol
            }
          >
            <div className={css.contentInfoWrapper}>
              <h3>식품(일반 가공 식품)</h3>
              <p>HACPP / ISO 22000 제출 필수</p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>코스메틱 (화장품)</h3>
              <p>신원재료 등록 및 기능성 입증 자료 요구</p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>전통의약품 및 건강기능 식품</h3>
              <p>GMP 제출 필수 </p>
            </div>
          </div>
        </div>

        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인증 필요성 </h1>{" "}
            <div className={css.sectionTwoContentWrapper + " " + css.year}>
              <div style={{ height: "auto" }} className={css.imgRowWrapper}>
                <img
                  src={merit1}
                  style={{ objectFit: "initial", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인증방법과 시간/비용 문제점, 인증 이후 전략 문제 </h1>{" "}
            <div className={css.sectionTwoContentWrapper + " " + css.year}>
              <div style={{ height: "auto" }} className={css.imgRowWrapper}>
                <img
                  src={merit2}
                  style={{ objectFit: "initial", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
