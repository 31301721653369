import css from "../../css/component/main/_index.module.scss";

import { getDateStringByDate } from "../../utils/utils";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getNoticePostByIdx,
  getNuNoticePostByIdx,
} from "../../utils/request/user/noauth/post";
import { baseUrl } from "../../utils/setting/setting";
export interface NoticeData {
  id: number; // 고유 ID
  insertDate: string; // 삽입 날짜 (ISO 8601 형식)
  title: string; // 제목
  content: string; // HTML 형식의 공휴일 내용
  imgUrl: string | null; // 이미지 URL (null 가능)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
}
const HtmlRenderer = ({ htmlString }: any) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
const HtmlRendererByHtmlString = ({
  imgPath,
  htmlString,
}: {
  htmlString: string;
  imgPath?: Array<string>;
}) => {
  let replacedHtmlString = htmlString;
  // HTML 텍스트
  imgPath?.forEach((path, index) => {
    replacedHtmlString = replacedHtmlString.replace(
      `imgPath[${index}]`,
      baseUrl + "/api/image?path=" + path
    );
  });
  return <HtmlRenderer htmlString={replacedHtmlString} />;
};
export const NoticeView = () => {
  const [searchParams] = useSearchParams();
  const [noticeData, setNoticeData] = useState<NoticeData>();
  const navigate = useNavigate();
  useEffect(() => {
    const idx = Number(searchParams.get("idx"));
    getNoticePostByIdx({ idx }).then((res) => {
      setNoticeData(res.res);
    });
  }, []);
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider7 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>공지사항</h1>
              <h3>Nahdlatul Ulama (NU)의 공지사항을 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <table className={css["table-wrapper"] + " " + css["is-post"]}>
            <tr className={css["table-row"]}>
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>번호</span>
              </td>
              <td className={css["item"] + " " + css["center"]}>
                <span>{noticeData?.id}</span>
              </td>{" "}
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>날짜</span>
              </td>
              <td colSpan={3} className={css["item"] + " " + css["left"]}>
                <span>
                  {noticeData &&
                    getDateStringByDate(new Date(noticeData?.insertDate))}
                </span>
              </td>
            </tr>
            <tr className={css["table-row"]}></tr>
            <tr className={css["table-row"]}>
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>제목</span>
              </td>
              <td colSpan={3} className={css["item"] + " " + css["left"]}>
                <span>{noticeData?.title}</span>
              </td>
            </tr>
            <tr className={css["table-row"] + " " + css["data"]}>
              <td
                colSpan={4}
                className={css["item"] + " " + css["left"] + " " + css.content}
              >
                {noticeData && (
                  <HtmlRendererByHtmlString
                    imgPath={noticeData.imgUrl?.split(",")}
                    htmlString={noticeData.content
                      ?.replace(/\s+/g, " ")
                      .replaceAll('{" "}', "")}
                  />
                )}
              </td>
            </tr>
          </table>
          <button
            onClick={() => navigate("/community/notice")}
            className={css.button + " " + css.strock}
          >
            목록으로{" "}
          </button>
        </div>
      </div>
    </>
  );
};
export const NuNoticeView = () => {
  const [searchParams] = useSearchParams();
  const [noticeData, setNoticeData] = useState<NoticeData>();
  const navigate = useNavigate();
  useEffect(() => {
    const idx = Number(searchParams.get("idx"));
    getNuNoticePostByIdx({ idx }).then((res) => {
      setNoticeData(res.res);
    });
  }, []);
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider7 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>인도네시아 소식</h1>
              <h3>인도네시아의 새로운 소식 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <table className={css["table-wrapper"] + " " + css["is-post"]}>
            <tr className={css["table-row"]}>
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>번호</span>
              </td>
              <td className={css["item"] + " " + css["center"]}>
                <span>{noticeData?.id}</span>
              </td>{" "}
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>날짜</span>
              </td>
              <td colSpan={3} className={css["item"] + " " + css["left"]}>
                <span>
                  {noticeData &&
                    getDateStringByDate(new Date(noticeData?.insertDate))}
                </span>
              </td>
            </tr>
            <tr className={css["table-row"]}></tr>
            <tr className={css["table-row"]}>
              <td
                className={
                  css["item"] + " " + css["center"] + " " + css["title"]
                }
              >
                <span>제목</span>
              </td>
              <td colSpan={3} className={css["item"] + " " + css["left"]}>
                <span>{noticeData?.title}</span>
              </td>
            </tr>
            <tr className={css["table-row"] + " " + css["data"]}>
              <td
                colSpan={4}
                className={css["item"] + " " + css["left"] + " " + css.content}
              >
                {noticeData && (
                  <HtmlRendererByHtmlString
                    imgPath={noticeData.imgUrl?.split(",")}
                    htmlString={noticeData.content
                      ?.replace(/\s+/g, " ")
                      .replaceAll('{" "}', "")}
                  />
                )}
              </td>
            </tr>
          </table>
          <button
            onClick={() => navigate("/community/notice")}
            className={css.button + " " + css.strock}
          >
            목록으로{" "}
          </button>
        </div>
      </div>
    </>
  );
};
