import css from "../../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../../pages/main/component/pageNav";
import DateSelector from "../../../dashboardPages/main/component/dateSelector";
import { useNavigate } from "react-router";
import { getProductListByUser } from "../../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
} from "../../../utils/utils";
import {
  changeUserConcertingState,
  changeUserCsAdmin,
  getUserList,
} from "../../../utils/request/admin/user";
import { getFile } from "../../../utils/request/user/auth/file";
import { getAdminList } from "../../../utils/request/admin/admin";
import { AdminInfo } from "../adminManage/admin";
import { start } from "repl";
import { IdInfoWrapper } from "../../../pages/login/login";
import {
  concertTypeList,
  transformArrayToObject,
} from "../../../dashboardPages/main/dashboard";
import { getTotalPage } from "../../../pages/community/notice";
interface UserResponse {
  userInfo: UserInfo;
  concertState: ConcertState;
}

interface UserInfo {
  password: string; // 비밀번호 (해시된 값)
  contractStartDate: string | null; // 계약 시작일 (null 가능)
  idx: number; // 사용자 인덱스
  email: string; // 이메일 주소
  contractEndDate: string | null; // 계약 종료일 (null 가능)
  csAdminIdx: number | null; // 고객 서비스 관리자 인덱스 (null 가능)
  companyName: string; // 회사명
  registrationDate: string; // 등록 날짜 (ISO 8601 형식)
  salesManagerIdx: number; // 영업 관리자 인덱스
  managerName: string; // 담당자 이름
  businessId: string; // 사업자 등록번호
  isNewCompany: boolean; // 신규 회사 여부
  businessFileUrl: string | null; // 사업자 파일 URL
  faxNumber: string; // 팩스 번호
  phoneNumber: string; // 전화번호
  salesPersonName: string; // 영업 담당자 이름
  csManagerName: string; // 고객 서비스 관리자 이름
}

interface ConcertState {
  halalComplete: number; // 할랄 인증 완료 상태 수
  waitAll: number; // 대기 중 상태 수
  completeAll: number; // 완료 상태 수
  etcConcertList: any[]; // 기타 콘서트 리스트
  alertList: any[]; // 알림 리스트
  totalProductCount: number; // 총 제품 수
  bpomComplete: number; // BPOM 인증 완료 상태 수
}
export function UserSearch() {
  const navigate = useNavigate();
  const count = 10;
  const [searchDateData, setSearchDateData] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: getDatePrevMonth(), endDate: getNextDay() });
  const [searchData, setSearchData] = useState<{
    searchType?: string;
    search?: string;
    adminName?: string;
    contractStartDate?: string;
    contractEndDate?: string;
    start: number;
    count: number;
  }>({
    searchType: "전체",
    search: "",
    start: 0,
    count: count,
  });
  // TODO :: user concertState date가 있으면 검색이 안 되는 현상
  const [filterInfoViewState, setFilterInfoViewState] = useState(false);
  const [userList, setUserList] = useState<Array<UserResponse>>();
  const [userPopup, setUserPopup] = useState<{
    state: boolean;
    data: UserResponse;
  }>({
    state: false,
    data: { concertState: {} as ConcertState, userInfo: {} as UserInfo },
  });
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    getUserList(searchData).then((res) => {
      setUserList(res.res);
      setTotalPages(getTotalPage(res, count));
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>유저관리</h1>
      </header>
      <section className={css["filter-section"]}>
        <div
          style={{ justifyContent: "flex-start", gap: "12px" }}
          className={css.filters}
        >
          <div className={css["status-filters"]}>
            <span>컨설팅 시작 날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchDateData.startDate,
                endDate: searchDateData.endDate,
              }}
              setData={(data: any) => {
                setSearchDateData({
                  ...data,
                });
              }}
            />{" "}
          </div>
          <div className={css["status-filters"]}>
            <button
              onClick={() => {
                setSearchData({
                  ...searchData,
                  contractStartDate: searchDateData.startDate,
                  contractEndDate: searchDateData.endDate,
                });
                alert(
                  searchDateData.startDate +
                    "~" +
                    searchDateData.endDate +
                    "으로 컨설팅 검색 기준 기간이 설정되었습니다."
                );
              }}
              className={css["button"]}
            >
              기간 설정
            </button>
            <button
              onClick={() => {
                const searchDataTemp = { ...searchData };
                delete searchDataTemp.contractStartDate;
                delete searchDataTemp.contractEndDate;
                setSearchData({
                  ...searchDataTemp,
                });
                alert("기간이 초기화되었습니다.");
              }}
              className={css["button"] + " " + css.strock}
            >
              기간 초기화
            </button>{" "}
          </div>
        </div>{" "}
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  const value = e.target.value as
                    | "전체"
                    | "업체명"
                    | "사업자등록번호";
                  setSearchData({
                    ...searchData,
                    searchType: value,
                  });
                }}
                className={css.input}
              >
                <option value={"전체"}>업체명/사업자등록번호</option>
                <option value={"업체명"}>업체명</option>
                <option value={"사업자등록번호"}>사업자등록번호</option>
              </select>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="기준 검색어로 검색"
                onChange={(e) => {
                  setSearchData({ ...searchData, search: e.target.value });
                }}
              ></input>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="CS 담당자 이름으로 검색"
                onChange={(e) => {
                  setSearchData({ ...searchData, adminName: e.target.value });
                }}
              ></input>
              <button
                onClick={() => {
                  getUserList({ ...searchData, start: 0 }).then((res) => {
                    setUserList(res.res);
                    setTotalPages(getTotalPage(res, count));
                  });
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              <button
                style={{ alignSelf: "stretch" }}
                onClick={() => {
                  setFilterInfoViewState(!filterInfoViewState);
                }}
                className={css["button"] + " " + css.strock}
              >
                검색이 안 돼요
              </button>
            </div>
          </div>
        </div>
        {filterInfoViewState && (
          <div className={css.filters}>
            {" "}
            <div className={css["status-filters"]}>
              <IdInfoWrapper
                style={{ background: "#f3f3f3", display: "block" }}
              >
                <h2 style={{ fontWeight: "800" }}>검색이 안 되는 이유</h2>
                <br />
                컨설팅 시작 날짜를 설정하시면 시작일과 종료일 기준으로
                검색됩니다.
                <br />
                시작일이 설정된 컨설팅 시작일 이후인 사용자와 컨설팅 종료일이
                설정된 컨설팅 종료일 이전 사용자가 검색됩니다.
                <br /> 예를 들어 컨설팅 기간이 2024-01-01 ~ 2024-02-31인 회원이
                있다면 시작일을 2024-01-01이전으로 설정하거나 종료일을
                2024-02-31이후로 설정하면 검색됩니다.
                <br /> <br />
                컨설팅 기간 설정 시 컨설팅 시작 날짜와 종료 날짜가 설정되지 않은
                사용자는 검색되지 않습니다.
                <br />
                <br /> 컨설팅 기간을 설정하지 않고 검색하시려면
                <b style={{ fontWeight: "600" }}> 기간 초기화 버튼</b>을
                눌러주세요.
              </IdInfoWrapper>
            </div>
          </div>
        )}
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                번호
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                상태
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                사업자등록번호/회사명
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                담당자
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                전화번호
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                이메일
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                영업 담당자
              </th>
              {/**
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                총 제품 수
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                대기 중
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                완료
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                할랄 인증
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                BPOM 인증
              </th> */}
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                CS 담당자
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                등록일
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                계약 시작일
              </th>
              <th style={{ whiteSpace: "nowrap" }} className={css.center}>
                계약 종료일
              </th>
            </tr>
          </thead>
          <tbody>
            {userList?.map((user, idx) => (
              <RowItem
                setUserPopup={(data) => {
                  setUserPopup({ state: true, data: data });
                }}
                user={user}
                key={"user-" + idx}
              />
            ))}
            {userList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={totalPages}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getUserList({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setUserList(res.res);
              setTotalPages(getTotalPage(res, count));
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <UserEditPopup
        state={userPopup.state}
        cancelAction={() =>
          setUserPopup({ state: false, data: userPopup.data })
        }
        user={userPopup.data}
      />
    </div>
  );
}
const RowItem = ({
  user,
  setUserPopup,
}: {
  user: UserResponse;
  setUserPopup: (data: UserResponse) => void;
}) => {
  return (
    <tr>
      <td className={css.center}>{user.userInfo.idx}</td>
      <td className={css.center}>
        {" "}
        {user.userInfo.isNewCompany ? "신규" : "기존"}
      </td>
      <td>
        <a
          onClick={(e) => {
            e.preventDefault();
            setUserPopup(user);
          }}
          style={{ cursor: "pointer", color: "blue" }}
        >
          [{user.userInfo.businessId}] {user.userInfo.companyName}
        </a>
      </td>
      <td className={css.center}>{user.userInfo.managerName}</td>
      <td className={css.center}>{user.userInfo.phoneNumber}</td>
      <td className={css.center}>{user.userInfo.email}</td>
      <td className={css.center}>{user.userInfo.salesPersonName}</td>

      <td className={css.center}>{user.userInfo.csManagerName}</td>
      <td className={css.center}>
        {user.userInfo.registrationDate &&
          getDateStringByDate(new Date(user.userInfo.registrationDate))}
      </td>
      <td className={css.center}>
        {user.userInfo.contractStartDate &&
          getDateStringByDate(new Date(user.userInfo.contractStartDate))}
      </td>
      <td className={css.center}>
        {user.userInfo.contractEndDate &&
          getDateStringByDate(new Date(user.userInfo.contractEndDate))}
      </td>
    </tr>
  );
};
export const UserEditPopup = ({
  state,
  cancelAction,
  user,
}: {
  state: boolean;
  cancelAction: any;
  user: UserResponse;
}) => {
  const [concertViewState, setConcertViewState] = useState(false);
  const [editState, setEditState] = useState({ admin: false, contract: false });
  const [adminList, setAdminList] = useState<Array<AdminInfo>>();
  const [selectedAdmin, setSelectedAdmin] = useState<AdminInfo>();
  const [userConcertState, setUserConcertState] = useState<{
    idx: number;
    contractStartDate: string;
    contractEndDate: string;
  }>({
    idx: user.userInfo.idx,
    contractStartDate: getTodayDateString(),
    contractEndDate: getTodayDateString(),
  });
  useEffect(() => {
    getAdminList({ start: 0, count: 100 }).then((res) => {
      setAdminList(res.res);
    });
  }, []);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>제품접수</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>회원번호</label>
            <span>{user.userInfo.idx}</span>
          </div>

          <div className={css["form-group"]}>
            <label>이메일</label>
            <span>{user.userInfo.email}</span>
          </div>

          <div className={css["form-group"]}>
            <label>회사명</label>
            <span>{user.userInfo.companyName}</span>
          </div>

          <div className={css["form-group"]}>
            <label>등록 날짜</label>
            <span>
              {getDateStringByDate(new Date(user.userInfo.registrationDate))}
            </span>
          </div>
          <div className={css["form-group"]}>
            <label>담당자 이름</label>
            <span>{user.userInfo.managerName}</span>
          </div>

          <div className={css["form-group"]}>
            <label>사업자 등록번호</label>
            <span>{user.userInfo.businessId}</span>
          </div>

          <div className={css["form-group"]}>
            <label>신규 회사 여부</label>
            <span>{user.userInfo.isNewCompany ? "신규" : "기존"}</span>
          </div>

          <div className={css["form-group"]}>
            <label>사업자 파일 URL</label>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                user.userInfo.businessFileUrl &&
                getFile({
                  path: user.userInfo.businessFileUrl,
                })
              }
            >
              {user.userInfo.businessFileUrl?.split("/").pop() || "없음"}
            </span>
          </div>

          <div className={css["form-group"]}>
            <label>팩스 번호</label>
            <span>{user.userInfo.faxNumber}</span>
          </div>

          <div className={css["form-group"]}>
            <label>전화번호</label>
            <span>{user.userInfo.phoneNumber}</span>
          </div>

          <div className={css["form-group"]}>
            <label>영업 담당자 이름</label>
            <span>{user.userInfo.salesPersonName}</span>
          </div>

          <div className={css["form-group"]}>
            <label>현재 CS 관리자 이름</label>
            <span>{user.userInfo.csManagerName || ""}</span>
            {!editState.admin && (
              <button
                onClick={() => {
                  setEditState({ ...editState, admin: true });
                }}
                className={css["button"] + " " + css.strock}
              >
                CS 관리자 변경
              </button>
            )}
          </div>
          {editState.admin && (
            <div className={css["form-group"]}>
              <label>현재 CS 관리자 변경</label>
              <select
                onChange={(e) => setSelectedAdmin(JSON.parse(e.target.value))}
                className={css.input}
              >
                {adminList?.map((admin, idx) => (
                  <option key={"admin-" + idx} value={JSON.stringify(admin)}>
                    {admin.name}
                  </option>
                ))}
              </select>
              <div className={css.flexRow}>
                <button
                  onClick={() => {
                    if (selectedAdmin) {
                      changeUserCsAdmin({
                        idx: user.userInfo.idx,
                        csAdminIdx: selectedAdmin.idx,
                      }).then((res) => {
                        if (res.message) {
                          alert("담당 관리자가 변경되었습니다.");
                          setEditState({
                            ...editState,
                            admin: false,
                          });
                        } else {
                          alert(
                            "변경에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                          );
                        }
                      });
                    }
                  }}
                  className={css["button"]}
                >
                  선택된 관리자로 변경
                </button>
                <button
                  onClick={() => {
                    setEditState({
                      ...editState,
                      admin: false,
                    });
                  }}
                  className={css["button"] + " " + css.strock}
                >
                  취소
                </button>
              </div>
            </div>
          )}

          <div className={css["form-group"]}>
            <label>컨설팅 계약 기간</label>
            <span>
              {user.userInfo.contractStartDate !== null
                ? user.userInfo.contractStartDate
                : "N/A"}{" "}
              ~{" "}
              {user.userInfo.contractEndDate !== null
                ? user.userInfo.contractEndDate
                : "N/A"}
            </span>{" "}
            {!editState.contract && (
              <button
                onClick={() => {
                  setEditState({ ...editState, contract: true });
                }}
                className={css["button"] + " " + css.strock}
              >
                컨설팅 계약 기간 변경
              </button>
            )}
          </div>
          {editState.contract && (
            <div className={css["form-group"]}>
              <label>계약 시작일</label>
              <input
                type="date"
                placeholder="계약 시작일을 입력하세요"
                value={userConcertState.contractStartDate}
                onChange={(e) =>
                  setUserConcertState({
                    ...userConcertState,
                    contractStartDate: e.target.value,
                  })
                }
              />
              <label>계약 종료일</label>
              <input
                type="date"
                placeholder="계약 종료일을 입력하세요"
                value={userConcertState.contractEndDate}
                onChange={(e) =>
                  setUserConcertState({
                    ...userConcertState,
                    contractEndDate: e.target.value,
                  })
                }
              />
              <div className={css.flexRow}>
                <button
                  onClick={() => {
                    if (userConcertState.contractStartDate) {
                      changeUserConcertingState({
                        contractEndDate: userConcertState.contractEndDate,
                        contractStartDate: userConcertState.contractStartDate,
                        idx: user.userInfo.idx,
                      }).then((res) => {
                        if (res.message) {
                          alert("컨설팅 기간이 변경되었습니다.");
                          setEditState({
                            ...editState,
                            contract: false,
                          });
                        } else {
                          alert(
                            "컨설팅 기간 변경에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                          );
                        }
                      });
                    }
                  }}
                  className={css["button"]}
                >
                  컨설팅기간 변경
                </button>
                <button
                  onClick={() => {
                    setEditState({
                      ...editState,
                      contract: false,
                    });
                  }}
                  className={css["button"] + " " + css.strock}
                >
                  취소
                </button>
              </div>
            </div>
          )}
          <button
            style={{ width: "100%" }}
            onClick={() => {
              setConcertViewState(!concertViewState);
            }}
            className={css["button"]}
          >
            컨설팅현황보기
          </button>
          {concertViewState && <DashboardView user={user} />}
          <button
            style={{ width: "100%" }}
            onClick={() => {
              cancelAction();
            }}
            className={css["button"] + " " + css.strock}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};
const DashboardView = ({ user }: { user: UserResponse }) => {
  return (
    <div className={css.dashboard} style={{ padding: 0, width: "100%" }}>
      <section className={css["contract-section"]}>
        <h2>컨설팅 계약</h2>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"]}>
            <span>
              <span className={css.dashboardPlusIcon + " " + css.icon} />
              대기중
            </span>
            <span className={css.count}>{user.concertState?.waitAll}건</span>
          </div>
          <div className={css["contract-card"] + " " + css.complete}>
            <span>
              <span className={css.icon + " " + css.dashboardDoubleCheckIcon} />
              완료
            </span>
            <span className={css.count}>
              {user.concertState?.completeAll}건
            </span>
          </div>
        </div>
      </section>{" "}
      <section className={css["contract-section"]}>
        <h2>할랄 가능여부</h2>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"]}>
            <span>
              <span className={css.xIcon + " " + css.icon} />
              불가능
            </span>
            <span className={css.count}>
              {user.concertState &&
                user.concertState?.totalProductCount -
                  user.concertState.halalComplete}
              건
            </span>
          </div>
          <div className={css["contract-card"] + " " + css.complete}>
            <span>
              <span className={css.icon + " " + css.dashboardDoubleCheckIcon} />
              가능
            </span>
            <span className={css.count}>
              {user.concertState && user.concertState.halalComplete}건
            </span>
          </div>
        </div>
      </section>
      <section className={css["contract-section"]}>
        <h2>할랄</h2>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"]}>
            <span>
              <span className={css.dashboardPlusIcon + " " + css.icon} />
              대기중
            </span>
            <span className={css.count}>
              {user.concertState &&
                user.concertState?.totalProductCount -
                  user.concertState.halalComplete}
              건
            </span>
          </div>
          <div className={css["contract-card"] + " " + css.pending}>
            <span>
              <span className={css.icon + " " + css.dashboardCheckIcon} />
              진행중
            </span>
            <span className={css.count}>{user.concertState && 0}건</span>
          </div>
          <div className={css["contract-card"] + " " + css.complete}>
            <span>
              <span className={css.icon + " " + css.dashboardDoubleCheckIcon} />{" "}
              완료
            </span>
            <span className={css.count}>
              {user.concertState && user.concertState.halalComplete}건
            </span>
          </div>
        </div>
      </section>
      <section className={css["contract-section"]}>
        <h2>BPOM</h2>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"]}>
            <span>
              <span className={css.dashboardPlusIcon + " " + css.icon} />
              대기중
            </span>
            <span className={css.count}>
              {user.concertState &&
                user.concertState?.totalProductCount -
                  user.concertState.bpomComplete}
              건
            </span>
          </div>
          <div className={css["contract-card"] + " " + css.pending}>
            <span>
              <span className={css.icon + " " + css.dashboardCheckIcon} />{" "}
              진행중
            </span>
            <span className={css.count}>{user.concertState && 0}건</span>
          </div>
          <div className={css["contract-card"] + " " + css.complete}>
            <span>
              <span className={css.icon + " " + css.dashboardDoubleCheckIcon} />{" "}
              완료
            </span>
            <span className={css.count}>
              {user.concertState && user.concertState.bpomComplete}건
            </span>
          </div>
        </div>
      </section>
      <section className={css["contract-section"] + " " + css["row"]}>
        <div className={css.row}>
          {user.concertState &&
            Object.keys(
              transformArrayToObject(user.concertState.etcConcertList)
            ).map((item: string, index: number) => {
              return (
                <div className={css["misc-card"]}>
                  <p>{concertTypeList[Number(item)]}</p>
                  <div>
                    <span>진행중</span>
                    <span className={css.count}>
                      {
                        transformArrayToObject(
                          user.concertState.etcConcertList
                        )[Number(item)][0]
                      }
                      건
                    </span>
                  </div>
                  <div className={css.complete}>
                    <span>완료</span>
                    <span className={css.count}>
                      {
                        transformArrayToObject(
                          user.concertState.etcConcertList
                        )[Number(item)][1]
                      }
                      건
                    </span>
                  </div>
                </div>
              );
            })}{" "}
        </div>
      </section>
    </div>
  );
};
