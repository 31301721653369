import axios from "axios";
export interface BasicSearchParam {
  currentPage: number;
  viewCount: number;
}
export interface DateSearchParam {
  startDate: string;
  endDate: string;
}
export interface UserSearchParam extends BasicSearchParam {
  userIdx: number;
}
export interface UserSearchWithDateParam extends BasicSearchParam {
  userIdx: number;
}
interface PostData {
  path: string;
  data: any;
  header?: any;
}
const makePathParamString = (params: { [key: string]: string }) => {
  const keys = Object.keys(params);
  let paramString = "?";
  keys.map((key, index) => {
    paramString = paramString + key + "=" + params[key];
    if (index != keys.length - 1) {
      paramString = paramString + "&";
    }
  });
  return paramString;
};
export const getData = async (info: {
  path: string;
  params?: any;
  header?: any;
  isImg?: boolean;
}) => {
  try {
    const res = await axios.get(
      info.path +
        (info.params
          ? makePathParamString(
              info.params as unknown as { [key: string]: string }
            )
          : ""),
      {
        headers: info.header,
      }
    );
    return res.data;
  } catch (e) {
    const status = JSON.parse(JSON.stringify(e)).status;
  }
};

export const getPostData = async (info: PostData) => {
  try {
    const res = await axios.post(info.path, info.data, {
      headers: info.header,
    });
    return res.data;
  } catch (e) {
    const status = JSON.parse(JSON.stringify(e)).status;
  }
};
export const getLoginPostData = async (info: PostData) => {
  try {
    const res = await axios.post(info.path, info.data, {
      headers: info.header,
    });

    return res;
  } catch (e) {
    const status = JSON.parse(JSON.stringify(e)).status;
  }
};
export const upPostData = async (info: PostData) => {
  try {
    const res = await axios.post(info.path, info.data, {
      headers: info.header,
    });

    const data = res.data;
    if (data.state == "200") {
      return data.message;
    } else {
      return data.errMessage;
    }
  } catch (e) {
    const status = JSON.parse(JSON.stringify(e)).status;
  }
};

export const downloadFile = async (path: string, header: any) => {
  try {
    const response = await axios.get(path, {
      headers: header,
      responseType: "blob", // 파일 데이터를 blob으로 받음
    });

    // Blob URL 생성
    const downloadUrl = URL.createObjectURL(response.data);

    // 다운로드 강제 실행
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = path.split("/").pop() || "default_filename"; // 파일 이름 추출
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // 사용 후 정리
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("다운로드 실패:", error);
  }
};
