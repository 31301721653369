import styled from "styled-components";
import css from "../../css/component/main/_index.module.scss";
import { SectionTitle } from "../main/component/sectionTitle";
import {
  BaseDataSelectWithLabel,
  BaseInputWithLabel,
  BaseSelectWithLabel,
  BaseTextAreaInputWithLabel,
} from "../login/component/BaseInputWithLabel";
import { useState } from "react";
import { insertContactUs } from "../../utils/request/user/noauth/contact";
import { LoginInputWrapper } from "../login/styled/login";
import { useNavigate } from "react-router";
export const Contact = () => {
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = useState<{
    title: string; // 문의 제목
    content: string; // 문의 내용
    email: string; // 이메일 주소
    phone: string; // 전화번호
    name: string; // 이름
    companyName: string; // 회사명
    contactType: string; // 문의 유형
  }>({
    title: "",
    content: "",
    email: "",
    phone: "",
    name: "",
    companyName: "",
    contactType: "0",
  });
  return (
    <div className={css["wrapper"]}>
      <div className={css["sectionWrapper"]}>
        <SectionTitle title="NU한국위원회 고객센터" description="" />
        <LoginInputWrapper>
          <div className={css.sectionFirstContentWrapper}>
            <h1>전문컨설턴트 상담신청</h1>
            <p className={css.infoBoxWrapper}>
              궁금하신 점을 간략히 남겨주시면
              <br /> 빠른 시일 내에 연락드리겠습니다
            </p>
          </div>
          <BaseInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, name: e.target.value })
            }
            label="담당자 성함"
            placeholder="담당자 성함"
          />
          <BaseInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, companyName: e.target.value })
            }
            label="회사명"
            placeholder="회사명"
          />
          <BaseInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, email: e.target.value })
            }
            label="이메일"
            placeholder="이메일"
          />
          <BaseInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, phone: e.target.value })
            }
            label="연락처"
            placeholder="연락처"
          />
          <BaseDataSelectWithLabel
            list={[
              { name: "할랄", type: 0 },
              { name: "BPOM", type: 1 },
              { name: "인허가", type: 2 },
              { name: "촬영", type: 3 },
              { name: "언어변환", type: 4 },
              { name: "디자인", type: 5 },
            ]}
            onChange={(e) => {
              setContactInfo({
                ...contactInfo,
                contactType: JSON.parse(e.target.value).type,
              });
              console.log(e.target.value);
            }}
            label="문의종류"
          />
          <BaseInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, title: e.target.value })
            }
            label="제목"
            placeholder="제목"
          />
          <BaseTextAreaInputWithLabel
            onChange={(e) =>
              setContactInfo({ ...contactInfo, content: e.target.value })
            }
            label="내용"
            placeholder="내용"
          />
          <button
            onClick={() =>
              insertContactUs(contactInfo).then((data) => {
                if (data.message) {
                  alert("문의가 등록되었습니다.");
                  navigate("/");
                } else {
                  alert(
                    "문의 등록에 실패하였습니다. 잠시 후 다시 시도해주세요."
                  );
                }
              })
            }
            className={css.button + " " + css.fullWidth}
          >
            무료상담신청
          </button>
        </LoginInputWrapper>
      </div>
    </div>
  );
};
const JoinInfoWrapper = styled.div`
  display: flex;
  padding-top: 28px;
  justify-content: center;
  align-items: center;
  gap: var(--3, 12px);
  flex-direction: column;
  width: 100%;
`;
const JoinInfoText = styled.span`
  color: #303033;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
`;
const JoinLinkTextWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--3, 0px);
  text-decoration: none;
`;
const JoinLinkText = styled.span`
  color: #5d1d96;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const JoinLinkTextIcon = styled.div`
  filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(5705%)
    hue-rotate(270deg) brightness(96%) contrast(93%);
  width: 18px;
  height: 18px;
`;

const LoginButton = styled.button`
  border-radius: 36px;
  border: 1px solid #fdfeff;
  background: #017534;
  display: flex;
  padding: var(--2, 12px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  width: 100%;
`;
const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const LoginInfoFindButtonListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--6, 28px);
  width: 100%;
`;
const LoginInfoFindButton = styled.a`
  border: none;
  background: none;
  text-decoration: none;
  padding: 6px 0;
`;
const LoginInfoFindButtonText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
`;
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 33%;
`;
