import { useNavigate } from "react-router";
import CreateDoubleMenu from "./sideBarItem/double";
import CreateSingleMenu from "./sideBarItem/single";
import { useEffect, useState } from "react";
import { getUserCsAdminInfo } from "../../../utils/request/user/auth/user";
const menuObj: any = [
  /*
  {
    isDoubleMenu: true,
    title: { key: "my-info", url: "/mypage", iconKey: "user" },
    depth: 2,
    list: [
      { key: "my-info", url: "/mypage", iconKey: "user" },
      { key: "my-info-edit", url: "/mypage/edit", iconKey: "edit" },
    ],
  },*/

  {
    isDoubleMenu: false,
    title: { key: "어드민관리", url: "/admin/dashboard", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "유저관리", url: "/admin/dashboard/user", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "영업사원관리", url: "/admin/dashboard/sales", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: {
      key: "영업사원 매출관리",
      url: "/admin/dashboard/sales/withdraw",
      iconKey: "",
    },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "제품관리", url: "/admin/dashboard/product", iconKey: "" },
    list: [],
  },
  /*
  {
    isDoubleMenu: false,
    title: { key: "제품등록", url: "/dashboard/product/add", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "인증완료 제품", url: "/dashboard/verifyed", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "1:1문의", url: "/dashboard/contact", iconKey: "" },
    list: [],
  },*/
];
export const SideBar = ({
  url,
  css,
  setOpenSideBar,
  openSideBar,
}: {
  openSideBar: boolean;
  setOpenSideBar: () => void;
  url: string;
  css: any;
}) => {
  const navigate = useNavigate();
  const [userAdminInfo, setUserAdminInfo] = useState({
    name: "담당자 지정 대기중",
    email: "담당자 지정 대기중",
    phone: "담당자 지정 대기중",
  });
  useEffect(() => {
    getUserCsAdminInfo().then((res) => {
      if (res.state == "200") {
        setUserAdminInfo(
          res.res
            ? res.res
            : {
                name: "담당자 지정 대기중",
                email: "담당자 지정 대기중",
                phone: "담당자 지정 대기중",
              }
        );
      }
    });
  }, []);
  return (
    <div
      className={
        css.sideBarWrapper +
        " " +
        (!openSideBar ? css.closed : css.mobileActive)
      }
      id="mobile-side-bar"
    >
      <div
        onClick={() => {
          navigate("/admin/dashboard");
        }}
        style={{ cursor: "pointer" }}
        className={css.logo}
      />

      <div className={css.menuWrapper}>
        {menuObj.map((data: any) => {
          if (data.isDoubleMenu == true && data.depth) {
            return (
              <>
                {CreateDoubleMenu(
                  css,
                  "/",
                  data.depth ? data.depth : 1,
                  data.title,
                  data.list
                )}
              </>
            );
          } else {
            return <>{CreateSingleMenu(css, "/", data.title)}</>;
          }
        })}
      </div>
      <div className={css["role"] + " " + css.pcNone}>
        <span style={{ marginTop: "-24px" }} onClick={() => setOpenSideBar()}>
          메뉴 닫기
        </span>
      </div>
      <div className={css["role"] + " " + css.pcNone}>
        <span
          style={{ padding: 0, color: "blue", marginTop: "-24px" }}
          onClick={() => navigate("/admin/dashboard/login")}
        >
          로그아웃
        </span>
      </div>
    </div>
  );
};
