import { downloadFile, getData, getPostData } from "../../axios/axiosUtil";
import { getJwt } from "../../sessionStorage/getAsync";
import { auth, baseUrl, noAuth } from "../../setting/setting";

const jwt = () => getJwt();

const BASE_URL = baseUrl + "/api/admin/manage/product";

// 어드민 - 특정 제품 상세 정보 조회
export const getAdminProductByIdx = (info: { idx: number }) => {
  return getData({
    path: `${BASE_URL}/get`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 - 제품 리스트 조회
export const getProductListByAdmin = (filters: {
  concertType?: string;
  state?: number;
  searchType?: string;
  search?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  csManager?: string;
  start: number;
  count: number;
}) => {
  return getData({
    path: `${BASE_URL}/get/list`,
    params: filters,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 - 제품 고유 ID 업데이트
export const updateProductIdByAdmin = (info: {
  productIdx: number;
  productUniqueId: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/unique`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 - 제품 Halal 상태 업데이트
export const changeProductHalalStateIsVerified = (data: {
  idx: number;
  file: File;
  certificationDate: string;
  expiryDate: string;
  certificationNumber: string;
}) => {
  const formData = new FormData();
  formData.append("idx", String(data.idx));
  formData.append("file", data.file);
  formData.append("certificationDate", data.certificationDate);
  formData.append("expiryDate", data.expiryDate);
  formData.append("certificationNumber", data.certificationNumber);

  return getPostData({
    path: `${BASE_URL}/update/halal/verified`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// 어드민 - 제품 BPOM 상태 업데이트
export const changeProductBpomStateIsVerified = (data: {
  idx: number;
  file: File;
  certificationDate: string;
  expiryDate: string;
  certificationNumber: string;
}) => {
  const formData = new FormData();
  formData.append("idx", String(data.idx));
  formData.append("file", data.file);
  formData.append("certificationDate", data.certificationDate);
  formData.append("expiryDate", data.expiryDate);
  formData.append("certificationNumber", data.certificationNumber);

  return getPostData({
    path: `${BASE_URL}/update/bpom/verified`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// Halal 상태 업데이트
export const updateHalalState = (info: { idx: number; state: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/halal/state`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
// Halal 상태 업데이트
export const updateAbleHalalState = (info: { idx: number; state: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/halal/able/state`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
// BPOM 상태 업데이트
export const updateBpomState = (info: { idx: number; state: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/bpom/state`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 게시물 목록 조회
export const getProductBpomPostList = (filterDTO: {
  idx?: number;
  confirmationStatus?: number;
  state?: number;
  type?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/get/bpom/post/list`,
    data: filterDTO,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 게시물 목록 조회
export const getProductHalalPostList = (filterDTO: {
  idx?: number;
  confirmationStatus?: number;
  state?: number;
  type?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/get/halal/post/list`,
    data: filterDTO,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 특정 Halal 게시물 조회
export const getHalalPostByIdx = (info: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/get/halal/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 특정 BPOM 게시물 조회
export const getBpomPostByIdx = (info: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/get/bpom/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 게시물 추가
export const insertHalalPost = (postInfo: {
  productIdx: number;
  title: string;
  content: string;
  type: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/insert/halal/post`,
    data: postInfo,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 게시물 추가
export const insertBpomPost = (postInfo: {
  productIdx: number;
  title: string;
  content: string;
  type: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/insert/bpom/post`,
    data: postInfo,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 특정 제품의 기타 컨설팅 상태 조회
export const getProductOtherConcertState = (info: { productIdx: number }) => {
  return getData({
    path: `${BASE_URL}/get/etc`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 기타 컨설팅 상태 검색
export const getOtherConcertStateList = (filters: {
  searchType?: string;
  search?: string;
  csManager?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  start?: number;
  count?: number;
}) => {
  return getData({
    path: `${BASE_URL}/get/etc/list`,
    params: filters,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 게시물 상태 업데이트
export const updateHalalPostState = (req: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/halal/post/state`,
    data: req,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 게시물 상태 업데이트
export const updateBpomPostState = (req: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/bpom/post/state`,
    data: req,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 문서 상태 업데이트
export const updateHalalDocumentState = (docInfo: {
  idx: number;
  state: number;
  comment?: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/halal/doc/state`,
    data: docInfo,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 문서 상태 업데이트
export const updateBpomDocumentState = (docInfo: {
  idx: number;
  state: number;
  comment?: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/bpom/doc/state`,
    data: docInfo,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 기타 컨설팅 정보 추가
export const insertEtcConcerting = (concertInfo: {
  idx: number;
  type: number;
}) => {
  return getPostData({
    path: `${BASE_URL}/insert/etc`,
    data: concertInfo,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
export const updateOtherConcertingState = (req: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/etc/state`,
    data: req,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 여러 기타 컨설팅 상태 업데이트
export const updateOtherConcertingStateBatch = (req: { idxList: number[] }) => {
  return getPostData({
    path: `${BASE_URL}/update/etc/state/list`,
    data: req,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

export const updateBpomDocFormFile = (info: {
  file: File;
  productIdx: number;
  docIdx: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docIdx", info.docIdx.toString());

  return getPostData({
    path: `${BASE_URL}/update/product/bpom/doc/form`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

export const insertDocFormFile = (info: {
  file: File;
  docType: number;
  isHalal: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("docType", info.docType.toString());
  formData.append("isHalal", info.isHalal.toString());

  return getPostData({
    path: `${BASE_URL}/insert/doc/form`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// Halal 문서 파일 업데이트
export const updateHalalDocFormFile = (info: {
  file: File;
  productIdx: number;
  docIdx: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docIdx", info.docIdx.toString());

  return getPostData({
    path: `${BASE_URL}/update/product/halal/doc/form`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};
