import css from "../../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../../pages/main/component/pageNav";
import {
  getAdminList,
  insertAdmin,
  updateAdminInfo,
  updateAdminPassword,
} from "../../../utils/request/admin/admin";
import { getDateStringByDate } from "../../../utils/utils";
export interface AdminInfo {
  idx: number; // 관리자 인덱스
  id: string; // 관리자 아이디
  pw: string; // 비밀번호 (해시된 값)
  insertDate: string; // 생성 날짜 (ISO 8601 형식)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
  name: string; // 이름
  phone: string; // 전화번호
  email: string; // 이메일 주소
}
export function AdminSearch() {
  const [addAdminPopup, setAddAdminPopup] = useState(false);
  const [editAdminPopup, setEditAdminPopup] = useState({
    state: false,
    data: {
      idx: 0,
      id: "",
      pw: "",
      insertDate: "",
      state: 0,
      name: "",
      phone: "",
      email: "",
    },
  });
  const [searchData, setSearchData] = useState<{
    start: number;
    count: number;
  }>({ start: 0, count: 10 });
  const [adminList, setAdminList] = useState<Array<AdminInfo>>();
  useEffect(() => {
    getAdminList(searchData).then((res) => {
      setAdminList(res.res);
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>관리자 관리</h1>
        <button
          onClick={() => {
            setAddAdminPopup(true);
          }}
          className={css["button"] + " " + css.shadow}
        >
          <span className={css.dashboardPlusIcon + " " + css.white} />새 관리자
          등록
        </button>
      </header>

      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th className={css.center}>번호</th>
              <th className={css.center}>등록일</th>
              <th className={css.center}>아이디</th>
              <th className={css.center}>이름</th>
              <th className={css.center}>이메일</th>
              <th className={css.center}>전화번호</th>
              <th className={css.center}>상태</th>
            </tr>
          </thead>
          <tbody>
            {adminList?.map((admin, idx) => (
              <RowItem
                setAdminPopup={(data: AdminInfo) => {
                  setEditAdminPopup({ state: true, data: data });
                }}
                admin={admin}
                key={"admin-" + idx}
              />
            ))}
            {adminList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={1000}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getAdminList({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setAdminList(res.res);
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <EditAdminPopup
        admin={editAdminPopup.data}
        state={editAdminPopup.state}
        cancelAction={() =>
          setEditAdminPopup({ state: false, data: editAdminPopup.data })
        }
      />
      <NewAdminAddPopup
        state={addAdminPopup}
        cancelAction={() => setAddAdminPopup(false)}
      />
    </div>
  );
}
const RowItem = ({
  admin,
  setAdminPopup,
}: {
  admin: AdminInfo;
  setAdminPopup: (data: AdminInfo) => void;
}) => {
  return (
    <tr>
      <td className={css.center}>{admin.idx}</td>
      <td className={css.center}>
        {" "}
        {getDateStringByDate(new Date(admin.insertDate))}
      </td>
      <td>
        <a
          onClick={(e) => {
            e.preventDefault();
            setAdminPopup(admin);
          }}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {admin.id}
        </a>
      </td>
      <td className={css.center}> {admin.name}</td>
      <td className={css.center}> {admin.email}</td>
      <td className={css.center}> {admin.phone}</td>
      <td className={css.center}>
        {admin.state == 0 ? (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            정상사용
          </span>
        ) : (
          <span className={css.bedge + " " + css.red + " " + css.middle}>
            사용안함
          </span>
        )}
      </td>
    </tr>
  );
};
export const NewAdminAddPopup = ({
  state,
  cancelAction,
}: {
  state: boolean;
  cancelAction: any;
}) => {
  const [newAdminInfo, setNewAdminInfo] = useState<{
    id: string;
    pw: string;
    name: string;
    phone: string;
    email: string;
  }>({ id: "", pw: "", name: "", phone: "", email: "" });
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>새관리자 등록</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>새관리자 등록 안내</h2>
            <ul>
              <li>
                CS담당자 정보를 꼭 입력해주세요. 입력하지 않을 시 CS담당자
                정보가 사용자에게 표출되지 않습니다.
              </li>
            </ul>
          </section>
          <div className={css["form-group"]}>
            <label>아이디</label>
            <input
              type="text"
              placeholder="아이디를 입력하세요"
              onChange={(e) =>
                setNewAdminInfo({ ...newAdminInfo, id: e.target.value })
              }
            />
          </div>

          <div className={css["form-group"]}>
            <label>비밀번호</label>
            <input
              type="password"
              placeholder="비밀번호를 입력하세요"
              onChange={(e) =>
                setNewAdminInfo({ ...newAdminInfo, pw: e.target.value })
              }
            />
          </div>

          <div className={css["form-group"]}>
            <label>이름</label>
            <input
              type="text"
              placeholder="이름을 입력하세요"
              onChange={(e) =>
                setNewAdminInfo({ ...newAdminInfo, name: e.target.value })
              }
            />
          </div>

          <div className={css["form-group"]}>
            <label>전화번호</label>
            <input
              type="tel"
              placeholder="전화번호를 입력하세요"
              onChange={(e) =>
                setNewAdminInfo({ ...newAdminInfo, phone: e.target.value })
              }
            />
          </div>

          <div className={css["form-group"]}>
            <label>이메일</label>
            <input
              type="email"
              placeholder="이메일 주소를 입력하세요"
              onChange={(e) =>
                setNewAdminInfo({ ...newAdminInfo, email: e.target.value })
              }
            />
          </div>
          <button
            onClick={() => {
              insertAdmin(newAdminInfo).then((res) => {
                if (res.message) {
                  alert("어드민 등록이 완료되었습니다.");
                  cancelAction();
                } else {
                  alert("어드민 등록이 실패하였습니다.");
                }
              });
              cancelAction();
            }}
            className={css["button"]}
          >
            등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export const EditAdminPopup = ({
  state,
  cancelAction,
  admin,
}: {
  admin: AdminInfo;
  state: boolean;
  cancelAction: any;
}) => {
  const [updateAdminInfoData, setUpdateAdminInfoData] = useState<{
    idx: number;
    name: string;
    phone: string;
    email: string;
  }>({ idx: 0, name: "", phone: "", email: "" });
  const [pw, setPw] = useState("");
  useEffect(() => {
    setUpdateAdminInfoData({ ...updateAdminInfoData, idx: admin.idx });
  }, [admin]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>관리자 정보 수정</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>아이디</label>
            <input readOnly type="text" defaultValue={admin.id} />
          </div>
          <div className={css["form-group"]}>
            <label>새 비밀번호</label>
            <input
              onChange={(e) => setPw(e.target.value)}
              type="password"
              placeholder="수정하지 않을 시 공란으로 남겨주세요."
            />
          </div>
          <div className={css["form-group"]}>
            <label>관리자 이름</label>
            <input
              onChange={(e) =>
                setUpdateAdminInfoData({
                  ...updateAdminInfoData,
                  name: e.target.value,
                })
              }
              type="text"
              defaultValue={admin.name}
            />
          </div>

          <div className={css["form-group"]}>
            <label>전화번호</label>
            <input
              onChange={(e) =>
                setUpdateAdminInfoData({
                  ...updateAdminInfoData,
                  phone: e.target.value,
                })
              }
              type="tel"
              defaultValue={admin.phone}
            />
          </div>

          <div className={css["form-group"]}>
            <label>이메일 주소</label>
            <input
              onChange={(e) =>
                setUpdateAdminInfoData({
                  ...updateAdminInfoData,
                  email: e.target.value,
                })
              }
              type="email"
              defaultValue={admin.email}
            />
          </div>

          <button
            onClick={() => {
              updateAdminInfo(updateAdminInfoData).then((res) => {
                if (res.message) {
                  alert("정보수정이 완료되었습니다.");
                  cancelAction();
                } else {
                  alert("정보수정에 실패하였습니다.");
                }
              });
              if (pw != "") {
                updateAdminPassword({ idx: admin.idx, pw: pw }).then((res) => {
                  if (res.message) {
                    alert("비밀번호 수정이 완료되었습니다.");
                    cancelAction();
                  } else {
                    alert("비밀번호 수정에 실패하였습니다.");
                  }
                });
              }
            }}
            className={css["button"]}
          >
            수정완료
          </button>
        </div>
      </div>
    </div>
  );
};
