import css from "../../css/component/main/_index.module.scss";
import bpomFoodProcess from "../../assets/images/concert/status.png";
import indoPerson from "../../assets/images/concert/indoPerson.png";
import bpomBeautyProcess from "../../assets/images/concert/indonesiaMarket.png";
import cart from "../../assets/images/concert/cart1.png";
import indonesiaMarketProduct from "../../assets/images/concert/indonesiaMarketProduct.png";
import halalharamProduct from "../../assets/images/concert/halalharamProduct.png";
import { SectionTitle } from "../main/component/sectionTitle";
export const Concert = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>인도네시아 진출</h1>
              <h3> 왜 인도네시아에 진출해야 하는지 알아보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="인도네시아 진출" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아</h1>
            <p className={css.infoBoxWrapper}>
              인도네시아는 해마다 높은 경제성장률과 전 세계 16위의
              국내총생산량(GDP) (한국 13위) [KOSIS참조, 2022] 을 자랑하는 신흥
              공업국으로 자리매김하고 있습니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={bpomFoodProcess}
                style={{ objectFit: "initial", height: "auto" }}
              />
              <img
                src={bpomBeautyProcess}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아 종교적 특징과 현황</h1>
            <p className={css.infoBoxWrapper}>
              대부분의 인도네시아인의 약 87%는 이슬람교를 믿고 사실상 사회
              분위기를 지배하고 있다. <br />타 이슬람 국가들처럼 무슬림들에
              이슬람법이 적용된다.{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={indoPerson}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              인도네시아는 국교가 따로 없고, 헌법에 의해 6개 종교 이슬람,
              개신교, 로마 가톨릭, 힌두교, 불교, 유교의 신앙의 자유를 보장한다.
              다만 무신론자는 공산주의자로 간주되기 때문에 사실상 무신론자가
              되려면 신분증에 반드시 신앙이 명시되어야 한다. 대부분의
              인도네시아인의 약 87%는 이슬람교를 믿고 사실상 사회 분위기를
              지배하고 있다. 타 이슬람 국가들처럼 무슬림들에 이슬람법이
              적용된다.
            </p>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아의 한국 인식 조사 지표 </h1>{" "}
            <p className={css.infoBoxWrapper}>
              동남아시아 국가 중 한국 선호도가 가장 높은 지표를 나타내고 있는
              만큼 한국의 문화, 경제, 제품에 대한 관심도가 높습니다.{" "}
            </p>
          </div>
          <div className={css.tableWrapper}>
            <div className={css.sectionTwoContentWrapper + " " + css.year}>
              <div className={css.contentInfoWrapper + " " + css.year}>
                <h3>호감도 조사 결과 58.3% </h3>
                <p>
                  호감도 조사 결과 58.3%로 높은 호감도를 표현하였으며
                  <br />
                  19.5%는 호감과 비호감의 답변을 했음
                  <br />
                  22.2% 부정적인 인식은 무응답 (35.1%) 또는 잘 모르겠어서 (9.3%)
                  등으로 구체적 비호감 사유는 없음
                </p>
              </div>
              <div
                className={
                  css.contentInfoWrapper + " " + css.year + " " + css.green
                }
              >
                <h3>신뢰도 조사 결과 58.7% </h3>
                <p>
                  신뢰도 조사 결과 58.7%로 높은 신뢰도를 표현했으며
                  <br />
                  24.0%는 신뢰하는 편이라는 답변을 했음
                  <br />
                  17.3% 비신뢰의 사유는 무응답 또는 잘 알지 못해서로 호감도와
                  유사하게 구체적 비신뢰의 사유는 없음{" "}
                </p>
              </div>
            </div>{" "}
            <span>
              출처: 주인도네시아 대한민국 대사관 조사결과 보고 (2023년 11월) /
              인도네시아 여론조사가기관 LSI 전화 면접 방식
            </span>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아 소비자 구매 가능한 한국식품 수출현황 </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={cart}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아 주요 온라인 쇼핑몰 인기 한국 식품 </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={indonesiaMarketProduct}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>인도네시아 할랄인증/비인증 한국 인기 식품 </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={halalharamProduct}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
