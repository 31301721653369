const headerNames = {
  "/dashboard": "현황표",
  "/dashboard/product": "제품관리",
  "/dashboard/verifyed": "인증완료 제품",

  "/dashboard/contact": "1:1문의",

  "/dashboard/company": "회사정보",
} as { [key: string]: string };
export default headerNames;
