import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import css from "../../../css/component/main/_index.module.scss";

export function MainSlider() {
  const settings = {
    dots: true, // 슬라이더 하단에 도트 표시
    infinite: true, // 무한 반복
    speed: 1500, // 슬라이더 전환 속도
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 한 번에 넘길 슬라이드 개수
    autoplay: true, // 자동 슬라이딩
    autoplaySpeed: 5000, // 자동 슬라이딩 속도
  };

  return (
    <div className={css.sliderContainer}>
      <Slider {...settings}>
        <div className={css.sliderWrapper + " " + css.slider1}>
          <div>
            <div className={css.titleWrapper}>
              <h3>인도네시아 진출 기업의 원활한 사업을 위한 </h3>
              <h1>할랄 인증 서비스</h1>
            </div>
            <a href="/info/halal" className={css.button}>
              자세히 보기
            </a>
          </div>
        </div>
        <div className={css.sliderWrapper + " " + css.slider2}>
          <div>
            <div className={css.titleWrapper}>
              <h3>인도네시아 진출 준비 기업을 위한 </h3>
              <h1>컨설팅 서비스</h1>
            </div>
            <a href="/concert" className={css.button}>
              자세히 보기
            </a>
          </div>
        </div>
        <div className={css.sliderWrapper + " " + css.slider3}>
          <div>
            <div className={css.titleWrapper}>
              <h3>인도네시아 진출을 위한 식약청 인증 컨설팅 </h3>
              <h1>BPOM 인증 서비스</h1>
            </div>
            <a href="/info/bpom" className={css.button}>
              자세히 보기
            </a>
          </div>
        </div>
      </Slider>
    </div>
  );
}
