import { downloadFile, getData, getPostData } from "../../axios/axiosUtil";
import { getJwt } from "../../sessionStorage/getAsync";
import { auth, baseUrl, noAuth } from "../../setting/setting";

const jwt = () => getJwt();

const BASE_URL = baseUrl + "/api/admin/user";

// 어드민 비밀번호 변경
export const updateAdminPassword = (info: { idx: number; pw: string }) => {
  return getPostData({
    path: `${BASE_URL}/change/pw`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 사용 상태 변경 (탈퇴 처리)
export const updateAdminState = (info: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/join/cancel`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 목록 조회
export const getAdminList = (info: { start: number; count: number }) => {
  return getData({
    path: `${BASE_URL}/get/list`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 추가
export const insertAdmin = (info: {
  id: string;
  pw: string;
  name: string;
  phone: string;
  email: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/insert`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 어드민 정보 업데이트
export const updateAdminInfo = (info: {
  idx: number;
  name: string;
  phone: string;
  email: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
