import { downloadFile, getData, getPostData } from "../../axios/axiosUtil";
import { getJwt } from "../../sessionStorage/getAsync";
import { auth, baseUrl, noAuth } from "../../setting/setting";

const jwt = () => getJwt();

const BASE_URL = baseUrl + "/api/admin/sales";

// 1. Insert a new sales person
export const insertSalesPerson = (data: {
  parentIdx: number | null;
  levelIdx: number;
  name: string;
  type: number;
}) => {
  return getPostData({
    path: `${BASE_URL}/insert`,
    data: data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 2. Get sales person list
export const getSalesPersonList = (
  info: {
    salesPersonIdx?: number | null;
  } | null
) => {
  return getData({
    path: `${BASE_URL}/get/list`,
    params: info,
    header: { Authorization: jwt() },
  });
};

// 3. Get sales person details by ID
export const getSalesPersonByIdx = (info: { idx: number }) => {
  return getData({
    path: `${BASE_URL}/get/info`,
    params: info,
    header: { Authorization: jwt() },
  });
};

// 4. Update sales person information
export const updateSalesPersonInfo = (data: {
  idx: number;
  name: string;
  parentIdx: number;
  levelIdx: number;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/info`,
    data: data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 5. Update sales person state (activate/deactivate)
export const updateSalesPersonState = (data: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/state`,
    data: data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 6. Get sales withdraw list
export const getSalesWithdrawList = (params: {
  searchType?: string;
  search?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  start: number;
  count: number;
}) => {
  return getData({
    path: `${BASE_URL}/get/withdraw/list`,
    params: params,
    header: { Authorization: jwt() },
  });
};

// 7. Get all sales withdraw list
export const getSalesWithdrawListAll = (params: {
  searchPersonIdx?: number;
  startDate?: string;
  endDate?: string;
  start: number;
  count: number;
}) => {
  return getData({
    path: `${BASE_URL}/get/withdraw/list/all`,
    params: params,
    header: { Authorization: jwt() },
  });
};

// 8. Get sales levels
export const getSalesLevelList = () => {
  return getData({
    path: `${BASE_URL}/get/level/list`,
    header: { Authorization: jwt() },
  });
};

// 9. Update sales withdraw state by ID
export const updateSalesWithdrawState = (data: { idx: number }) => {
  return getPostData({
    path: `${BASE_URL}/update/withdraw/state/idx`,
    data: data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 10. Update sales withdraw state by date range
export const updateSalesWithdrawStateByDateStartEnd = (data: {
  startDate: string;
  endDate: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/withdraw/state/date`,
    data: data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
