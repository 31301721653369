import { getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { noAuth } from "../../../setting/setting";

const url = noAuth + "/post";
// 데이터 게시물 리스트 조회
export const getDataPostList = (info: {
  search: string;
  start: number;
  count: number;
}) => {
  return getData({
    path: `${url}/get/data/list`,
    params: info,
  });
};

// 데이터 게시물 단일 조회
export const getDataPostByIdx = (info: { idx: number }) => {
  return getData({
    path: `${url}/get/data`,
    params: info,
  });
};
export const getNoticePostList = (info: { start: number; count: number }) => {
  return getData({
    path: `${url}/get/notice/list`,
    params: info,
  });
};

// 데이터 게시물 단일 조회
export const getNoticePostByIdx = (info: { idx: number }) => {
  return getData({
    path: `${url}/get/notice`,
    params: info,
  });
};
export const getNuNoticePostList = (info: { start: number; count: number }) => {
  return getData({
    path: `${url}/get/nu/list`,
    params: info,
  });
};

// 데이터 게시물 단일 조회
export const getNuNoticePostByIdx = (info: { idx: number }) => {
  return getData({
    path: `${url}/get/nu`,
    params: info,
  });
};
