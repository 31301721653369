import css from "../../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../../pages/main/component/pageNav";
import DateSelector from "../../../dashboardPages/main/component/dateSelector";
import { useNavigate } from "react-router";
import { getProductListByUser } from "../../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
} from "../../../utils/utils";
import {
  getSalesLevelList,
  getSalesWithdrawList,
  insertSalesPerson,
  updateSalesPersonInfo,
} from "../../../utils/request/admin/sales";
import { getSalesPersonList } from "../../../utils/request/user/noauth/reg";
interface Organization {
  name: string; // 이름
  type: string; // 타입 (예: "0")
  percent: number; // 퍼센트 값
  idx: number; // 인덱스
  parent_idx: number | null; // 부모 인덱스 (null 가능)
  parentPercent: number | null; // 부모 퍼센트 (null 가능)
  level_idx: number; // 레벨 인덱스
  oldParentPercent: number | null; // 이전 부모 퍼센트 (null 가능)
  total_withdraw_amount: number; // 총 인출 금액
  sale_amount: number; // 판매 금액
  levelName: string | null; // 레벨 이름 (null 가능)
  insert_date: string; // 삽입 날짜 (ISO 8601 형식)
}
interface Product {
  realAmount: number; // 실금액
  halalState: number; // 할랄 상태
  productName: string; // 제품명
  salesPersonName: string; // 영업 담당자 이름
  contractStartDate: string | null; // 계약 시작일
  contractEndDate: string | null; // 계약 종료일
  percentage: string; // 비율
  managerName: string; // 담당자 이름
  amount: number; // 금액
  businessId: string; // 사업자등록번호
  insertDate: string; // 등록일
  bpomState: number; // BPOM 상태
  companyName: string; // 회사명
}

export function SalesWithdrawSearch() {
  const navigate = useNavigate();
  const [searchDateData, setSearchDateData] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: getDatePrevMonth(), endDate: getNextDay() });
  const [searchData, setSearchData] = useState<{
    searchType?: string;
    search?: string;
    contractStartDate?: string;
    contractEndDate?: string;
    start: number;
    count: number;
  }>({
    searchType: "전체",
    search: "",
    start: 0,
    count: 10,
  });
  const [newSalesPopup, setNewSalesPopup] = useState(false);
  const [salesList, setSalesList] = useState<Array<Product>>();
  const [salesPopup, setSalesPopup] = useState<{
    state: boolean;
    data: Organization;
  }>({
    state: false,
    data: {
      idx: 0,
      name: "",
      type: "",
      percent: 0,
      level_idx: 0,
      parent_idx: null,
      parentPercent: null,
      oldParentPercent: null,
      total_withdraw_amount: 0,
      sale_amount: 0,
      levelName: null,
      insert_date: "",
    },
  });
  const [salesPersonIdx, setSalesPersonIdx] = useState<Organization>();

  useEffect(() => {
    getSalesWithdrawList(searchData).then((res) => {
      setSalesList(res.res);
    });
  }, [salesPersonIdx]);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>영업사원 매출 관리</h1>
      </header>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>컨설팅계약기간</span>
            <DateSelector
              initialDate={{
                startDate: searchDateData.startDate,
                endDate: searchDateData.endDate,
              }}
              setData={(data: any) => {
                setSearchDateData({
                  ...data,
                });
              }}
            />{" "}
          </div>
          <div className={css["status-filters"]}>
            <button
              onClick={() => {
                setSearchData({
                  ...searchData,
                  contractStartDate: searchDateData.startDate,
                  contractEndDate: searchDateData.endDate,
                });
                alert(
                  searchDateData.startDate +
                    "~" +
                    searchDateData.endDate +
                    "으로 컨설팅 검색 기준 기간이 설정되었습니다."
                );
              }}
              className={css["button"]}
            >
              기간 설정
            </button>
            <button
              onClick={() => {
                const searchDataTemp = { ...searchData };
                delete searchDataTemp.contractStartDate;
                delete searchDataTemp.contractEndDate;
                setSearchData({
                  ...searchDataTemp,
                });
                alert("기간이 초기화되었습니다.");
              }}
              className={css["button"] + " " + css.strock}
            >
              기간 초기화
            </button>{" "}
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  const value = e.target.value as
                    | "전체"
                    | "업체명"
                    | "사업자등록번호"
                    | "제품명";
                  setSearchData({
                    ...searchData,
                    searchType: value,
                  });
                }}
                className={css.input}
              >
                <option value={"전체"}>업체명/사업자등록번호/제품명</option>
                <option value={"업체명"}>업체명</option>
                <option value={"사업자등록번호"}>사업자등록번호</option>
                <option value={"제품명"}>제품명</option>
              </select>

              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="검색"
                onChange={(e) => {
                  setSearchData({ ...searchData, search: e.target.value });
                }}
              ></input>
              <button
                onClick={() => {
                  getSalesWithdrawList(searchData).then((res) => {
                    setSalesList(res.res);
                  });
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th>영업관리자명</th>

              <th>사업자등록번호</th>
              <th>업체명</th>
              <th>담당자명</th>
              <th>제품명</th>

              <th>계약 시작일</th>
              <th>계약 종료일</th>
              <th>할랄 상태</th>
              <th>BPOM 상태</th>

              <th>매출</th>
              <th>정산율</th>
              <th>정산금</th>
              <th>정산일</th>
            </tr>
          </thead>
          <tbody>
            {salesList?.map((sales, idx) => (
              <RowItem
                setSalesPersonIdx={setSalesPersonIdx}
                setSalesPopup={(e) => setSalesPopup({ state: true, data: e })}
                sales={sales}
                key={"sales-" + idx}
              />
            ))}
            {salesList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={100}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getSalesWithdrawList({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setSalesList(res.res);
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <EditSalesAddPopup
        state={salesPopup.state}
        cancelAction={() =>
          setSalesPopup({ state: false, data: salesPopup.data })
        }
        sales={salesPopup.data}
      />
      <NewSalesAddPopup
        state={newSalesPopup}
        cancelAction={() => setNewSalesPopup(false)}
      />
    </div>
  );
}
const RowItem = ({
  sales,
  setSalesPopup,
  setSalesPersonIdx,
}: {
  sales: Product;
  setSalesPopup: (data: Organization) => void;
  setSalesPersonIdx: (data: Organization) => void;
}) => {
  return (
    <tr>
      <td>{sales.salesPersonName}</td>

      <td>{sales.businessId}</td>
      <td>{sales.companyName}</td>
      <td>{sales.managerName}</td>
      <td>{sales.productName}</td>
      <td>
        {sales.contractStartDate &&
          getDateStringByDate(new Date(sales.contractStartDate))}
      </td>
      <td>
        {sales.contractEndDate &&
          getDateStringByDate(new Date(sales.contractEndDate))}
      </td>
      <td>
        {sales.halalState === 1 ? (
          <span className="badge green">완료</span>
        ) : sales.halalState === 0 ? (
          <span className="badge blue">진행중</span>
        ) : (
          <span className="badge red">미진행</span>
        )}
      </td>
      <td>
        {sales.bpomState === 2 ? (
          <span className="badge green">완료</span>
        ) : sales.bpomState === 1 ? (
          <span className="badge blue">진행중</span>
        ) : (
          <span className="badge red">미진행</span>
        )}
      </td>

      <td>{sales.amount.toLocaleString()}원</td>

      <td>{sales.percentage}</td>
      <td>{sales.realAmount.toLocaleString()}원</td>

      <td>{getDateStringByDate(new Date(sales.insertDate))}</td>
    </tr>
  );
};
export const NewSalesAddPopup = ({
  state,
  cancelAction,
}: {
  state: boolean;
  cancelAction: any;
}) => {
  const [salesPersonIdx, setSalesPersonIdx] = useState<Organization>();
  const [salesList, setSalesList] = useState<Array<Organization>>();
  const [salesList2, setSalesList2] = useState<Array<Organization>>();
  useEffect(() => {
    getSalesPersonList(null).then((res) => {
      setSalesList(res.res);
    });
    if (salesPersonIdx) {
      getSalesPersonList({ salesPersonIdx: salesPersonIdx.idx }).then((res) => {
        setSalesList2(res.res);
      });
    }
  }, [salesPersonIdx]);
  const [selectedLevel, setSelectedLevel] = useState<LevelInfo>();
  const [levelList, setLevelList] = useState<Array<LevelInfo>>();
  const [user, setUser] = useState<{
    parentIdx: number | null;
    levelIdx: number;
    name: string;
    type: number;
  }>({ type: 0, name: "", parentIdx: null, levelIdx: 0 });
  useEffect(() => {
    getSalesLevelList().then((res) => {
      setLevelList(res.res);
    });
  }, []);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>새 영업사원 추가</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>영업사원 추가 안내 </h2>
            <ul>
              <li>사원 타입은 본부와 지부를 꼭 선택해주세요.</li>
              <li>지부 타입은 본부를 꼭 선택해주세요.</li>
            </ul>
          </section>

          <div className={css["form-group"]}>
            <label>이름</label>
            <input
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
              }}
              type="text"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>등급</label>
            <select
              onChange={(e) =>
                setUser({ ...user, levelIdx: JSON.parse(e.target.value).idx })
              }
              className={css.input}
            >
              {levelList?.map((level, idx) => (
                <option key={"level-" + idx} value={JSON.stringify(level)}>
                  {level.name}
                </option>
              ))}
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>타입</label>
            <select
              onChange={(e) => {
                setUser({ ...user, type: Number(e.target.value) });
              }}
              className={css.input}
            >
              <option value="0">본부</option>
              <option value="1">지부</option>
              <option value="2">사원</option>
            </select>
          </div>
          {(user.type == 1 || user.type == 2) && (
            <div className={css["form-group"]}>
              <label>본부 선택</label>
              <select
                onChange={(e) => {
                  setUser({
                    ...user,
                    parentIdx: JSON.parse(e.target.value).idx,
                  });
                  setSalesPersonIdx(JSON.parse(e.target.value));
                }}
                className={css.input}
              >
                {salesList?.map((sales, idx) => (
                  <option
                    key={"sales-new-" + idx}
                    value={JSON.stringify(sales)}
                  >
                    {sales.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {user.type == 2 && (
            <div className={css["form-group"]}>
              <label>지부 선택</label>
              <select
                onChange={(e) => {
                  setUser({
                    ...user,
                    parentIdx: JSON.parse(e.target.value).idx,
                  });
                  setSalesPersonIdx(JSON.parse(e.target.value));
                }}
                className={css.input}
              >
                {salesList2?.slice(1, -1)?.map((sales, idx) => (
                  <option
                    key={"sales-new-" + idx}
                    value={JSON.stringify(sales)}
                  >
                    {sales.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className={css["form-group"]}>
            <label>선택된 부모 영업사원</label>
            <span>{salesPersonIdx?.name}</span>
          </div>
          <button
            onClick={() => {
              insertSalesPerson({ ...user }).then((res) => {
                if (res.message) {
                  alert("영업사원이 등록되었습니다.");
                } else {
                  alert(
                    "영업사원 등록에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                  );
                }
              });
              cancelAction();
            }}
            className={css["button"]}
          >
            제품등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
interface LevelInfo {
  name: string; // 레벨 이름
  percent: number; // 퍼센트 값
  idx: number; // 인덱스
  parentPercent: number; // 부모 퍼센트
  oldParentPercent: number; // 이전 부모 퍼센트
}
export const EditSalesAddPopup = ({
  state,
  cancelAction,
  sales,
}: {
  state: boolean;
  cancelAction: any;
  sales: Organization;
}) => {
  const [selectedLevel, setSelectedLevel] = useState<LevelInfo>();
  const [levelList, setLevelList] = useState<Array<LevelInfo>>();
  useEffect(() => {
    getSalesLevelList().then((res) => {
      setLevelList(res.res);
    });
  }, []);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>영업사원 정보수정</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>사원 이름</label>
            <span>{sales.name}</span>
          </div>

          <div className={css["form-group"]}>
            <label>타입</label>
            {sales.type == "0" ? (
              <span className={css.bedge + " " + css.green + " " + css.middle}>
                본부
              </span>
            ) : sales.type == "1" ? (
              <span className={css.bedge + " " + css.blue + " " + css.middle}>
                지부
              </span>
            ) : (
              <span className={css.bedge + " " + css.white + " " + css.middle}>
                사원
              </span>
            )}{" "}
          </div>

          <div className={css["form-group"]}>
            <label>레벨</label>
            <span>{sales.levelName}</span>
          </div>
          <div className={css["form-group"]}>
            <label>레벨 재설정</label>
            <select
              onChange={(e) => setSelectedLevel(JSON.parse(e.target.value))}
              className={css.input}
            >
              {levelList?.map((level, idx) => (
                <option key={"level-" + idx} value={JSON.stringify(level)}>
                  {level.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => {
                if (selectedLevel) {
                  updateSalesPersonInfo({
                    idx: sales.idx,
                    name: sales.name,
                    parentIdx: Number(sales.parent_idx),
                    levelIdx: selectedLevel.idx,
                  }).then((res) => {
                    if (res.message) {
                      alert("영업사원의 등급이 변경되었습니다.");
                    } else {
                      alert(
                        "영업사원의 등급 변경에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                      );
                    }
                  });
                }
              }}
              className={css["button"]}
            >
              선택된 등급으로 변경
            </button>
          </div>

          <div className={css["form-group"]}>
            <label>정산률</label>
            <span>{sales.percent / 100}%</span>
          </div>
          <div className={css["form-group"]}>
            <label>등록 날짜</label>
            <span>
              {sales.insert_date !== null ? sales.insert_date : "N/A"}
            </span>
          </div>

          <div className={css["form-group"]}>
            <label>총 영업금액</label>
            <span>
              {sales.total_withdraw_amount !== null
                ? sales.total_withdraw_amount.toLocaleString()
                : "N/A"}
            </span>
          </div>

          <button
            onClick={() => {
              cancelAction();
            }}
            className={css["button"]}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
