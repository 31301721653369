import css from "../../css/component/header/_index.module.scss";
export const Footer = () => {
  return (
    <div className={css["footer-wrapper"]}>
      <div className={css["footer-links"]}>
        <a href="/community/notice">
          <b>공지사항</b>
        </a>
        <a href="/customer/map">오시는길</a>
        <a href="/customer">1:1문의</a>
        <a href="/customer/faq">FAQ</a>
      </div>
      <div className={css["customer-center"]}>
        <div className={css["company-details"]}>
          <h2 className={css["logo"]}></h2>
          <p>
            <b>NU 한국위원회</b>
            389-87-01029
          </p>

          <p>서울 서초구 주흥16길 14 (14 Juheung 16-gil, Seocho-gu, Seoul)</p>
        </div>
      </div>
      <div
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={css["customer-center"]}
      >
        <div className={css["company-details"]}>
          <p>
            <b className={css.green}></b>
          </p>
        </div>
      </div>
      <div className={css["customer-center"]}>
        <div className={css["company-details"]}>
          <p className={css["hide"]}>
            © 2024 NU한국위원회, LLC. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
