import css from "../../css/component/wrapper/_index.module.scss";
import css2 from "../../css/component/dashboard/_index.module.scss";
import { Outlet, useLocation } from "react-router";
import { useContext, useEffect, useState } from "react";
import { UrlContext } from "../../context/url";
import { SideBar } from "./sidebar/sideBar";
import Header from "./header/header";
const Wrapper = () => {
  const { url, setUrl } = useContext(UrlContext) as any;
  const [openSideBar, setOpenSideBar] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setUrl(window.location.pathname);
  }, [location]);
  return (
    <div className={css.wrapper}>
      <SideBar
        setOpenSideBar={() => setOpenSideBar(!openSideBar)}
        openSideBar={openSideBar}
        url={url}
        css={css}
      ></SideBar>
      <Header
        setOpenSideBar={() => setOpenSideBar(!openSideBar)}
        css={{ ...css2, ...css }}
      >
        <Outlet />
      </Header>
    </div>
  );
};
export default Wrapper;
