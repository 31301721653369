import css from "../../css/component/dashboard/_index.module.scss";
import React from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "./component/dateSelector";
import { getDatePrevMonth, getNextDay } from "../../utils/utils";

function ProductVerifyedSearch() {
  const [searchData, setSearchData] = React.useState({
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    start: 0,
    count: 10,
  });
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>인증완료 제품검색</h1>
      </header>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>인증날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
              }}
              setData={(data: any) => {
                const newSearchData = { ...searchData };
                setSearchData({ ...newSearchData, ...data });
              }}
            />{" "}
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select className={css.input}>
                <option>분야 전체</option>
                <option>할랄</option>
                <option>BPOM</option>
              </select>
              <select className={css.input}>
                <option>상태 전체</option>
                <option>할랄유효</option>
                <option>할랄만료</option>
                <option>BPOM유효</option>
                <option>BPOM만료</option>
              </select>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="제품명으로 검색"
              ></input>
              <button className={css["button"]}>
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              {/**
              <button className={css["button"] + " " + css.strock}>
                <span className={css.dashboardResetIcon} />
                필터 초기화
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 100 }}>
                <div className={css.sortWrapper}>
                  할랄인증일
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 120 }}>
                <div className={css.sortWrapper}>
                  BPOM인증일
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 100 }}>제품ID</th>
              <th style={{ whiteSpace: "nowrap", width: 500 }}>제품명</th>
              <th style={{ width: 100 }}>할랄 상태</th>
              <th style={{ width: 100 }}>BPOM 상태</th>
              <th style={{ width: 130 }}>할랄인증서 </th>
              <th style={{ width: 130 }}>BPOM인증서 </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }} colSpan={9}>
                인증완료 제품이 없습니다.
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={1}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
    </div>
  );
}
const RowItem = ({
  idx,
  id,
  title,
}: {
  idx: number;
  id: string;
  title: string;
}) => {
  return (
    <tr>
      <td className={css.center}>{idx}</td>
      <td className={css.center}>2024-11-10</td>
      <td className={css.center}>2024-11-10</td>
      <td className={css.center}>{id}</td>
      <td>{title}</td>
      <td className={css.center}>
        <span className={css.bedge + " " + css.green + " " + css.middle}>
          유효
          <br />
          2025-11-10 만료
        </span>
      </td>
      <td className={css.center}>
        <span className={css.bedge + " " + css.green + " " + css.middle}>
          유효
          <br />
          2025-11-10 만료
        </span>
      </td>

      <td className={css.center}>
        <button className={css["button"] + " " + css.strock}>
          HALAL 인증서
        </button>
      </td>
      <td className={css.center}>
        <button className={css["button"] + " " + css.strock}>
          BPOM 인증서
        </button>
      </td>
    </tr>
  );
};
export default ProductVerifyedSearch;
