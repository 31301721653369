import { downloadFile, getData, getPostData } from "../../axios/axiosUtil";
import { getJwt } from "../../sessionStorage/getAsync";
import { auth, baseUrl, noAuth } from "../../setting/setting";

const url = baseUrl + "/api/admin/file?path=";
const jwt = () => getJwt();

// 파일 가져오기

export const getFile = (info: { path: string }) => {
  return downloadFile(url + info.path, {
    Authorization: jwt(),
    "Content-Type": "application/json",
  });
};
