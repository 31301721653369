import { ReactElement, useContext, useEffect, useState } from "react";
import { UrlContext } from "../../../context/url";
import headerNames from "../../../staticSettings/sideBarAndHeader/urlHeaderTextSetting";
import { useNavigate } from "react-router";
import { getUserInfo } from "../../../utils/request/user/auth/user";
export interface CompanyInfo {
  idx: number; // 사용자 ID
  businessId: string; // 사업자 ID
  companyName: string; // 회사 이름
  managerName: string; // 담당자 이름
  phoneNumber: string; // 전화번호
  email: string; // 이메일
  faxNumber: string; // 팩스 번호
  businessFileUrl: string | null; // 사업자 파일 URL (null 가능)
  postNumber: string; // 우편 번호
  address: string; // 주소
  addressDetail: string; // 상세 주소
  adminName: string | null; // CS 관리자 이름 (null 가능)
  salesPersonName: string; // 영업 담당자 이름
}
const Header = ({
  css,
  children,
  setOpenSideBar,
}: {
  children: ReactElement;
  css: any;
  setOpenSideBar: () => void;
}) => {
  const { url } = useContext(UrlContext);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<CompanyInfo>({
    idx: 0,
    businessId: "",
    companyName: "",
    managerName: "",
    phoneNumber: "",
    email: "",
    faxNumber: "",
    businessFileUrl: null,
    postNumber: "",
    address: "",
    addressDetail: "",
    adminName: null,
    salesPersonName: "",
  });
  useEffect(() => {
    getUserInfo().then((res) => {
      setUserInfo(res.res);
    });
  }, []);
  return (
    <div className={css.contentWrapper}>
      <div className={css.headerWrapper}>
        <span>{headerNames[url]}</span>
        <div className={css["notification-area"] + " " + css.pcNone}>
          <div style={{ marginLeft: "auto" }} className={css["user-info"]}>
            <span onClick={() => setOpenSideBar()} className={css["user-name"]}>
              MENU
            </span>
          </div>
        </div>
      </div>
      <div className={css.contentDiv}>{children}</div>
    </div>
  );
};
export default Header;
