import css from "../../../css/component/main/_index.module.scss";
import { SectionTitle } from "../../main/component/sectionTitle";
import bpomFoodProcess from "../../../assets/images/bpomFoodProcess.png";
import bpomBeautyProcess from "../../../assets/images/beautiyProcess.png";
import { useNavigate } from "react-router";
export const Bpom = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>BPOM(식약청) 등록 소개</h1>
              <h3>BPOM인증을 알아볼 수 있습니다.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="BPOM(식약청) 등록 소개" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>BPOM인증이란</h1>
            <p className={css.infoBoxWrapper}>
              <b>식약청 인증</b>이
              <b className={css.green}>
                BPOM(Badan Pengawas Obat dan Makanan) 인증
              </b>
              으로도 통용됩니다. <br />
              대제품목에는 기본적으로
              <b>
                의약품, 의약외품, 전통의약품, 건강기능식품(건강 보조식품),
                식품(일반가공식품) 화장품
              </b>
              이 있습니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              식약청 인증이 BPOM(Badan Pengawas Obat dan Makanan) 인증으로도
              통용됩니다. 대제품목에는 기본적으로 의약품, 의약외품, 전통의약품,
              건강기능식품(건강 보조식품), 식품(일반가공식품) 화장품이 있습니다.
              통상 화장품, 식품의 인증이 2개월~1년 사이로 가능한 반면, 의약품
              전통의약품 및 건강 보조제품의 경우 길면 2년까지도 소요되는 것으로
              보고되고 있습니다. 각 분야별 등록 허가 요건사항을 사전 준비와
              요구조건 사항을 충족 시에는 허가에 소요되는 기간은 평균 일반
              가공식품 경우 평균 3~4개월, 전통의약품 및 건강 기능식품의 경우
              6~8개월내에도 완료되는 점을 참고 하여 인도네시아 식약청 기준에
              이해와 철저한 준비가 필요합니다.
            </p>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>
              BPOM인증 <br />
              식품(일반 가공 식품)
            </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={bpomFoodProcess}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>등록 접수 비용 </h3>
              <p>
                일반 가공식품은 Rp.300,000 또는 카테 고리에 따라 Rp.500,000를
                식약청에 심사 등록비로 납부 되어야 심사 진행 가능하며, 제품의
                광고 기능성 표기가 필요시 광고 대상 기능성 평가가 추가됨에 따라
                Rp.3,000,000의 비용이 납부되어야 합니다.
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>기타 특이 사항 </h3>
              <p>
                해외 제조사의 식품안전 및 위생 관리사항을 점검 직접 점검 확인할
                수 없는 문제로 제조사의 HACPP 또는 ISO22000의 사본을 필수
                제출하여 심사 평가를 계속 받을 수 있습니다. 또한 제품의 시험검사
                성적서의 준비는 제조사의 자가 시험 성적서용도 필요 하지만 외부
                시험 검사 기관으로 인정받은 기관의 시험 검사 성적서가 추가 제출
                되어야 하며, 이때 식품 카테고리별 주요 의무 미생물 및 중금속
                검사가 포함 되어야 합니다. 그 기준이 국가간 다를 수 있기 때문에
                인도네시아 시험검사 기관에 의뢰 하여 준비하시는 것이 비용 및
                시간적으로 유리합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>등록 접수 비용 </h3>
              <p>
                BPOM 2019년 22호 규정의 개정에서 일반가공식품의 영양성 분표시
                사항에 대하여 자국지정 시험검사기관의 의무검사 제외대상 품목은
                현지에서 생산한 커피, 차(분말 및 티백 제품 포함), 미네랄워터,
                허브 제품, 양념류, 조미료이며, 해외 제조 수입 제품은 예외 없이
                모두 의무화.
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>
              BPOM인증 <br />
              전통의약품 및 건강기능식품(건강보조식품)
            </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>
                인허가의 절차 <br />및 소요시간{" "}
              </h3>
              <p>
                일반 가공식품의 등록 절차와 유사하나 허가 대상이 일반식품 보다는
                그 제조의 관리와 제조의 기술 안정성, 제품의 기능성 검증자료등의
                심사가 매우 어렵고 그 요구사항도 많은 전문성을 요구합니다.
                심사기간은 서류적 요건의 충족이 완비 될시 6개월 이내에도
                가능하지만, 심사요건과 평가항목이 많은 관계로 추가 서류보정 및
                요구 자료로 인하여 평균적으로 1여년이상 소요 될수도 있습니다.
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>등록 접수 비용 </h3>
              <p>
                일반 가공식품은 Rp.300,000 또는 카테 고리에 따라 Rp.500,000를
                식약청에 심사 등록비로 납부 되어야 심사 진행 가능하며, 제품의
                광고 기능성 표기가 필요시 광고 대상 기능성 평가가 추가됨에 따라
                Rp.3,000,000의 비용이 납부되어야 합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>주요 유의사항 </h3>
              <p>
                해외 제조사의 식품안전 및 위생 관리사항을 점검 직접 점검 확인할
                수 없는 문제로 제조사의 HACPP 또는 ISO22000의 사본을 필수
                제출하여 심사 평가를 계속 받을 수 있습니다. 또한 제품의 시험검사
                성적서의 준비는 제조사의 자가 시험 성적서용도 필요 하지만 외부
                시험 검사 기관으로 인정받은 기관의 시험 검사 성적서가 추가 제출
                되어야 하며, 이때 식품 카테고리별 주요 의무 미생물 및 중금속
                검사가 포함 되어야 합니다. 그 기준이 국가간 다를 수 있기 때문에
                인도네시아 시험검사 기관에 의뢰 하여 준비하시는 것이 비용 및
                시간적으로 유리합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
