import css from "../../css/component/main/_index.module.scss";
import { MainSlider } from "./component/slider";
import { SectionTitle } from "./component/sectionTitle";
import { NoticeItem } from "./component/noticeItem";
import img1 from "../../assets/images/image-1.png";
import img2 from "../../assets/images/image-2.png";
import img3 from "../../assets/images/image-3.png";
import img4 from "../../assets/images/image-4.png";
import img5 from "../../assets/images/image-5.png";
import img6 from "../../assets/images/image-6.png";
import img7 from "../../assets/images/noNameLogo.png";
import img_1 from "../../assets/images/image1.png";
import img_2 from "../../assets/images/image2.png";
import img_3 from "../../assets/images/image3.png";
import img_4 from "../../assets/images/image4.png";
import img_5 from "../../assets/images/image5.png";
import halal from "../../assets/images/halal.png";
import bpom from "../../assets/images/bpom.png";
import { NoticeData } from "../community/noticeView";
import { useEffect, useState } from "react";
import {
  getNoticePostList,
  getNuNoticePostList,
} from "../../utils/request/user/noauth/post";
import { getDateStringByDate } from "../../utils/utils";
import { RowItem } from "../community/notice";

export const Main = () => {
  const [searchData, setSearchData] = useState<{
    start: number;
    count: number;
  }>({
    start: 0,
    count: 2,
  });
  const [noticeList, setNoticeList] = useState<Array<NoticeData>>([]);
  const [nuNoticeList, setNuNoticeList] = useState<Array<NoticeData>>([]);
  useEffect(() => {
    getNuNoticePostList(searchData).then((res) => {
      setNuNoticeList(res.res);
    });
    getNoticePostList({ ...searchData, count: 1 }).then((res) => {
      setNoticeList(res.res);
    });
  }, []);
  return (
    <>
      <div className={css["wrapper"]}>
        <MainSlider />
        <div className={css["sectionWrapper"]}>
          <SectionTitle
            title="최신 공지사항"
            description="NU한국위원회의 새로운 소식을 받아보세요."
          />
          <div className={css["is-row-scroll"]}>
            <table className={css["table-wrapper"]}>
              <thead>
                <tr className={css["table-row"]}>
                  <th
                    className={css["item"] + " " + css["center"]}
                    style={{ width: "10%" }}
                  >
                    <span>번호</span>
                  </th>
                  <th
                    style={{ width: "20%" }}
                    className={css["item"] + " " + css["left"]}
                  >
                    <span>날짜</span>
                  </th>
                  <th
                    className={css["item"] + " " + css["left"]}
                    style={{ width: "60%" }}
                  >
                    <span>제목</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((notice: NoticeData, idx: number) => {
                  return <RowItem notice={notice} key={idx} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={css["sectionWrapper"] + " " + css.gray}>
          <SectionTitle
            title="인도네시아 소식"
            description="인도네시아의 새로운 소식을 받아보세요."
          />
          <div className={css["noticeListWrapper"]}>
            {nuNoticeList.map((notice) => (
              <NoticeItem
                href={"/community/nu/view" + "?idx=" + notice.id}
                notice={notice}
                key={"notice-" + notice.id}
              />
            ))}
          </div>
        </div>
        <div className={css["sectionWrapper"] + " " + css.section2}>
          <SectionTitle
            title=" 인증 진행 현황"
            description=" 그룹은 명확한 결과로 고객의 성원에 보답합니다"
          />
          <div className={css["noticeListWrapper"] + " " + css.row}>
            <div className={css.itemWrapper}>
              <h1>할랄 인증 진행 완료</h1>
              <h2>283건</h2>
            </div>
            <div className={css.itemWrapper}>
              <h1>최단기간 할랄 인증</h1>
              <h2>24일</h2>
            </div>
            <div className={css.itemWrapper}>
              <h1>식약청 허가 진행 완료</h1>
              <h2>215건</h2>
            </div>
            <div className={css.itemWrapper}>
              <h1>최단기간 식약청 허가</h1>
              <h2>75일</h2>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle
            title=" 할랄 센터 협력 조직"
            description="할랄 센터와 협력하는 조직을 소개합니다."
          />
          <div className={css["noticeListWrapper"] + " " + css.row}>
            <div
              onClick={() => {
                window.open("https://www.nu.or.id/");
              }}
              className={css.compItemWrapper}
            >
              <img src={img7} />
              <h1>Nahdlatul Ulama</h1>
              <h2>N.U</h2>
            </div>
            <div
              onClick={() => {
                window.open("https://www.hpnk.org/id");
              }}
              className={css.compItemWrapper}
            >
              <img src={img3} />
              <h1>NU기업인협회</h1>
              <h2>HPN</h2>
            </div>
            <div
              onClick={() => {
                window.open("https://bpjph.halal.go.id/");
              }}
              className={css.compItemWrapper}
            >
              <img src={img1} />
              <h1>종교부 할랄보장청</h1>
              <h2>BPJPH</h2>
            </div>
            <div
              onClick={() => {
                window.open("https://www.pom.go.id/");
              }}
              className={css.compItemWrapper}
            >
              <img src={img2} />
              <h1>인도네시아 식약청</h1>
              <h2>BPOM</h2>
            </div>
            <div
              onClick={() => {
                window.open("https://www.sucofindo.co.id/");
              }}
              className={css.compItemWrapper}
            >
              <img src={img4} />
              <h1>공기업</h1>
              <h2>SUCOFINDO</h2>
            </div>
            <div
              onClick={() => {
                window.open("https://www.ptsi.co.id/");
              }}
              className={css.compItemWrapper}
            >
              <img src={img5} />
              <h1>공기업</h1>
              <h2>SURVEYOR</h2>
            </div>
          </div>
          <SectionTitle
            title="할랄 인증 진행 완료 기업"
            description=" 노하우를 통해 빠른 할랄 인증을 진행했습니다"
          />
          <div className={css["noticeListWrapper"] + " " + css.row}>
            <div style={{ cursor: "initial" }} className={css.compItemWrapper}>
              <img src={img_4} />
              <h1>인도네시아</h1>
              <h2>두끼</h2>
            </div>
            <div style={{ cursor: "initial" }} className={css.compItemWrapper}>
              <img src={img_1} />
              <h1>인도네시아</h1>
              <h2>굽네치킨</h2>
            </div>
            <div style={{ cursor: "initial" }} className={css.compItemWrapper}>
              <img src={img_3} />
              <h1>인도네시아</h1>
              <h2>GS마트</h2>
            </div>
            <div style={{ cursor: "initial" }} className={css.compItemWrapper}>
              <img src={img_2} />
              <h1>인도네시아</h1>
              <h2>던킨도너츠</h2>
            </div>
            <div style={{ cursor: "initial" }} className={css.compItemWrapper}>
              <img src={img_5} />
              <h1>인도네시아</h1>
              <h2>할로서울</h2>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"] + " " + css.gray}>
          <SectionTitle
            title="인증 서비스 소개"
            description="NU한국위원회가 제공하는 인증 서비스를 알아보세요."
          />
          <div className={css["noticeListWrapper"]}>
            <NoticeItem
              notice={
                {
                  id: 1,
                  title: "할랄인증",
                  content: `할랄(Halal)은 아랍어로 "허용된" 또는 "합법적인"을 의미하며, 이슬람 율법에 따라 허용 된 식품과 음료를 지칭합니다. 할랄인증은 식품, 음료, 화장품, 의약품 등 다양한 제품이 이슬람 율법을 준수하여 제조되었음을 보증하는 인증입니다. 신할랄인증은 기존의 할랄인증을 확장한 개념으로, 더욱 엄격한 기준을 적용하여 제품의 신뢰 성과 안전성을 보장합니다.`,
                  imgUrl: "",
                } as NoticeData
              }
              imgData={halal}
              href="/info/halal"
            />
            <NoticeItem
              imgData={bpom}
              notice={
                {
                  id: 1,
                  title: "BPOM인증",
                  content: `BPOM인증이란?식약청 인증이 BPOM(Badan Pengawas Obat dan Makanan) 인증으로도 통용됩니다. 대제품목에는 기본적으로 의약품, 의약외품, 전통의약품, 건강기능식품(건강 보조식품), 식품(일반가공식품) 화장품이 있습니다.`,
                  imgUrl: "",
                } as NoticeData
              }
              href="/info/bpom"
            />
          </div>
        </div>
        {/**
        <div className={css["sectionWrapper"]}>
          <SectionTitle
            title="서비스 바로가기"
            description="NU한국위원회 홈페이지의 모든 서비스를 알아보세요."
          />
          <div
            style={{ justifyContent: "space-between" }}
            className={css["noticeListWrapper"]}
          >
            <ServiceItem
              title="서비스 소개"
              list={[
                { text: "할랄 인증 절차", href: "/info/halal/process" },
                { text: "BPOM인증 절차", href: "/info/bpom" },
                { text: "컨설팅 프로그램", href: "/concert" },
                { text: "NU한국위원회 차별점", href: "/concert/nu" },
              ]}
            />
            <ServiceItem
              title="커뮤니티"
              list={[
                { text: "공지사항", href: "/community/notice" },
                { text: "자료실", href: "/community/data" },
              ]}
            />
            <ServiceItem
              title="고객센터"
              list={[
                { text: "1:1 문의", href: "/customer" },
                { text: "FAQ", href: "/customer/faq" },
                { text: "오시는 길", href: "/customer/map" },
              ]}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};
