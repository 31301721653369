import { getData, getPostData } from "../../../axios/axiosUtil";
import { noAuth } from "../../../setting/setting";

const url = noAuth + "/contact";

// 문의사항 등록 요청 함수
export const insertContactUs = (info: {
  title: string; // 문의 제목
  content: string; // 문의 내용
  email: string; // 이메일 주소
  phone: string; // 전화번호
  name: string; // 이름
  companyName: string; // 회사명
  contactType: string; // 문의 유형
}) => {
  return getPostData({
    path: `${url}/insert`,
    data: info,
    header: { "Content-Type": "application/json" },
  });
};
