import {
  BaseInputWithLabel,
  BaseInputWithLabelWithButton,
  BaseInputWithLabelWithTime,
} from "./component/BaseInputWithLabel";
import css from "../../css/component/main/_index.module.scss";
import { LoginInputWrapper } from "./styled/login";
import { SectionTitle } from "../main/component/sectionTitle";
import { useNavigate } from "react-router";

export const FindLoginInfo = () => {
  const navigate = useNavigate();

  return (
    <div className={css["wrapper"]}>
      <div className={css["sectionWrapper"]}>
        <SectionTitle title="비밀번호 초기화" description="" />
        <LoginInputWrapper>
          <BaseInputWithLabelWithButton
            buttonText="인증번호 발송"
            label="Email"
            placeholder="Email"
          />
          <BaseInputWithLabelWithTime label="인증번호" placeholder="인증번호" />
          <BaseInputWithLabel label="비밀번호" placeholder="비밀번호" />
          <BaseInputWithLabel
            label="비밀번호 확인"
            placeholder="비밀번호 확인"
          />
          <button
            onClick={() => navigate("/login")}
            className={css.button + " " + css.fullWidth}
          >
            비밀번호 초기화 완료
          </button>
        </LoginInputWrapper>
      </div>
    </div>
  );
};
