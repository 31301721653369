import { Link } from "react-router-dom";

const CreateSingleMenu = (
  css: any,
  url: string,
  info: { key: string; url: string; iconKey: string },
  isCenter?: boolean
) => {
  return (
    <Link to={info.url}>
      <div className={css.sideBarElem + " " + (url == info.url && css.active)}>
        <div
          style={{ textAlign: isCenter ? "center" : "left" }}
          className={css[info.iconKey + "Icon"] + " " + css.menuIcon}
        />
        <span>{info.key}</span>
      </div>
    </Link>
  );
};
export default CreateSingleMenu;
