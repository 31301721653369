import css from "../../../css/component/main/_index.module.scss";
import { SectionTitle } from "../../main/component/sectionTitle";
import halalProcess from "../../../assets/images/halalProcess.png";
import logo from "../../../assets/images/BPJPH.png";
export const Halal = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>할랄 인증 소개</h1>
              <h3>할랄 인증을 소개합니다.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="할랄인증?" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄(Halal)</h1>
            <p className={css.infoBoxWrapper}>
              <b className={css.green}>할랄(Halal)</b>은 아랍어로{" "}
              <b>"허용된" 또는 "합법적인"을 의미</b>하며, 이슬람 율법에 따라
              <b className={css.green}>허용된 식품과 음료를 지칭</b>
              합니다.
              <br />
              <b className={css.green}>할랄인증</b>은 식품, 음료, 화장품, 의약품
              등 다양한 제품이
              <b>이슬람 율법을 준수하여 제조되었음을 보증</b>하는 인증입니다.
              <br />
              <b className={css.green}>할랄인증</b>은 기존의 할랄인증을 확장한
              개념으로, <b>더욱 엄격한 기준을 적용</b>하여 제품의{" "}
              <b className={css.green}>신뢰성과 안전성을 보장</b>합니다.
            </p>
          </div>
          <div
            className={
              css.sectionTwoContentWrapper + " " + css.row + " " + css.mobileCol
            }
          >
            <div className={css.contentInfoWrapper}>
              <h3>특징 1 : 할랄 및 하람 재료의 명확한 구분</h3>
              <p>사용된 모든 재료가 할랄인지 확인합니다. </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>특징 2 : 위생 관리</h3>
              <p>제조 과정에서 철저한 청결과 위생 관리를 요구합니다. </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>특징 3 : 추적 가능성</h3>
              <p>
                원재료의 출처부터 최종 제품에 이르기까지 모든 과정을 추적할 수
                있습니다.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>대표적인 할랄 | 하람 식품</h1>
          </div>
          <div
            className={
              css.sectionTwoContentWrapper + " " + css.row + " " + css.mobileCol
            }
          >
            <div className={css.contentInfoWrapper + " " + css.green}>
              <h3>
                <span className={css.doubleCheckIcon} />
                할랄 식품
              </h3>
              <p className={css.space}>
                신선농산물, 유제품, 생선
                <br />
                밀, 쌀, 호밀 등 곡물류
                <br /> 이슬람 율법에 따라 도축된 육류 (소, 양, 산양, 낙타, 사슴,
                닭, 오리 등)
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3 className={css.gray}>
                <span className={css.xIcon} />
                하람 식품
              </h3>
              <p className={css.space}>
                돼지고기와 그 부산물 술 등
                <br /> 알코올 피와 그 부산물 <br />
                파충류와 곤충류 동물의 사체, 도축 전에 죽은 동물
                <br /> 이슬람 율법에 따라 도축되지 않은 할랄동물
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄인증의 필요성</h1>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.doubleCheckIcon} />
                소비자의 신뢰
              </h3>
              <p>
                할랄인증은 엄격한 기준을 통해 제품이 이슬람 율법을 준수했음을
                보증하여 소비자에게 신뢰를 제공합니다. 이는 제품의 품질과
                안전성을 높여 소비자의 만족도를 향상시킵니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.doubleCheckIcon} />
                시장 확대
              </h3>
              <p>
                전 세계 할랄 시장은 급성장하고 있으며, 할랄인증을 통해
                동남아시아, 중동 등 무슬림 인구가 많은 지역에 접근할 수
                있습니다. 이는 새로운 수출 기회를 창출하고 글로벌 시장에서의
                경쟁력을 강화합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.doubleCheckIcon} />
                품질 보증
              </h3>
              <p>
                할랄인증을 받은 제품은 철저한 품질 관리와 위생 관리가
                이루어졌음을 보증합니다. 이는 소비자의 만족도를 높이고 브랜드의
                명성을 쌓는 데 기여합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.doubleCheckIcon} />
                법적 요구사항 준수
              </h3>
              <p>
                일부 국가에서는 할랄 인증이 법적으로 요구됩니다. 할랄인증을 통해
                이러한 법적 요구사항을 충족하여 법적 리스크를 최소화할 수
                있습니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.doubleCheckIcon} />
                사회적 책임 실천
              </h3>
              <p>
                할랄인증은 기업이 사회적 책임을 다하고 있음을 나타내며, 긍정적인
                기업 이미지를 구축합니다. 이는 브랜드 충성도를 높이고 장기적인
                성장과 지속 가능성에 기여합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
