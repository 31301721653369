import { getLoginPostData, getPostData } from "../../axios/axiosUtil";
import {
  getJwt,
  getRefreshJwt,
  setStorage,
} from "../../sessionStorage/getAsync";
import { baseUrl } from "../../setting/setting";
const url = baseUrl + "/api/login/admin";
const refreshUrl = baseUrl + "/refresh";
const jwt = () =>
  getRefreshJwt() != "" ? JSON.parse(getRefreshJwt() as string) : null;

export const login = async (info: { id: string; pw: string }) => {
  const res = await getLoginPostData({
    path: url,
    data: info,
    header: info,
  });
  const headers = res?.headers;
  if (headers && Object.keys(headers).includes("authorization")) {
    setStorage([
      {
        key: "Authorization",
        value: JSON.stringify(headers.authorization),
      },
      {
        key: "AuthorizationRefresh",
        value: JSON.stringify(headers.authorizationrefresh),
      },
      { key: "id", value: info.id },
    ]);
    return true;
  } else {
    return false;
  }
};
export const checkJwtExpired = async () => {
  const res = ""; // await getUserInfo();
  if (res) {
    return true;
  } else {
    return false;
  }
};
export const refresh = async () => {
  const res = await getLoginPostData({
    path: refreshUrl,
    data: {},
    header: { AuthorizationRefresh: jwt() },
  });
  const headers = res?.headers;

  if (headers && Object.keys(headers).includes("authorization")) {
    return {
      auth: headers.authorization,
      refresh: headers.authorizationrefresh,
    };
  } else {
    return false;
  }
};
