import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "../../dashboardPages/main/component/dateSelector";
import { useSearchParams } from "react-router-dom";
import {
  getBpomDocs,
  getHalalDocs,
  getProductBpomPostList,
  getProductConcertInfo,
  getProductHalalPostList,
  getProductInfo,
  insertBpomDocs,
  insertHalalDocs,
  updateBpomDocFile,
  updateHalalDocFile,
} from "../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
  textSplitAndReturnComponent,
} from "../../utils/utils";
import { getFile } from "../../utils/request/user/auth/file";
import { EtcConcertingCount } from "../../dashboardPages/main/dashboard";
import {
  changeProductBpomStateIsVerified,
  changeProductHalalStateIsVerified,
  getAdminProductByIdx,
  insertBpomPost,
  insertDocFormFile,
  insertHalalPost,
  updateAbleHalalState,
  updateBpomDocFormFile,
  updateBpomDocumentState,
  updateBpomState,
  updateHalalDocFormFile,
  updateHalalDocumentState,
  updateHalalState,
  updateProductIdByAdmin,
} from "../../utils/request/admin/product";
import { docList } from "../../dashboardPages/main/productDetail";
import { IdInfoWrapper } from "../../pages/login/login";

const docObject = docList.reduce((acc: any, doc) => {
  acc[doc.type] = doc;
  return acc;
}, {});
interface ProductDetails {
  id: number; // 제품 ID
  userIdx: number; // 사용자 인덱스
  insertDate: string; // 등록 날짜 (ISO 형식)
  productName: string; // 제품 이름
  halalState: number; // 할랄 상태 (true/false)
  bpomState: number; // BPOM 상태 (true/false)
  productInfoUrl: string; // 제품 정보 URL
  productIngredientAnalysisUrl: string; // 제품 성분 분석 URL
  isNewProduct: boolean; // 신규 제품 여부
  productUniqueId: string | null; // 제품 고유 ID (null 가능)
  halalCertificationDate: string | null; // 할랄 인증 날짜 (null 가능)
  bpomCertificationDate: string | null; // BPOM 인증 날짜 (null 가능)
  halalExpiryDate: string | null; // 할랄 인증 만료 날짜 (null 가능)
  bpomExpiryDate: string | null; // BPOM 인증 만료 날짜 (null 가능)
  halalCertificationNumber: string | null; // 할랄 인증 번호 (null 가능)
  bpomCertificationNumber: string | null; // BPOM 인증 번호 (null 가능)
  halalCertificateUrl: string | null; // 할랄 인증서 URL (null 가능)
  bpomCertificateUrl: string | null; // BPOM 인증서 URL (null 가능)
  salesAmount: number; // 판매 금액
  ableHalalState: number; // 할랄 가능 상태
}
interface StateCount {
  confirmationStatusZeroCount: number; // 확인 상태 0의 개수
  typeOneStateZeroOrOneCount: number; // 타입 1이며 상태가 0 또는 1인 개수
}
interface DocState {
  state: number; // 상태 값 (예: 0, 1, 2 등)
  count: number; // 해당 상태의 문서 개수
}
interface ProductConcertState {
  halalPostState: StateCount; // 할랄 게시물 상태
  bpomPostState: StateCount; // BPOM 게시물 상태
  bpomDocState: Array<DocState>; // BPOM 문서 상태 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
  halalDocState: Array<DocState>; // 할랄 문서 상태 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
  etcConcertingStateCount: Array<EtcConcertingCount>; // 기타 컨서팅 상태 개수 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
}
interface PostItem {
  id: number; // 문서 ID
  productIdx: number; // 제품 인덱스
  title: string; // 문서 제목
  content: string; // 문서 내용
  confirmationStatus: number; // 확인 상태 (0: 미확인, 1: 확인 등)
  type: number; // 문서 타입 (예: 3)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
  insertDate: string; // 삽입 날짜 (ISO 8601 형식)
}
const ProductStateInfoItem = ({
  productInfo,
}: {
  productInfo: ProductDetails;
}) => {
  const [editState, setEditState] = useState(false);
  const [id, setId] = useState("");
  return (
    <section className={css["contract-section"]}>
      <div className={css.cardWrapper}>
        <div className={css["contract-card"] + " " + css.detail}>
          <div
            style={{ border: "none", padding: 0 }}
            className={css["popup-wrapper"]}
          >
            <div
              style={{ border: "none", padding: 0, gap: 8 }}
              className={css["popup-content"]}
            >
              <div className={css["form-group"]}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    gap: 12,
                  }}
                >
                  제품 할랄 가능 여부
                  {productInfo?.ableHalalState == 0 ? (
                    <span
                      className={css.bedge + " " + css.red + " " + css.middle}
                    >
                      불가능
                    </span>
                  ) : (
                    <span
                      className={css.bedge + " " + css.green + " " + css.middle}
                    >
                      가능
                    </span>
                  )}
                  <button
                    onClick={() => {
                      updateAbleHalalState({
                        idx: productInfo.id,
                        state: productInfo.ableHalalState == 0 ? 1 : 0,
                      }).then((data) => {
                        thenAction(data);
                      });
                    }}
                    className={css.button}
                  >
                    {productInfo?.ableHalalState == 0 ? "가능" : "불가능"}
                    으로 상태 수정
                  </button>
                </label>
              </div>
              <div className={css["form-group"]}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    gap: 12,
                  }}
                >
                  제품고유번호
                  {productInfo &&
                  productInfo.productUniqueId != "" &&
                  productInfo.productUniqueId != null ? (
                    <span
                      className={css.bedge + " " + css.green + " " + css.middle}
                    >
                      {productInfo.productUniqueId}
                    </span>
                  ) : (
                    <span
                      className={css.bedge + " " + css.red + " " + css.middle}
                    >
                      제품고유번호미등록
                    </span>
                  )}{" "}
                  <button
                    onClick={() => {
                      setEditState(!editState);
                    }}
                    className={css.button}
                  >
                    {editState ? "수정취소" : "수정하기"}
                  </button>
                </label>
                {editState && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      gap: 12,
                    }}
                  >
                    <input
                      placeholder="제품고유번호를 입력해주세요."
                      type="text"
                      style={{ width: 250 }}
                      className={css.input}
                      onChange={(e) => setId(e.target.value)}
                    />
                    <button
                      onClick={() => {
                        updateProductIdByAdmin({
                          productIdx: productInfo.id,
                          productUniqueId: id,
                        }).then((data) => {
                          if (data.message) {
                            alert(data.message);
                            setEditState(false);
                          } else {
                            alert(data.errMessage);
                          }
                        });
                      }}
                      className={css.button + " " + css.strock}
                    >
                      수정완료
                    </button>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
function AdminProductDetail() {
  const [popupVerifyDocInsertState, setPopupVerifyDocInsertState] = useState({
    docType: 0,
    state: false,
  });
  const [popupState, setPopupState] = useState({ docType: 0, state: false });
  const [popupPostState, setPopupPostState] = useState<{
    post: PostItem;
    state: boolean;
    type: number;
  }>({
    post: {
      id: 0,
      productIdx: 0,
      title: "",
      content: "",
      confirmationStatus: 0,
      type: 0,
      state: 0,
      insertDate: "",
    },
    state: false,
    type: 0,
  });
  const [popupQnaWriteState, setPopupQnaWriteState] = useState(false);
  const [searchParams] = useSearchParams();
  const [productInfo, setProductInfo] = useState<ProductDetails>();
  const [productConcertState, setProductConcertState] =
    useState<ProductConcertState>();
  const [halalPostList, setHalalPostList] = useState<Array<PostItem>>([]);
  const [bpomPostList, setBpomPostList] = useState<Array<PostItem>>([]);
  const [searchType, setSearchType] = useState(0);
  const [searchData, setSearchData] = useState<{
    idx: number;
    confirmationStatus: string;
    state: string;
    type: string;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
  }>({
    idx: Number(searchParams.get("idx")),
    confirmationStatus: "",
    state: "",
    type: "",
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    start: 0,
    count: 10,
  });
  useEffect(() => {
    getProductConcertInfo({ productIdx: Number(searchParams.get("idx")) }).then(
      (data) => {
        setProductConcertState(data.res);
      }
    );
    getAdminProductByIdx({ idx: Number(searchParams.get("idx")) }).then(
      (data) => {
        setProductInfo(data.res);
      }
    );
    searchAction();
  }, []);
  const searchAction = (searchDataForce?: {
    idx: number;
    confirmationStatus: string;
    state: string;
    type: string;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
  }) => {
    if (searchType == 2 || searchType == 0) {
      getProductBpomPostList(
        searchDataForce ? searchDataForce : searchData
      ).then((data) => {
        setBpomPostList(data.res);
      });
    }
    if (searchType == 1 || searchType == 0) {
      getProductHalalPostList(
        searchDataForce ? searchDataForce : searchData
      ).then((data) => {
        setHalalPostList(data.res);
      });
    }
  };
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>
          <span
            onClick={() => {
              window.history.back();
            }}
            className={css.leftArrowIcon}
          />
          제품상세보기
        </h1>
      </header>
      <section className={css["contract-section"]}>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>
                [{searchParams.get("company")}] {productInfo?.productName}
              </h2>
              <span>
                {productInfo &&
                  getDateStringByDate(new Date(productInfo?.insertDate))}
                접수
              </span>
            </div>
          </div>
        </div>
      </section>
      {productInfo && <ProductStateInfoItem productInfo={productInfo} />}
      <section className={css["contract-section"]}>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>
                할랄
                {productInfo?.halalState == 0 ? (
                  <span className={css.bedge + " " + css.white}>대기중</span>
                ) : productInfo?.halalState == 1 ? (
                  <span className={css.bedge + " " + css.blue}>진행중</span>
                ) : (
                  <span className={css.bedge + " " + css.green}>완료</span>
                )}
              </h2>
              <span>{getTodayDateString()} UPDATE</span>
            </div>
            <div
              style={{ border: "none", padding: 0 }}
              className={css["popup-wrapper"]}
            >
              <div
                style={{ border: "none", padding: 0, gap: 8 }}
                className={css["popup-content"]}
              >
                <div className={css["form-group"]}>
                  <label>진행상태변경</label>
                  <IdInfoWrapper>
                    선택하면 바로 바뀝니다. 주의해주세요.
                  </IdInfoWrapper>
                  <select
                    onChange={(e) => {
                      if (productInfo)
                        updateHalalState({
                          state: Number(e.target.value),
                          idx: productInfo.id,
                        }).then((data) => {
                          // 수정
                          thenAction(data);
                        });
                    }}
                    className={css.input}
                  >
                    <option value={0}>대기</option>
                    <option value={1}>진행</option>
                    <option value={1}>완료</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setPopupState({ docType: 0, state: true });
              }}
              className={css.button + " " + css.strock}
            >
              등록된 서류 확인
            </button>

            {
              /*productInfo?.halalState ==*/ true && (
                <button
                  onClick={() => {
                    setPopupVerifyDocInsertState({ docType: 0, state: true });
                  }}
                  className={css.button}
                >
                  인증서발행
                </button>
              )
            }
          </div>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>
                BPOM
                {productInfo?.bpomState == 0 ? (
                  <span className={css.bedge + " " + css.white}>대기중</span>
                ) : productInfo?.bpomState == 1 ? (
                  <span className={css.bedge + " " + css.blue}>진행중</span>
                ) : (
                  <span className={css.bedge + " " + css.green}>완료</span>
                )}
              </h2>
              <span>{getTodayDateString()}UPDATE</span>
            </div>
            <div
              style={{ border: "none", padding: 0 }}
              className={css["popup-wrapper"]}
            >
              <div
                style={{ border: "none", padding: 0, gap: 8 }}
                className={css["popup-content"]}
              >
                <div className={css["form-group"]}>
                  <label>진행상태변경</label>
                  <IdInfoWrapper>
                    선택하면 바로 바뀝니다. 주의해주세요.
                  </IdInfoWrapper>
                  <select
                    onChange={(e) => {
                      if (productInfo)
                        updateBpomState({
                          state: Number(e.target.value),
                          idx: productInfo.id,
                        }).then((data) => {
                          // 수정
                          thenAction(data);
                        });
                    }}
                    className={css.input}
                  >
                    <option value={0}>대기</option>
                    <option value={1}>진행</option>
                    <option value={1}>완료</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setPopupState({ docType: 1, state: true });
              }}
              className={css.button + " " + css.strock}
            >
              등록된 서류 확인
            </button>
            {
              /*productInfo?.halalState ==*/ true && (
                <button
                  onClick={() => {
                    setPopupVerifyDocInsertState({ docType: 1, state: true });
                  }}
                  className={css.button}
                >
                  인증서발행
                </button>
              )
            }
          </div>
        </div>
      </section>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>등록날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
              }}
              setData={(data: any) => {
                const newSearchData = { ...searchData };
                setSearchData({ ...newSearchData, ...data });
              }}
            />
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  setSearchType(Number(e.target.value));
                }}
                className={css.input}
              >
                <option value={0}>분야 전체</option>
                <option value={1}>할랄</option>
                <option value={2}>BPOM</option>
              </select>
              <select
                onChange={(e) => {
                  setSearchData({
                    ...searchData,
                    confirmationStatus: e.target.value,
                  });
                }}
                className={css.input}
              >
                <option value={""}>확인 전체</option>
                <option value={0}>대기</option>
                <option value={1}>확인</option>
                <option value={1}>완료</option>
              </select>

              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="내용으로 검색"
              ></input>
              <button
                onClick={() => {
                  searchAction();
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              <button
                onClick={() => {
                  setPopupQnaWriteState(true);
                  searchAction();
                }}
                style={{ marginLeft: "auto", alignSelf: "stretch" }}
                className={css["button"] + " " + css.strock}
              >
                게시물 등록
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              {/** th 필터 순서 바꾸기 기능 넣기  */}
              <th style={{ width: 100 }}>
                <div className={css.sortWrapper}>
                  작성일자
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 60 }}>분야</th>
              <th style={{ width: 60 }}>유형</th>
              <th>제목</th>
              <th style={{ width: 100 }}>확인 상태</th>
            </tr>
          </thead>
          <tbody>
            {halalPostList.map((post, index) => (
              <RowItem
                key={index}
                type={0}
                idx={(searchData.start / 10) * 10 + index + 1}
                post={{ ...post, id: index + 1 }}
                setPopupPostState={(post) => {
                  setPopupPostState({ post: post, state: true, type: 0 });
                }}
              />
            ))}
            {bpomPostList.map((post, index) => (
              <RowItem
                key={index}
                type={1}
                idx={(searchData.start / 10) * 10 + index + 1}
                post={{ ...post, id: halalPostList.length + index }}
                setPopupPostState={(post) => {
                  setPopupPostState({ post: post, state: true, type: 1 });
                }}
              />
            ))}
            {bpomPostList?.length == 0 && halalPostList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={6}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={100}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            searchAction({ ...searchData, start: (page - 1) * 10 });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <ProductDocsAddPopup
        productIdx={Number(searchParams.get("idx"))}
        docType={popupState.docType}
        state={popupState.state}
        cancelAction={() => {
          setPopupState({ docType: 0, state: false });
        }}
      />
      <ProductPostPopup
        post={popupPostState.post}
        state={popupPostState.state}
        type={popupPostState.type}
        cancelAction={() => {
          setPopupPostState({ ...popupPostState, state: false });
        }}
      />
      <NewProductQnaPopup
        productIdx={Number(searchParams.get("idx"))}
        state={popupQnaWriteState}
        cancelAction={() => {
          setPopupQnaWriteState(false);
          searchAction();
        }}
      />
      <ProductVerifyDocInsertPopup
        productIdx={Number(searchParams.get("idx"))}
        state={popupVerifyDocInsertState.state}
        type={popupVerifyDocInsertState.docType}
        cancelAction={() => {
          setPopupVerifyDocInsertState({ docType: 0, state: false });
          searchAction();
        }}
      />
    </div>
  );
}
const RowItem = ({
  type,
  post,
  setPopupPostState,
  idx,
}: {
  type: number;
  post: PostItem;
  setPopupPostState: (post: PostItem) => void;
  idx: number;
}) => {
  //post.type 유형 (0: 일반안내, 1: 문제안내, 2: 심사완료안내, 3: 사용자문의, 4: 문의답변)
  const renderedElement = () => {
    switch (post.type) {
      case 0: // 일반안내
        return (
          <span className={`${css.bedge} ${css.white} ${css.middle}`}>
            일반안내
          </span>
        );
      case 1: // 문제안내
        return (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            문제안내
          </span>
        );
      case 2: // 심사완료안내
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            심사완료안내
          </span>
        );
      case 3: // 사용자문의
        return (
          <span className={`${css.bedge} ${css.white} ${css.middle}`}>
            사용자문의
          </span>
        );
      case 4: // 문의답변
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            문의답변
          </span>
        );
    }
  };
  const renderedConfirmationStatus = () => {
    switch (post.confirmationStatus) {
      case 0: // 확인필요
        return (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            확인필요
          </span>
        );
      case 1: // 확인완료
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            확인완료
          </span>
        );
      case 2: // 해결완료
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            해결완료
          </span>
        );
    }
  };
  return (
    <tr>
      <td className={css.center}>{idx}</td>
      <td className={css.center}>
        {" "}
        {getDateStringByDate(new Date(post.insertDate))}
      </td>
      <td className={css.center}>
        <span>{type == 0 ? "할랄" : "BPOM"}</span>
      </td>
      <td className={css.center}>{renderedElement()}</td>
      <td>
        <span
          onClick={() => {
            setPopupPostState(post);
          }}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {post.title}
        </span>
      </td>
      <td className={css.center}>{renderedConfirmationStatus()}</td>
    </tr>
  );
};
interface Document {
  id: number; // 문서 ID
  productIdx: number; // 제품 인덱스
  docType: number; // 문서 유형 (예: 1)
  fileUrl: string; // 파일 URL
  fileName: string; // 파일 이름
  alertContent: string | null; // 알림 내용 (null 가능)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
  insertDate: string; // 생성 날짜 (ISO 8601 형식)
  updateDate: string; // 업데이트 날짜 (ISO 8601 형식)
}
export const ProductDocsAddPopup = ({
  state,
  cancelAction,
  docType,
  productIdx,
}: {
  docType: number;
  state: boolean;
  cancelAction: any;
  productIdx: number;
}) => {
  const [searchData, setSearchData] = React.useState("");
  const [uploadedDocList, setUploadedDocList] = React.useState<Array<Document>>(
    []
  );
  const docAction = () => {
    if (docType == 0) {
      getHalalDocs({ productIdx: productIdx }).then((data) => {
        setUploadedDocList(data.res);
      });
    } else {
      getBpomDocs({ productIdx: productIdx }).then((data) => {
        setUploadedDocList(data.res);
      });
    }
  };
  useEffect(() => {
    docAction();
  }, [docType]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>{docType == 0 ? "할랄" : "BPOM"}서류등록</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"] + " " + css.row}>
            <input
              onChange={(e) => setSearchData(e.target.value)}
              type="text"
              placeholder="서류명으로 검색 .."
            />
          </div>
          <div className={css.overflowSection}>
            {uploadedDocList
              .filter((item) => {
                return docObject[item.docType].title.includes(searchData);
              })
              .map((item, index) => {
                const data = {
                  ...docObject[item.docType],
                  ...item,
                };
                return (
                  <DocsFileItem
                    productIdx={productIdx}
                    docType={docType}
                    key={index}
                    title={data.title}
                    otherInfo={
                      typeof data.otherInfo === "function"
                        ? data.otherInfo(docType == 0)
                        : data.otherInfo
                    }
                    info={data.info}
                    fileData={item}
                    action={docAction}
                  />
                );
              })}
            {docList
              .filter((item) => {
                const docTypes: number[] = uploadedDocList.map(
                  (doc) => doc.docType
                );
                return (
                  item.title.includes(searchData) &&
                  !docTypes.includes(item.type)
                );
              })
              .map((item, index) => (
                <DocsItem
                  action={docAction}
                  docType={docType}
                  type={item.type}
                  key={index}
                  title={item.title}
                  otherInfo={
                    typeof item.otherInfo === "function"
                      ? item.otherInfo(docType == 0)
                      : item.otherInfo
                  }
                  productIdx={productIdx}
                  info={item.info}
                />
              ))}
          </div>
          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
const DocsMoreInfoItem = ({ item }: { item: any }) => {
  const [more, setMore] = useState(false);

  return (
    <section className={css["notice-section"]}>
      <h2 onClick={() => setMore(!more)}>{item.title}</h2>
      {more && (
        <ul>
          {item.info.split("\n").map((data: string, i: number) => (
            <li key={i}>{data}</li>
          ))}
        </ul>
      )}
    </section>
  );
};
const DocsItem = ({
  docType,
  title,
  info,
  otherInfo,
  type,
  productIdx,
  action,
}: {
  docType: number;
  type: number;
  title: string;
  info: string;
  productIdx: number;
  otherInfo?: Array<{ title: string; info: string }>;
  action: any;
}) => {
  const [more, setMore] = useState(false);
  const [file, setFile] = useState<File>(new File([], ""));
  const thenAction = (data: any) => {
    if (data.state == "200") {
      alert("서류가 등록되었습니다.");
      action();
    } else {
      alert(data.errMessage);
    }
  };
  return (
    <div className={css.docsCard}>
      <header onClick={() => setMore(!more)} className={css.header}>
        <div className={css.cardTitleWrapper}>
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            미등록
          </span>
          <h3 className={css.tab}>{title}</h3>
        </div>
        <span className={css.downArrowIcon}></span>
      </header>
      <DocCommentAndFormEditItem more={more} docType={docType} type={type} />
    </div>
  );
};
const thenAction = (data: any, action?: () => void, message?: string) => {
  if (data.message) {
    alert(message ? message : data.message);
    if (action) action();
  } else {
    alert(data.errMessage);
  }
};
const DocsFileItem = ({
  title,
  info,
  otherInfo,
  docType,
  fileData,
  productIdx,
  action,
}: {
  productIdx: number;
  docType: number;
  title: string;
  info: string;
  otherInfo?: Array<{ title: string; info: string }>;
  fileData: Document;
  action: () => void;
}) => {
  const [more, setMore] = useState(false);

  const renderState = (state: number) => {
    switch (state) {
      case 0: // 검토중
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            검토중
          </span>
        );
      case 1: // 문제발견
        return (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            문제발견
          </span>
        );
      case 2: // 등록완료
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            등록완료
          </span>
        );
      default: // 알 수 없는 상태
        return (
          <span className={`${css.bedge} ${css.gray} ${css.middle}`}>
            알 수 없는 상태
          </span>
        );
    }
  };
  return (
    <div className={css.docsCard}>
      <header onClick={() => setMore(!more)} className={css.header}>
        <div className={css.cardTitleWrapper}>
          {renderState(fileData.state)}
          <h3 className={css.tab}>{title}</h3>
        </div>
        <span className={css.downArrowIcon}></span>
      </header>
      {more && (
        <h3
          onClick={() => getFile({ path: fileData.fileUrl })}
          style={{ cursor: "pointer", color: "blue", fontWeight: 500 }}
        >
          제품에 업로드된 서류 : {fileData.fileName}
        </h3>
      )}
      {more && fileData.alertContent && (
        <section className={css["notice-section"]}>
          <h2>입력된 서류 문제 안내</h2>
          <ul>
            {fileData.alertContent?.split("\n").map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      )}
      <DocCommentAndFormEditItem
        more={more}
        docType={docType}
        fileData={fileData}
        type={fileData.docType}
      />
    </div>
  );
};
const DocCommentAndFormEditItem = ({
  more,
  type,
  docType,
  fileData,
}: {
  more: boolean;
  type: number;
  docType: number;
  fileData?: Document;
}) => {
  const [file, setFile] = useState<any>();
  const [editDocFormFile, setEditDocFormFile] = useState<boolean>(false);
  const [editDocState, setEditDocState] = useState<boolean>(false);
  const [info, setInfo] = useState<{
    idx: number;
    state: number;
    comment: string;
  }>({ idx: fileData?.id ?? 0, state: 1, comment: "" });
  return more ? (
    <>
      <div className={css["button-group"]}>
        <button
          onClick={() => setEditDocFormFile(!editDocFormFile)}
          className={css["button"] + " " + css.strock}
        >
          양식업로드 {editDocFormFile ? "취소" : ""}
        </button>
        {fileData && (
          <button
            onClick={() => setEditDocState(!editDocState)}
            className={css["button"] + " " + css.strock}
          >
            서류처리상태변경 {editDocState ? "취소" : ""}
          </button>
        )}{" "}
      </div>
      {editDocFormFile && (
        <>
          <section className={css["notice-section"]}>
            <h2>
              양식 또한 JPG, PNG, PDF, ZIP 파일만 업로드 가능합니다. (10MB 제한)
              <br />
              서류가 등록된 상태에서만 맞춤 양식을 업로드할 수 있습니다.
            </h2>
          </section>
          <div className={css["button-group"]}>
            <input
              onChange={(e) => {
                if (e.target.files) setFile(e.target.files[0]);
              }}
              className={css.input}
              type="file"
              placeholder="JPG, PNG, PDF 파일만 가능"
            />
          </div>
          <div className={css["button-group"]}>
            {fileData && (
              <button
                onClick={() => {
                  if (docType == 0) {
                    updateHalalDocFormFile({
                      productIdx: fileData.productIdx,
                      docIdx: fileData.id,
                      file: file,
                    }).then(thenAction);
                  } else {
                    updateBpomDocFormFile({
                      productIdx: fileData.productIdx,
                      docIdx: fileData.id,
                      file: file,
                    }).then(thenAction);
                  }
                }}
                className={css["button"] + " " + css.strock}
              >
                해당 제품에만 양식 업로드 적용
              </button>
            )}
            <button
              onClick={() => {
                insertDocFormFile({
                  file: file,
                  isHalal: docType,
                  docType: type,
                }).then(thenAction);
              }}
              className={css["button"] + " " + css.strock}
            >
              모든 제품에 양식 업로드 적용
            </button>
          </div>
        </>
      )}
      {editDocState && fileData && (
        <>
          <section className={css["notice-section"]}>
            <h2>서류가 문제없다면, 서류검토완료를 눌러주세요 </h2>
          </section>
          <div className={css["form-group"] + " " + css["button-group"]}>
            <textarea
              onChange={(e) => {
                setInfo({ ...info, comment: e.target.value });
              }}
              className={css.input}
              placeholder="서류 문제 안내 입력"
            />
            <button
              onClick={() => {
                if (docType == 0) {
                  updateHalalDocumentState(info).then(thenAction);
                } else {
                  updateBpomDocumentState(info).then(thenAction);
                }
              }}
              className={css["button"] + " " + css.strock}
            >
              문제등록
            </button>
          </div>
          <div className={css["form-group"] + " " + css["button-group"]}>
            <button
              onClick={() => {
                if (docType == 0) {
                  updateHalalDocumentState({
                    idx: fileData.id,
                    state: 2,
                  }).then(thenAction);
                } else {
                  updateBpomDocumentState({
                    idx: fileData.id,
                    state: 2,
                  }).then(thenAction);
                }
              }}
              className={css["button"]}
            >
              서류검토완료
            </button>
          </div>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
export const NewProductQnaPopup = ({
  state,
  cancelAction,
  productIdx,
}: {
  state: boolean;
  cancelAction: any;
  productIdx: number;
}) => {
  const [qnaInfo, setQnaInfo] = useState<{
    productIdx: number;
    title: string;
    content: string;
    type: string;
  }>({
    title: "",
    content: "",
    productIdx: productIdx,
    type: "0",
  });
  const [qnaType, setQnaType] = useState(0);
  useEffect(() => {
    setQnaInfo({
      title: "",
      content: "",
      productIdx: productIdx,
      type: "0",
    });
  }, [productIdx]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          //cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>코멘트 등록</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>컨설팅 유형</label>
            <select
              value={qnaType}
              onChange={(e) => setQnaType(Number(e.target.value))}
              className={css.input}
            >
              <option value={0}>할랄</option>
              <option value={1}>BPOM</option>
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>알림유형</label>
            <select
              onChange={(e) => setQnaInfo({ ...qnaInfo, type: e.target.value })}
              className={css.input}
            >
              <option value={0}>일반안내</option>
              <option value={1}>문제안내</option>
              <option value={2}>심사완료안내</option>
              <option value={3}>사용자문의</option>
              <option value={4}>문의답변</option>
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>제목</label>
            <input
              value={qnaInfo.title}
              onChange={(e) =>
                setQnaInfo({ ...qnaInfo, title: e.target.value })
              }
              type="text"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>내용</label>
            <textarea
              onChange={(e) =>
                setQnaInfo({ ...qnaInfo, content: e.target.value })
              }
              className={css.input}
              value={qnaInfo.content}
            />
          </div>

          <button
            onClick={async () => {
              if (qnaInfo.title == "" || qnaInfo.content == "") {
                alert("제목과 내용을 입력해주세요.");
                return;
              }
              if (qnaType == 0) {
                await insertHalalPost(qnaInfo);
              } else {
                await insertBpomPost(qnaInfo);
              }
              alert(
                (qnaType == 0 ? "할랄" : "BPOM") +
                  "게시물 등록이 완료되었습니다. "
              );
              setQnaInfo({
                title: "",
                content: "",
                productIdx: productIdx,
                type: "0",
              });
              cancelAction();
            }}
            className={css["button"]}
          >
            등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export const ProductPostPopup = ({
  post,
  type,
  state,
  cancelAction,
}: {
  type: number;
  post: PostItem;
  state: boolean;
  cancelAction: any;
}) => {
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>상세보기</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>인증유형</label>
            <span>{type == 0 ? "할랄인증" : "BPOM인증"}</span>
          </div>

          <div className={css["form-group"]}>
            <label>제목</label>
            <span>{post.title}</span>
          </div>

          <div className={css["form-group"]}>
            <label>내용</label>
            <p>{textSplitAndReturnComponent(post.content)}</p>{" "}
          </div>

          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
export const ProductVerifyDocInsertPopup = ({
  state,
  cancelAction,
  productIdx,
  type,
}: {
  state: boolean;
  cancelAction: any;
  productIdx: number;
  type: number;
}) => {
  const [verifyInfo, setVerifyInfo] = useState<{
    idx: number;
    file: File;
    certificationDate: string;
    expiryDate: string;
    certificationNumber: string;
  }>({
    idx: productIdx,
    file: new File([], ""),
    certificationDate: "",
    expiryDate: "",
    certificationNumber: "",
  });
  const [qnaType, setQnaType] = useState(0);
  const [file, setFile] = useState<any>();

  useEffect(() => {
    setVerifyInfo({
      idx: productIdx,
      file: new File([], ""),
      certificationDate: "",
      expiryDate: "",
      certificationNumber: "",
    });
  }, [productIdx]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          //cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>{type == 0 ? "HALAL" : "BPOM"}인증서 등록</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>인증서 파일</label>
            <input
              onChange={(e) => {
                console.log(e.target.files);
                if (e.target.files)
                  setVerifyInfo({ ...verifyInfo, file: e.target.files[0] });
              }}
              className={css.input}
              type="file"
              placeholder="JPG, PNG, PDF 파일만 가능"
            />
          </div>
          <div className={css["form-group"]}>
            <label>인증서 고유번호</label>
            <input
              value={verifyInfo.certificationNumber}
              onChange={(e) =>
                setVerifyInfo({
                  ...verifyInfo,
                  certificationNumber: e.target.value,
                })
              }
              type="text"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>인증완료일자(인증서발행일)</label>
            <input
              value={verifyInfo.certificationDate}
              onChange={(e) =>
                setVerifyInfo({
                  ...verifyInfo,
                  certificationDate: e.target.value,
                })
              }
              type="date"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>인증만료일자</label>
            <input
              value={verifyInfo.expiryDate}
              onChange={(e) =>
                setVerifyInfo({
                  ...verifyInfo,
                  expiryDate: e.target.value,
                })
              }
              type="date"
              placeholder=""
            />
          </div>

          <button
            onClick={async () => {
              console.log(verifyInfo);
              if (
                verifyInfo.certificationNumber == "" ||
                verifyInfo.certificationDate == "" ||
                verifyInfo.file.name == "" ||
                verifyInfo.expiryDate == ""
              ) {
                alert("모든 요소를 입력해주세요.");
                return;
              }
              if (type == 0) {
                await changeProductHalalStateIsVerified(verifyInfo).then(
                  thenAction
                );
              } else {
                await changeProductBpomStateIsVerified(verifyInfo).then(
                  thenAction
                );
              }
              cancelAction();
            }}
            className={css["button"]}
          >
            등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export default AdminProductDetail;
