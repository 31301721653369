import { ReactElement, useEffect, useState } from "react";
import React from "react";

export const UrlContext = React.createContext({
  url: "",
  setUrl: (data: string) => {},
});
export const UrlContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [url, setUrl] = useState<string>("/main");

  return (
    <UrlContext.Provider
      value={{ url, setUrl } as { url: string; setUrl: (data: string) => void }}
    >
      {children}
    </UrlContext.Provider>
  );
};
