import css from "../../../css/component/main/_index.module.scss";
export const SectionTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className={css.titleWrapper}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};
