import css from "../../../css/component/main/_index.module.scss";
export const PageNation = ({
  currentPage,
  setCurrentPage,
  totalPages,
  count,
}: {
  count?: number;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) => {
  const renderPageNumbers = () => {
    const pages = [];

    // 표시할 페이지 번호 범위 계산
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(currentPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={i === currentPage ? css["active"] : ""}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={css["page-nav"]}>
      {/* 이전 버튼 */}
      <button
        onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
      >
        <div className={css["leftThinArrowIcon"]} />
      </button>

      {/* 페이지 번호 버튼 */}
      {renderPageNumbers()}

      {/* 다음 버튼 */}
      <button
        onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
      >
        <div className={css["rightThinArrowIcon"]} />
      </button>
    </div>
  );
};
