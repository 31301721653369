import { ReactElement, useContext, useEffect, useState } from "react";
import { UrlContext } from "../../../context/url";
import headerNames from "../../../staticSettings/sideBarAndHeader/urlHeaderTextSetting";
import { useNavigate } from "react-router";
import { getUserInfo } from "../../../utils/request/user/auth/user";
import { getUserDashboardInfo } from "../../../utils/request/user/auth/product";
import {
  AlertDetailData,
  AlertDetailListPopup,
  AlertItem,
  AlertItemData,
  DashboardData,
} from "../../main/dashboard";
export interface CompanyInfo {
  idx: number; // 사용자 ID
  businessId: string; // 사업자 ID
  companyName: string; // 회사 이름
  managerName: string; // 담당자 이름
  phoneNumber: string; // 전화번호
  email: string; // 이메일
  faxNumber: string; // 팩스 번호
  businessFileUrl: string | null; // 사업자 파일 URL (null 가능)
  postNumber: string; // 우편 번호
  address: string; // 주소
  addressDetail: string; // 상세 주소
  adminName: string | null; // CS 관리자 이름 (null 가능)
  salesPersonName: string; // 영업 담당자 이름
}
const Header = ({
  css,
  children,
  setOpenSideBar,
}: {
  children: ReactElement;
  css: any;
  setOpenSideBar: () => void;
}) => {
  const { url } = useContext(UrlContext);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const [subMenuState, setSubMenuState] = useState(false);
  const [userInfo, setUserInfo] = useState<CompanyInfo>({
    idx: 0,
    businessId: "",
    companyName: "",
    managerName: "",
    phoneNumber: "",
    email: "",
    faxNumber: "",
    businessFileUrl: null,
    postNumber: "",
    address: "",
    addressDetail: "",
    adminName: null,
    salesPersonName: "",
  });
  const [dashboardInfo, setDashboardInfo] = useState<DashboardData>();
  const [alertPopup, setAlertPopup] = useState({
    state: false,
    alertData: [] as Array<AlertDetailData>,
  });

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUserInfo(res.res);
        getUserDashboardInfo().then((res) => {
          setDashboardInfo({
            ...res.res,
            etcConcertList:
              res.res.etcConcertList.length > 0
                ? res.res.etcConcertList
                : [
                    { type: 0, state: 0, count: 0 },
                    { type: 0, state: 1, count: 0 },
                    { type: 1, state: 0, count: 0 },
                    { type: 1, state: 1, count: 0 },
                    { type: 2, state: 0, count: 0 },
                    { type: 2, state: 1, count: 0 },
                    { type: 3, state: 0, count: 0 },
                    { type: 3, state: 1, count: 0 },
                  ],
          });
        });
      })
      .catch((err) => {
        window.alert("다시 로그인 해주세요.");
        navigate("/login");
      });
  }, []);
  return (
    <>
      <div className={css.contentWrapper}>
        <div className={css.headerWrapper}>
          <span>{headerNames[url]}</span>
          <div className={css["notification-area"]}>
            <button
              onClick={() => {
                setAlert(!alert);
              }}
              className={css["notification-btn"]}
            >
              <span
                className={css["notification-icon"] + " " + css["alertIcon"]}
              ></span>
            </button>
            <div className={css.alertList + " " + (alert ? css.active : "")}>
              <section className={css["alert-section"]}>
                <div className={css.alertListWrapper}>
                  {dashboardInfo?.alertList &&
                  dashboardInfo?.alertList.length > 0 ? (
                    dashboardInfo?.alertList.map(
                      (alert: AlertItemData, idx: number) => (
                        <AlertItem
                          onClick={(data: Array<AlertDetailData>) =>
                            setAlertPopup({ state: true, alertData: data })
                          }
                          css={css}
                          key={"header-alert-" + idx}
                          alert={alert}
                        />
                      )
                    )
                  ) : (
                    <div className={css["alert-item"] + " " + css.important}>
                      <span className={css.titleWrapper}>
                        <span>알림이 없습니다.</span>
                      </span>
                    </div>
                  )}{" "}
                </div>
              </section>
            </div>

            <div
              onClick={() => {
                setSubMenuState(!subMenuState);
              }}
              className={css["user-info"] + " " + css.mobileNone}
              style={{ cursor: "pointer" }}
            >
              <span className={css["user-name"]}>
                {userInfo.companyName}님{" "}
              </span>
            </div>
            <div
              className={
                css.alertList +
                " " +
                (subMenuState ? css.active : "") +
                " " +
                css.mobileNone
              }
            >
              <section className={css["alert-section"]}>
                <div
                  style={{ alignItems: "center" }}
                  className={css.alertListWrapper}
                >
                  <div className={css["user-info"] + " " + css.mobileNone}>
                    <span
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => navigate("/login")}
                      className={css["user-name"]}
                    >
                      LOGOUT
                    </span>
                  </div>
                  <div className={css["user-info"] + " " + css.mobileNone}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/")}
                      className={css["user-name"]}
                    >
                      홈페이지 바로가기
                    </span>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className={css["notification-area"] + " " + css.pcNone}>
            <div style={{ marginLeft: "auto" }} className={css["user-info"]}>
              <span
                onClick={() => setOpenSideBar()}
                className={css["user-name"]}
              >
                MENU
              </span>
            </div>
          </div>
        </div>
        <div className={css.contentDiv}>{children}</div>
      </div>
      <AlertDetailListPopup
        alertList={alertPopup.alertData}
        type={0}
        state={alertPopup.state}
        cancelAction={() => {
          setAlertPopup({ state: false, alertData: [] });
        }}
      />
    </>
  );
};
export default Header;
