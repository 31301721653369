import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "./component/dateSelector";
import { useNavigate } from "react-router";
import { getProductListByUser } from "../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
} from "../../utils/utils";
import { getTotalPage } from "../../pages/community/notice";
interface Product {
  contractStartDate: string | null; // 계약 시작 날짜
  contractEndDate: string | null; // 계약 종료 날짜
  email: string; // 이메일 주소
  productIdx: number; // 제품 인덱스
  csAdminIdx: number | null; // 고객 서비스 관리자 인덱스
  phoneNumber: string; // 전화번호
  managerName: string; // 담당자 이름
  companyName: string; // 회사 이름
  productIngredientAnalysisUrl: string; // 제품 성분 분석 URL
  productInfoUrl: string; // 제품 정보 URL
  halalState: number; // 할랄 상태
  bpomState: number; // BPOM 상태
  productName: string; // 제품 이름
  salesAmount: number; // 판매 금액
  businessId: string; // 사업자 ID
  salesManagerIdx: number; // 영업 관리자 인덱스
  salesPersonName: string; // 영업 담당자 이름
  csManagerName: string | null; // 고객 서비스 관리자 이름
  isNew: number; // 신규 여부 (1: 신규, 0: 기존)
  insertDate: string;
}

function ProductSearch() {
  const count = 10;
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<{
    concertType: "전체" | "할랄" | "BPOM";
    state: number;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
    search: string;
  }>({
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    concertType: "전체",
    state: 0,
    start: 0,
    count: count,
    search: "",
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalProductCount, setTotalProductCount] = useState<number>(0);
  const [totalSearchedProductCount, setSearchedTotalProductCount] =
    useState<number>(0);
  const [productList, setProductList] = useState<Array<Product>>();
  useEffect(() => {
    getProductListByUser(searchData).then((res) => {
      setProductList(res.res);
      setTotalPages(getTotalPage(res, count));
      setTotalProductCount(res.message.match(/,(\d+)/)[1] ?? 0);
      setSearchedTotalProductCount(res.message.match(/,(\d+)/)[1] ?? 0);
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>제품검색</h1>
        <button
          onClick={() => {
            navigate("/dashboard/product/add");
          }}
          className={css["button"] + " " + css.shadow}
        >
          <span className={css.dashboardPlusIcon + " " + css.white} />
          새제품 등록
        </button>
      </header>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>접수날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
              }}
              setData={(data: any) => {
                const newSearchData = { ...searchData };
                setSearchData({ ...newSearchData, ...data });
              }}
            />
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  const value = e.target.value as "전체" | "할랄" | "BPOM";
                  setSearchData({
                    ...searchData,
                    concertType: value,
                  });
                }}
                className={css.input}
              >
                <option value={"전체"}>분야 전체</option>
                <option value={"할랄"}>할랄</option>
                <option value={"BPOM"}>BPOM</option>
                {/*
                <option>인허가</option>
                <option>촬영</option>
                <option>언어변환</option>
                <option>디자인</option>*/}
              </select>
              <select
                onChange={(e) => {
                  const value = e.target.value as "0" | "1";
                  setSearchData({
                    ...searchData,
                    state: Number(value),
                  });
                }}
                className={css.input}
              >
                <option value="0">진행</option>
                <option value="1">완료</option>
              </select>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="제품명으로 검색"
                onChange={(e) => {
                  setSearchData({ ...searchData, search: e.target.value });
                }}
              ></input>
              <button
                onClick={() => {
                  getProductListByUser({ ...searchData, start: 0 }).then(
                    (res) => {
                      setProductList(res.res);
                      setTotalPages(getTotalPage(res, count));
                      setSearchedTotalProductCount(
                        res.message.match(/,(\d+)/)[1] ?? 0
                      );
                    }
                  );
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              {/**
              <button className={css["button"] + " " + css.strock}>
                <span className={css.dashboardResetIcon} />
                필터 초기화
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span
              style={{ fontSize: 18 }}
              className={css.bedge + " " + css.white}
            >
              총 등록된 제품 수 {totalProductCount}
            </span>
            <span
              style={{ fontSize: 18 }}
              className={css.bedge + " " + css.white}
            >
              검색된 제품 수 {totalSearchedProductCount}
            </span>
          </div>
        </div>
      </section>

      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  등록번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 100 }}>
                <div className={css.sortWrapper}>
                  접수일
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th>제품명</th>
              <th style={{ width: 100 }}>할랄 상태</th>
              <th style={{ width: 100 }}>BPOM 상태</th>
            </tr>
          </thead>
          <tbody>
            {productList?.map((product, idx) => (
              <RowItem
                idx={(searchData.start / 10) * 10 + idx + 1}
                product={product}
                key={"product-" + idx}
              />
            ))}
            {productList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={totalPages}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getProductListByUser({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setProductList(res.res);
              setTotalPages(getTotalPage(res, count));
              setSearchedTotalProductCount(res.message.match(/,(\d+)/)[1] ?? 0);
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
    </div>
  );
}
const RowItem = ({ product, idx }: { product: Product; idx: number }) => {
  return (
    <tr>
      <td className={css.center}>{idx}</td>
      <td className={css.center}>{product.productIdx}</td>
      <td className={css.center}>
        {getDateStringByDate(new Date(product.insertDate))}
      </td>
      <td>
        <a
          style={{ cursor: "pointer", color: "blue" }}
          href={"/dashboard/product/view?idx=" + product.productIdx}
        >
          {product.productName}
        </a>
      </td>
      <td className={css.center}>
        {product.halalState == 0 ? (
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            대기중
          </span>
        ) : product.halalState == 1 ? (
          <span className={css.bedge + " " + css.blue + " " + css.middle}>
            처리중
          </span>
        ) : (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            완료
          </span>
        )}
      </td>
      <td className={css.center}>
        {product.bpomState == 0 ? (
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            대기중
          </span>
        ) : product.bpomState == 1 ? (
          <span className={css.bedge + " " + css.blue + " " + css.middle}>
            처리중
          </span>
        ) : (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            완료
          </span>
        )}
      </td>
    </tr>
  );
};
export const NewProductAddPopup = ({
  state,
  cancelAction,
}: {
  state: boolean;
  cancelAction: any;
}) => {
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>제품접수</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>제품접수 안내</h2>
            <ul>
              <li>
                제품 접수 시 할랄 인증과 BPOM 인증 심사를 동시에 진행합니다.
              </li>
              <li>
                제품 접수 후 서류 등록을 하지 않으면 할랄 인증 및 BPOM 인증이
                진행되지 않습니다.
              </li>
            </ul>
          </section>

          <div className={css["form-group"]}>
            <label>제품명</label>
            <input type="text" placeholder="" />
          </div>

          <div className={css["form-group"]}>
            <label>제품소개서 (선택사항)</label>
            <input type="file" placeholder="JPG, PNG, PDF 파일만 가능" />
          </div>

          <div className={css["form-group"]}>
            <label>성분분석표</label>
            <input type="file" placeholder="JPG, PNG, PDF 파일만 가능" />
          </div>

          <button
            onClick={() => {
              alert("제품등록이 완료되었습니다. 등록까지 최대 3일 소요됩니다.");
              cancelAction();
            }}
            className={css["button"]}
          >
            제품등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProductSearch;
