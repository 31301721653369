import css from "../../../css/component/main/_index.module.scss";
import { SectionTitle } from "../../main/component/sectionTitle";
import bpomFoodProcess from "../../../assets/images/bpomFoodProcess.png";
import bpomBeautyProcess from "../../../assets/images/beautiyProcess.png";
import { useNavigate } from "react-router";
export const Bpom2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>BPOM(식약청) 등록 절차</h1>
              <h3>BPOM인증을 알아볼 수 있습니다.</h3>
            </div>
          </div>
        </div>

        <div className={css["sectionWrapper"]}>
          <SectionTitle title="BPOM(식약청) 등록 절차" description="" />

          <div className={css.sectionFirstContentWrapper}>
            <h1>
              BPOM인증 <br />
              화장품
            </h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={bpomBeautyProcess}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>등록 접수 비용 </h3>
              <p>
                인허가 기간은 제조사의 등록 업무에 1개월 이내, 제품의 심사 4주
                이내로 구성되어 있으나 식약청의 업무 일정에 추가 소요될 수
                있습니다. 현재 평균 신규등록의 경우 3개월 이내 완료되고 있으며
                이는 화장품의 경우는 서류적 기본요건 사항으로 제품의 명칭,
                재료의 화장품 사용가능 기준 적합 부분만을 우선 기준으로 심사하여
                빠른 허가를 내어 주고 있습니다. 한편, 사후 관리제에 따른 화장품
                관리 서류의 비취관리가 허가유지 등록에 매우 중요합니다. -
                화장품의 등록 비용 : 아세아, 유럽, 미주 등으로 지역별 구분하게
                되며, 한국은 아세아에 포함되어 구분 코드 NA 로 등록 접수
                납입금은 Rp.1,500,000입니다.
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>주요 특이 사항 </h3>
              <p>
                화장품의 허가 심사가 비교적 다른 품목에 비하여 간소화되어 있는
                편이나 신재료 등록이 필요하거나, 제품의 명칭에 대한 평가와 그
                기능성의 입증 자료 요구에 따라 심사 기간이 추가 될 수 있는 점도
                참고 되어야 합니다. 제품의 시험 성적서의 화장품 유형에 따른
                미생물 및 중금속검사 항목과 그 기준이 적합하여야 하며 이에
                보편적으로 인도네시아 시험인정 기관에 의뢰 하시어 검사받는것이
                업무의 편리성과 비용적면에서 효과적입니다. 후속 관리로는
                식약청으로부터 등록 허가가 완료된 제품에 대한 DIP(data
                information product)* 서류 관리 부분에 대해 감사 받게 됩니다.
                DIP 구성서류 제품 정보 서류집으로, Data information product의
                약자입니다. 해당제품별 제품에 사용 전성분 재료에 대한 스펙과
                제조 공정 등의 기술서류, 허가서 사본, 포장 디자인, 라벨 표기
                사항 등을 일괄 순차적 정리하여 제품별로 편철 해야 합니다.
              </p>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1> 기타 요구사항</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>
                수입 <br />
                요구사항{" "}
              </h3>
              <p>
                <span>
                  - 수입식품 및 의약품에 유통허가 (Izin Edar)를 받아야 함.
                </span>
                <span>
                  - 유통허가를 받기 위해서 수입업자는 수입과 관련된 규정을
                  준수해야 함.
                </span>
                <span>
                  - 수입업자는 식약청장으로부터 수입 승인(SKI, Surat
                  KeteranganImpor)을 받아야 하고 수입승인의 신청 횟수는 수입시
                  1회이며자유무역지대, 자유항지대, 보세공단으로 들여오는 제품에
                  적용됨.
                </span>
                <span>
                  - 의약품, 전통의약품, 건강보조제, 화장품의 경우 유통기한의
                  최대 1/3이 남아있어야 하고 백신, 면역 혈청, 항원, 호르몬, 혈액
                  관련제품, 건강을 목적으로 한 발효제품등 생물학적 제품의 경우
                  최소 9개월 이전에 수입돼야 하며, 일반 가공 식품의 경우
                  유통기한의 최대2/3가 남아있어야 함.
                </span>
                <span>
                  - 해당 품목군: HS 코드 04, 13, 16, 17, 18, 19, 20, 21, 22, 30,
                  33, 34, 38
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>
                등록 절차 및<br /> 필수 서류{" "}
              </h3>
              <p>
                <span>
                  - 의약품 및 식품의 수입은 대리인의 유통 허가권을 보유하는
                  자만이 가능
                </span>
                <span>
                  - 유통 허가증을 보유한 제약산업체는 유통하고자 하는 제품의
                  품질 승인을 득한 후에 다른 제약산업체 또는 도매업자를
                  수입업자로 지정할 수 있음.
                </span>
                <span>
                  - SKI 신청 업자는 식약청{" "}
                  <a
                    style={{ fontWeight: 700, textDecoration: "underline" }}
                    href="https://e-bpom.pom.go.id/"
                  >
                    웹사이트
                  </a>
                  와{" "}
                  <a
                    style={{ fontWeight: 700, textDecoration: "underline" }}
                    href="https://www.insw.go.id/"
                  >
                    Indonesia National Single Window
                  </a>
                  를 통해 수입승인을 신청해야 함.
                </span>
                <b className={css.green}>- 수입승인(SKI) 신청 구비 서류 </b>
                <b>
                  수입승인 신청자는 수입 업체 사장으로부터 위임장 원본을 득해야
                  함.
                </b>
                <b>수입 업체 직인이 날인된 신청서 원본 </b>
                <b> 수입자 고유등록번호(API). </b>
                <b> 납세자 번호(NPWP). </b>
                <b>사업자 등록증(SIUP) 사본</b>
                <b className={css.green}>- 수입승인(SKI) 신청보조서류 </b>
                <b>유통승인 공식 레터 (NIE-Nomor Izin Edar) </b>
                <b>성분분석증명서 (COA-제조사의 자가 시험 증명서) </b>
                <b> 송장 납세 증빙(NPWP) </b>
                <span>
                  - 성분분석 증명서에는 제품명, 조항에 따른 제품 검사결과
                  분석방법,제품 생산날짜 코드(batch number), 로트 번호(lot
                  number) 생산코드, 생산일자, 유효기간 등이 표시돼 있어야 함.
                  또한 시험 검사항목은 각 제품의 카테고리 별 의무 시험
                  검사항목이 포함 되어 있어야 하며 그 기준이 충족 되어야 함.
                </span>
                <span>
                  - 백신 제품 수입 승인에 대해 다음과 같은 서류를 구비해야 함.
                </span>
                <span>
                  - 백신이 각 진입에 대해 승인된 원산지 기관의 기관으로부터의
                  배치 또는 로트 공개 증명서 및 제조자가 발행한 요약 배치 또는
                  로트 프로토콜
                </span>
                <span>
                  - 서류를 구비한 후 인증을 받는 데 걸리는 기간은 평균 3개월
                  안팎임.
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>재수입</h3>
              <p>
                <span>
                  - 인도네시아로 의약품 및 식품을 재수입하는 경우 규정에 명시된
                  대로 수입을 이행해야 함.{" "}
                </span>
                <span>
                  - 해당 제품 재수입시 의약품과 식품 원자재의 인도네시아산 여부
                  및 재수입 사유를 명기한 수출증명서를 동봉해야 하며, 해당 수출
                  증명서는 식약청 또는 관련 대행기관에서 발행돼야 함.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
