import styled from "styled-components";
import { BaseInputWithLabel } from "./component/BaseInputWithLabel";
import css from "../../css/component/main/_index.module.scss";
import { SectionTitle } from "../main/component/sectionTitle";
import { useNavigate } from "react-router";
import { useState } from "react";
import { login } from "../../utils/request/user/login";
import { LoginInputWrapper } from "./styled/login";
export const Login = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    id: "",
    pw: "",
  });
  return (
    <div className={css["wrapper"]}>
      <div className={css["sectionWrapper"]}>
        <SectionTitle title="로그인" description="" />
        <LoginInputWrapper>
          <BaseInputWithLabel
            onChange={(e) => setInfo({ ...info, id: e.target.value })}
            label="사업자등록번호"
            placeholder="사업자등록번호"
          />
          <IdInfoWrapper>
            10자의 사업자 등록번호를 입력해주세요. (-제외){" "}
          </IdInfoWrapper>
          <BaseInputWithLabel
            isPw={true}
            onChange={(e) => setInfo({ ...info, pw: e.target.value })}
            label="PASSWORD"
            placeholder="PASSWORD"
          />
          <button
            onClick={() => {
              login(info).then((res) => {
                if (res) {
                  navigate("/dashboard");
                } else {
                  alert("로그인 실패");
                }
              });
            }}
            className={css.button + " " + css.fullWidth}
          >
            로그인
          </button>
          <LoginInfoFindButtonListWrapper>
            <LoginInfoFindButton
              onClick={(e) => {
                e.preventDefault();
                alert("비밀번호 찾기는 준비중입니다. 관리자에게 문의해주세요.");
              }}
              href="/find"
            >
              <LoginInfoFindButtonText> 비밀번호 찾기</LoginInfoFindButtonText>
            </LoginInfoFindButton>
          </LoginInfoFindButtonListWrapper>
        </LoginInputWrapper>
        <LoginButtonWrapper>
          <JoinInfoWrapper>
            <JoinInfoText>아직 업체 등록을 하지 않으셨나요?</JoinInfoText>
            <a
              href="/join"
              className={css.button + " " + css.strock + " " + css.fullWidth}
            >
              업체등록
            </a>
          </JoinInfoWrapper>
        </LoginButtonWrapper>
      </div>
    </div>
  );
};
export const IdInfoWrapper = styled.div`
  display: flex;
  padding: var(--4, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--3, 12px);
  align-self: stretch;
  color: #3f424f;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 6px;
  background: #fcfcfc;
`;
const JoinInfoWrapper = styled.div`
  display: flex;
  padding-top: 28px;
  justify-content: center;
  align-items: center;
  gap: var(--3, 12px);
  flex-direction: column;
  width: 100%;
`;
const JoinInfoText = styled.span`
  color: #303033;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 600px) {
    white-space: nowrap;
  }
`;
const JoinLinkTextWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--3, 0px);
  text-decoration: none;
`;
const JoinLinkText = styled.span`
  color: #5d1d96;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const JoinLinkTextIcon = styled.div`
  filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(5705%)
    hue-rotate(270deg) brightness(96%) contrast(93%);
  width: 18px;
  height: 18px;
`;

const LoginButton = styled.button`
  border-radius: 36px;
  border: 1px solid #fdfeff;
  background: #017534;
  display: flex;
  padding: var(--2, 12px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  width: 100%;
`;
const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const LoginInfoFindButtonListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--6, 28px);
  width: 100%;
`;
const LoginInfoFindButton = styled.a`
  border: none;
  background: none;
  text-decoration: none;
  padding: 6px 0;
`;
const LoginInfoFindButtonText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
`;
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 33%;
`;
