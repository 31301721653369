import styled from "styled-components";
import {
  BaseDataSelectWithLabel,
  BaseFileInputWithLabel,
  BaseInputWithLabel,
  BaseInputWithLabelWithAlert,
  BaseInputWithLabelWithButton,
  BaseInputWithLabelWithTime,
} from "./component/BaseInputWithLabel";
import css from "../../css/component/main/_index.module.scss";
import { SectionTitle } from "../main/component/sectionTitle";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IdInfoWrapper } from "./login";
import {
  checkVerifyCode,
  getSalesPersonList,
  regUser,
  sendVerifyCode,
} from "../../utils/request/user/noauth/reg";
import { LoginInputWrapper } from "./styled/login";
declare const daum: any;
export interface AddressData {
  postcode: string; // 우편번호 (구버전)
  postcode1: string; // 우편번호 첫 부분 (구버전)
  postcode2: string; // 우편번호 두 번째 부분 (구버전)
  postcodeSeq: string; // 우편번호 시퀀스
  zonecode: string; // 우편번호 (새 버전)
  address: string; // 전체 주소
  addressEnglish: string; // 전체 주소 (영문)
  addressType: string; // 주소 타입 ("J": 지번, "R": 도로명)
  bcode: string; // 법정동 코드
  bname: string; // 법정동/법정리 이름
  bnameEnglish: string; // 법정동/법정리 이름 (영문)
  bname1: string; // 법정동 이름 1
  bname1English: string; // 법정동 이름 1 (영문)
  bname2: string; // 법정동 이름 2
  bname2English: string; // 법정동 이름 2 (영문)
  sido: string; // 시/도
  sidoEnglish: string; // 시/도 (영문)
  sigungu: string; // 시/군/구
  sigunguEnglish: string; // 시/군/구 (영문)
  sigunguCode: string; // 시/군/구 코드
  userLanguageType: string; // 사용자의 언어 타입
  query: string; // 검색어
  buildingName: string; // 건물 이름
  buildingCode: string; // 건물 코드
  apartment: string; // 아파트 여부 ("Y" 또는 "N")
  jibunAddress: string; // 지번 주소
  jibunAddressEnglish: string; // 지번 주소 (영문)
  roadAddress: string; // 도로명 주소
  roadAddressEnglish: string; // 도로명 주소 (영문)
  autoRoadAddress: string; // 자동 완성된 도로명 주소
  autoRoadAddressEnglish: string; // 자동 완성된 도로명 주소 (영문)
  autoJibunAddress: string; // 자동 완성된 지번 주소
  autoJibunAddressEnglish: string; // 자동 완성된 지번 주소 (영문)
  userSelectedType: string; // 사용자가 선택한 주소 타입 ("J" 또는 "R")
  noSelected: string; // 선택 여부 ("Y" 또는 "N")
  hname: string; // 행정동 이름
  roadnameCode: string; // 도로명 코드
  roadname: string; // 도로명 이름
  roadnameEnglish: string; // 도로명 이름 (영문)
}
interface CompanyData {
  name: string; // 회사 이름
  type: string; // 타입 (예: "0")
  levelName: string; // 레벨 이름 (예: "company")
  percent: number; // 퍼센트 (숫자형)
  idx: number; // 인덱스
  levelIdx: number | null; // 레벨 인덱스 (null 가능)
  parentIdx: number | null; // 부모 인덱스 (null 가능)
  oldParentPercent: number | null; // 이전 부모 퍼센트 (null 가능)
  parentPercent: number | null; // 부모 퍼센트 (null 가능)
  totalWithdrawAmount: number | null; // 총 출금 금액 (null 가능)
  insertDate: string | null; // 입력 날짜 (null 가능)
  saleAmount: number | null; // 판매 금액 (null 가능)
}

export const Join = () => {
  const navigate = useNavigate();
  const [joinReqData, setJoinReqData] = useState<{
    id: string;
    companyName: string;
    managerName: string;
    salesPersonIdx: string;
    phone: string;
    fax: string;
    pw: string;
    email: string;
    file: File;
    postNumber: string;
    address: string;
    detailAddress: string;
  }>({
    id: "",
    companyName: "",
    managerName: "",
    salesPersonIdx: "",
    phone: "",
    fax: "",
    pw: "",
    email: "",
    file: new File([""], ""),
    postNumber: "",
    address: "",
    detailAddress: "",
  });
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeCheck, setVerifyCodeCheck] = useState(false);
  const [step, setStep] = useState(0);
  const [salesPersonList, setSalesPersonList] = useState<Array<CompanyData>>(
    []
  );
  const [salesPersonIdx, setSalesPersonIdx] = useState<CompanyData>();
  useEffect(() => {
    getSalesPersonList(
      salesPersonIdx ? { salesPersonIdx: salesPersonIdx.idx } : null
    ).then((data) => {
      setSalesPersonList(data.res);
    });
  }, [salesPersonIdx]);
  return (
    <div className={css["wrapper"]}>
      {step == 0 && (
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="업체등록" description="" />
          <LoginInputWrapper className={css.joinSection}>
            <h2>기본정보</h2>
            <BaseInputWithLabel
              label="사업자등록번호"
              placeholder="사업자등록번호"
              onChange={(e) =>
                setJoinReqData({ ...joinReqData, id: e.target.value })
              }
            />
            <IdInfoWrapper>
              10자의 사업자 등록번호를 입력해주세요. (-제외)
            </IdInfoWrapper>
            <BaseFileInputWithLabel
              label="사업자등록증"
              placeholder="사업자등록증"
              onChange={(e) => {
                setJoinReqData({ ...joinReqData, file: e.target.files[0] });
              }}
            />
            <BaseInputWithLabelWithButton
              buttonText="인증번호 발송"
              label="Email"
              placeholder="Email"
              onChange={(e) => {
                setJoinReqData({ ...joinReqData, email: e.target.value });
              }}
              onClick={() => {
                sendVerifyCode({ email: joinReqData.email }).then((data) => {
                  if (data.message) {
                    alert(data.message);
                  } else {
                    alert(data.errMessage);
                  }
                });
              }}
            />
            <BaseInputWithLabelWithTime
              label="인증번호"
              placeholder="인증번호"
              onChange={(e) => setVerifyCode(e.target.value)}
              onClick={() => {
                checkVerifyCode({
                  code: verifyCode,
                  email: joinReqData.email,
                }).then((data) => {
                  if (data.message) {
                    alert(data.message);
                    setVerifyCodeCheck(true);
                  } else {
                    alert(data.errMessage);
                  }
                });
              }}
            />
            <BaseInputWithLabel
              isPw={true}
              label="비밀번호"
              placeholder="비밀번호"
              onChange={(e) =>
                setJoinReqData({ ...joinReqData, pw: e.target.value })
              }
            />
            <BaseInputWithLabel
              isPw={true}
              label="비밀번호 확인"
              placeholder="비밀번호 확인"
              onBlur={(e) => {
                if (joinReqData.pw !== e.target.value) {
                  alert("비밀번호가 일치하지 않습니다.");
                }
              }}
            />
            <button
              onClick={() => {
                function validateNumber(value: string) {
                  const regex = /^\d{10}$/; // 숫자만 10자리
                  return regex.test(value);
                }

                if (joinReqData.file.name == "") {
                  alert("사업자등록증을 첨부해주세요.");
                  return;
                }
                if (joinReqData.id == "" || joinReqData.pw == "") {
                  alert("사업자등록번호와 비밀번호는 필수항목입니다.");
                  return;
                }
                if (verifyCodeCheck == false) {
                  alert("이메일 인증을 완료해주세요.");
                  return;
                }
                if (!validateNumber(joinReqData.id)) {
                  alert("사업자등록번호는 숫자 10자리로 입력해주세요.");
                  return;
                }
                setStep(1);
              }}
              className={css.button + " " + css.fullWidth}
            >
              다음단계로
            </button>
          </LoginInputWrapper>
        </div>
      )}
      {step == 1 && (
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="업체등록" description="" />

          <LoginInputWrapper className={css.joinSection}>
            <h2>업체정보</h2>
            <BaseInputWithLabel
              onChange={(e) => {
                setJoinReqData({ ...joinReqData, companyName: e.target.value });
              }}
              label="회사명"
              placeholder="회사명"
            />
            <BaseInputWithLabel
              onChange={(e) => {
                setJoinReqData({ ...joinReqData, managerName: e.target.value });
              }}
              label="담당자명"
              placeholder="담당자명"
            />
            <BaseInputWithLabelWithButton
              label="우편번호"
              placeholder="우편번호"
              buttonText="우편번호 검색"
              value={joinReqData.postNumber}
              onClick={() => {
                new daum.Postcode({
                  oncomplete: function (data: AddressData) {
                    setJoinReqData({
                      ...joinReqData,
                      postNumber: data.zonecode,
                      address: data.roadAddress,
                    });
                    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
                    // 예제를 참고하여 다양한 활용법을 확인해 보세요.
                  },
                }).open();
                alert(
                  "모바일 환경에서 우편번호 검색창이 열리지 않을 경우 브라우저 팝업창을 허용해주세요."
                );
              }}
            />
            <BaseInputWithLabel
              value={joinReqData.address}
              label="주소"
              placeholder="주소"
            />
            <BaseInputWithLabel
              onChange={(e) =>
                setJoinReqData({
                  ...joinReqData,
                  detailAddress: e.target.value,
                })
              }
              label="상세주소"
              placeholder="상세주소"
            />

            <BaseInputWithLabel
              onChange={(e) => {
                setJoinReqData({ ...joinReqData, phone: e.target.value });
              }}
              label="전화번호"
              placeholder="전화번호"
            />

            <BaseDataSelectWithLabel
              onChange={(e) => {
                const data = JSON.parse(e.target.value) as CompanyData;
                setJoinReqData({
                  ...joinReqData,
                  salesPersonIdx: data.idx.toString(),
                });
                setSalesPersonIdx(data);
              }}
              label="관리자 검색"
              list={salesPersonList}
            />

            <button
              onClick={() => {
                if (
                  joinReqData.companyName == "" ||
                  joinReqData.managerName == "" ||
                  joinReqData.phone == ""
                ) {
                  alert("회사명, 담당자명, 전화번호는 필수항목입니다.");
                  return;
                }
                if (joinReqData.postNumber == "" || joinReqData.address == "") {
                  alert("주소는 필수항목입니다.");
                  return;
                }
                regUser({
                  ...joinReqData,
                  salesPersonIdx:
                    joinReqData.salesPersonIdx == ""
                      ? salesPersonList[0].idx.toString()
                      : joinReqData.salesPersonIdx,
                }).then((data) => {
                  if (data.message) {
                    alert(data.message);
                    navigate("/login");
                  } else {
                    alert(data.errMessage);
                  }
                });
              }}
              className={css.button + " " + css.fullWidth}
            >
              등록완료
            </button>
          </LoginInputWrapper>
        </div>
      )}
    </div>
  );
};
const JoinInfoWrapper = styled.div`
  display: flex;
  padding-top: 28px;
  justify-content: center;
  align-items: center;
  gap: var(--3, 12px);
  flex-direction: column;
  width: 100%;
`;
const JoinInfoText = styled.span`
  color: #303033;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
`;
const JoinLinkTextWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--3, 0px);
  text-decoration: none;
`;
const JoinLinkText = styled.span`
  color: #5d1d96;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const JoinLinkTextIcon = styled.div`
  filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(5705%)
    hue-rotate(270deg) brightness(96%) contrast(93%);
  width: 18px;
  height: 18px;
`;

const LoginButton = styled.button`
  border-radius: 36px;
  border: 1px solid #fdfeff;
  background: #017534;
  display: flex;
  padding: var(--2, 12px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  width: 100%;
`;
const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const LoginInfoFindButtonListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--6, 28px);
  width: 100%;
`;
const LoginInfoFindButton = styled.a`
  border: none;
  background: none;
  text-decoration: none;
  padding: 6px 0;
`;
const LoginInfoFindButtonText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
`;
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 33%;
`;
