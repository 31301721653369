import React from "react";
import { getData } from "./axios/axiosUtil";
import { getJwt } from "./sessionStorage/getAsync";
import { baseUrl } from "./setting/setting";
var timezoneOffset = new Date().getTimezoneOffset() * 60000;

const onClickAddRemoveCss = (id: string, css: string) => {
  const elem = window.document.getElementById(id);
  if (elem)
    if (elem.classList.contains(css)) {
      elem.classList.remove(css);
    } else {
      elem.classList.add(css);
    }
};
const handleCopyClipBoard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    alert("copyed!");
  } catch (e) {
    alert("fail");
  }
};
export const textSplitAndReturnComponent = (text: string) => {
  const dataMap = text.split("\n").map((data, index) => {
    return (
      <React.Fragment
        key={index}
        children={
          <>
            {data}
            <br />
            <br />
          </>
        }
      />
    );
  });
  return dataMap;
};

const getImgSrc = (json: string, jwt: string) => {
  const src = baseUrl + "/image?path=" + json;
  return getData({
    path: src,
    params: null,
    isImg: true,
    header: { Authorization: jwt },
  });
};

export const getPostImg = async (picture: string) => {
  const jwt = await getJwt();
  const img = await getImgSrc(picture, jwt ? jwt : "");
  const OrgUri = "data:image/jpeg;base64," + img;
  return OrgUri;
};
export const getTodayDateString = () => {
  return new Date(Date.now()).toISOString().split("T")[0];
};
export const getNextDay = () => {
  const today = new Date(); // 현재 날짜와 시간
  const tomorrow = new Date(today); // 현재 날짜를 복사
  tomorrow.setDate(today.getDate() + 1); // 날짜에 1일 더하기
  return tomorrow.toISOString().split("T")[0]; // ISO 형식에서 날짜 부분만 반환
};
export const getDatePrevMonth = (allMonth?: boolean) => {
  const today = new Date();
  if (allMonth) {
    today.setDate(
      today.getDate() - (Number(today.getMonth()) == 2 ? 28 : 30) * 12
    );
  } else {
    today.setDate(today.getDate() - (Number(today.getMonth()) == 2 ? 28 : 30));
  }
  return new Date(today.getTime()).toISOString().split("T")[0];
};
export const getDateStringByDate = (date: Date) => {
  try {
    return new Date(date.getTime()).toISOString().split("T")[0];
  } catch (e) {
    return "N/A";
  }
};
export function getRightText(str: string) {
  while (true) {
    if (str.indexOf("<!--") != -1) {
      var start = str.indexOf("<!--");
      var end = str.indexOf("-->");
      str = str.substring(0, start) + str.substring(end + 3, str.length);
    } else {
      break;
    }
  }
  return str;
}
export { onClickAddRemoveCss, handleCopyClipBoard };
