import { useState } from "react";
import css from "../../css/component/dashboard/_index.module.scss";
import { useNavigate } from "react-router";
import { insertProduct } from "../../utils/request/user/auth/product";

function ProductAdd() {
  const navigate = useNavigate();
  const [productInfo, setProductInfo] = useState<{
    infoFile: File;
    infoFile2: File;
    productName: string;
  }>({
    infoFile: new File([""], ""),
    infoFile2: new File([""], ""),
    productName: "",
  });
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>제품등록</h1>
      </header>
      <section className={css["contract-section"]}>
        <div className={css.cardWrapper}>
          <div
            className={
              css["contract-card"] + " " + css.detail + " " + css.company
            }
          >
            <div className={css.stateWrapper}>
              <h2>제품접수</h2>
            </div>
            <div className={css.alertWrapper + " " + css.companyInfo}>
              <div className={css.alertTextWrapper}>
                <span>제품명</span>
                <div className={css.changeButtonInputWrapper}>
                  <input
                    onChange={(e) => {
                      setProductInfo({
                        ...productInfo,
                        productName: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className={css.alertTextWrapper}>
                <span>성분분석표</span>
                <div className={css.changeButtonInputWrapper}>
                  <input
                    onChange={(e) => {
                      if (e.target.files)
                        if (e.target.files.length > 0) {
                          setProductInfo({
                            ...productInfo,
                            infoFile2: e.target.files
                              ? e.target.files[0]
                              : new File([""], ""),
                          });
                        }
                    }}
                    type="file"
                    placeholder="JPG, PNG, PDF 파일만 가능"
                  />
                </div>
              </div>
              <div className={css.alertTextWrapper}>
                <button
                  onClick={() => {
                    if (
                      productInfo.infoFile2.name == "" ||
                      productInfo.productName == ""
                    ) {
                      alert("제품명과 성분분석표는 필수입니다.");
                      return;
                    }
                    insertProduct(productInfo).then((res) => {
                      if (res.state == "200") {
                        alert(
                          "제품등록이 완료되었습니다. 등록까지 최대 3일 소요됩니다."
                        );
                        navigate("/dashboard/product");
                      } else {
                        alert(
                          "제품등록에 실패했습니다. 관리자에게 문의해주세요."
                        );
                      }
                    });
                  }}
                  className={css.button}
                >
                  저장{" "}
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>
    </div>
  );
}

export default ProductAdd;
