import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "./component/dateSelector";
import {
  getContactDashboardList,
  insertContactDashboard,
} from "../../utils/request/user/auth/contact";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  textSplitAndReturnComponent,
} from "../../utils/utils";
import { getTotalPage } from "../../pages/community/notice";
interface ContactDashboard {
  id: number; // Contact Dashboard 고유 ID
  userIdx: number; // 사용자 고유 ID
  insertDate: string; // 등록 날짜 (ISO 8601 형식)
  title: string; // 제목
  content: string; // 내용
  contactType: number; // 연락 유형 (0: 일반, 1: 기타 등)
  answerTitle: string | null; // 답변 제목 (없을 경우 null)
  answerContent: string | null; // 답변 내용 (없을 경우 null)
  state: number; // 상태 (0: 처리 중, 1: 완료 등)
}
function DashboardContact() {
  const [popupState, setPopupState] = React.useState({
    state: false,
    post: {
      id: 0,
      userIdx: 0,
      insertDate: "",
      title: "",
      content: "",
      contactType: 0,
      answerTitle: "",
      answerContent: "",
      state: 0,
    } as ContactDashboard,
  });
  const [popupPostState, setPopupPostState] = React.useState(false);
  const [contactList, setContactList] = React.useState<Array<ContactDashboard>>(
    []
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const count = 10;
  const [searchData, setSearchData] = React.useState({
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    start: 0,
    count: count,
  });
  useEffect(() => {
    getContactDashboardList(searchData).then((res) => {
      setContactList(res.res);
      setTotalPages(getTotalPage(res, count));
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>1:1문의</h1>
        <button
          onClick={() => setPopupPostState(true)}
          className={css["button"] + " " + css.shadow}
        >
          <span className={css.dashboardPlusIcon + " " + css.white} />
          1:1문의등록
        </button>
      </header>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>문의날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
              }}
              setData={(data: any) => {
                const newSearchData = { ...searchData };
                setSearchData({ ...newSearchData, ...data });
              }}
            />{" "}
          </div>
          <button
            onClick={() => {
              getContactDashboardList(searchData).then((res) => {
                setContactList(res.res);
                setTotalPages(getTotalPage(res, count));
              });
            }}
            className={css["button"]}
          >
            <span className={css.dashboardSearchIcon + " " + css.white} />
            검색
          </button>

          {/*          <div className={css["status-filters"]}>
            <span>문의유형</span>
            <div className={css.filterSelectWrapper}>
              <span className={css.active}>할랄</span>
              <span>BPOM</span>
              <span>인허가</span>
              <span>촬영</span>
              <span>언어변환</span>
              <span>디자인</span>
            </div>
          </div>

          <div className={css["status-filters"]}>
            <span>답변상태</span>
            <div className={css.filterSelectWrapper}>
              <span className={css.active}>전체</span>
              <span>답변대기</span>
              <span>답변완료</span>
            </div>
          </div>*/}
        </div>
        <div className={css.filters}>
          {/***<div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
             
              <select className={css.input}>
                <option>문의유형</option>
                <option>할랄</option>
                <option>BPOM</option>
                <option>인허가</option>
                <option>촬영</option>
                <option>언어변환</option>
                <option>디자인</option>
              </select>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="제목으로 검색"
              ></input> 
              {/**
              <button className={css["button"] + " " + css.strock}>
                <span className={css.dashboardResetIcon} />
                필터 초기화
              </button>
            </div>
          </div>*/}
        </div>
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              {/** th 필터 순서 바꾸기 기능 넣기  */}
              <th style={{ width: 100 }}>
                <div className={css.sortWrapper}>
                  문의일자
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 100 }}>문의유형</th>
              <th>제목</th>
              <th style={{ width: 100 }}>답변상태</th>
            </tr>
          </thead>
          <tbody>
            {contactList.map((data, index) => (
              <RowItem
                idx={(searchData.start / 10) * 10 + index + 1}
                data={data}
                setPopupState={(data) =>
                  setPopupState({ state: true, post: data })
                }
                key={index}
              />
            ))}
            {contactList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={totalPages}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getContactDashboardList({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setContactList(res.res);
              setTotalPages(getTotalPage(res, count));
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <ContactPostPopup
        post={popupState.post}
        state={popupState.state}
        cancelAction={() => {
          setPopupState({
            state: false,
            post: {
              id: 0,
              userIdx: 0,
              insertDate: "",
              title: "",
              content: "",
              contactType: 0,
              answerTitle: "",
              answerContent: "",
              state: 0,
            } as ContactDashboard,
          });
        }}
      />
      <NewContactAddPopup
        state={popupPostState}
        cancelAction={() => {
          setPopupPostState(false);
          getContactDashboardList({ ...searchData, start: 0 }).then((res) => {
            setContactList(res.res);
            setTotalPages(getTotalPage(res, count));
          });
        }}
      />
    </div>
  );
}
const getContactTypeText = (contactType: number): string => {
  const contactTypeMap: { [key: number]: string } = {
    0: "할랄",
    1: "BPOM",
    2: "인허가",
    3: "촬영",
    4: "언어변환",
    5: "디자인",
    6: "기타",
  };

  return contactTypeMap[contactType] ?? "알 수 없음"; // 기본값 처리
};
const RowItem = ({
  data,
  setPopupState,
  idx,
}: {
  idx: number;
  data: ContactDashboard;
  setPopupState: (data: ContactDashboard) => void;
}) => {
  return (
    <tr>
      <td className={css.center}>{data.id}</td>
      <td className={css.center}>
        {getDateStringByDate(new Date(data.insertDate))}
      </td>
      <td className={css.center}>{getContactTypeText(data.contactType)}</td>
      <td>
        <span
          onClick={() => setPopupState(data)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {data.title}
        </span>
      </td>
      <td className={css.center}>
        {data.state === 2 ? (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            완료
          </span>
        ) : (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            처리중
          </span>
        )}
      </td>
    </tr>
  );
};
export const ContactPostPopup = ({
  state,
  cancelAction,
  post,
}: {
  state: boolean;
  cancelAction: any;
  post: ContactDashboard;
}) => {
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>문의 상세보기</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css.contactAdminContent}>
            <section className={css["notice-section"] + " " + css.white}>
              <h2>
                <span className={css.bedge + " " + css.white + " " + css.small}>
                  {getContactTypeText(post.contactType)} 문의
                </span>
                {post.title}{" "}
              </h2>
              <p>{textSplitAndReturnComponent(post.content)}</p>
            </section>

            <section className={css["notice-section"]}>
              <h2>
                <span className={css.bedge + " " + css.green + " " + css.small}>
                  관리자 답변
                </span>
                {post.answerTitle != null && post.answerTitle != ""
                  ? post.answerTitle
                  : "답변 대기중"}
              </h2>
              <p>
                {post.answerContent != "" && post.answerContent != null
                  ? post.answerContent
                  : "답변 대기중입니다."}
              </p>
            </section>
          </div>

          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
export const NewContactAddPopup = ({
  state,
  cancelAction,
}: {
  state: boolean;
  cancelAction: any;
}) => {
  const [contactData, setContactData] = React.useState({
    title: "",
    content: "",
    contactType: 0,
  });

  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>1:1문의하기</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>1:1문의 안내</h2>
            <ul>
              <li>
                제품 접수 시 할랄 인증과 BPOM 인증 심사를 동시에 진행합니다.
              </li>
              <li>
                제품 접수 후 서류 등록을 하지 않으면 할랄 인증 및 BPOM 인증이
                진행되지 않습니다.
              </li>
            </ul>
          </section>
          <div className={css["form-group"]}>
            <label>문의유형</label>
            <select
              onChange={(e) =>
                setContactData({
                  ...contactData,
                  contactType: Number(e.target.value),
                })
              }
              className={css.input}
            >
              <option value={0}>할랄</option>
              <option value={1}>BPOM</option>
              <option value={2}>인허가</option>
              <option value={3}>촬영</option>
              <option value={4}>언어변환</option>
              <option value={5}>디자인</option>
              <option value={6}>기타</option>
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>제목</label>
            <input
              onChange={(e) =>
                setContactData({ ...contactData, title: e.target.value })
              }
              type="text"
              placeholder=""
            />
          </div>

          <div className={css["form-group"]}>
            <label>내용</label>
            <textarea
              onChange={(e) =>
                setContactData({ ...contactData, content: e.target.value })
              }
              placeholder=""
            />
          </div>

          <button
            onClick={() => {
              if (contactData.title == "" || contactData.content == "") {
                alert("제목과 내용을 입력해주세요.");
                return;
              }
              insertContactDashboard(contactData).then((res) => {
                if (res.state === "200") {
                  alert(
                    "문의 등록이 완료되었습니다. 답변까지 최대 일주일이 소요됩니다."
                  );
                  cancelAction();
                } else {
                  alert(
                    "문의 등록에 실패하였습니다. 잠시 후 다시 시도해주세요."
                  );
                }
              });
            }}
            className={css["button"]}
          >
            등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export default DashboardContact;
