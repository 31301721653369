import css from "../../css/component/main/_index.module.scss";
import concert8 from "../../assets/images/concert/concert8.png";
import { SectionTitle } from "../main/component/sectionTitle";
export const Concert4 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>NU한국위원회 컨설팅 차별점</h1>
              <h3>Nahdlatul Ulama (NU)의 차별화된 컨설팅 기법을 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          {" "}
          <SectionTitle title="NU한국위원회 컨설팅 차별점" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>NU와 인도네시아 협회를 통해 인증과정 간소화</h1>{" "}
            <p className={css.infoBoxWrapper}>
              인도네시아에 있는 감사관과 감시관을 인도네시아에 요청 후 한국에
              배정하는 과정을 NU한국위원회를 통해 진행합니다. <br />{" "}
              NU한국위원회 내부에 있는 감사관과 감시관을 통해 바로 공장 감사를
              진행하고 빠른 소통을 통해 즉시 피드백 전달 보다 빠른 할랄 인증
              서비스를 제공합니다{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            {/*<div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert8}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>*/}
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>내부 감사관 및 감시관 배정 </h3>
              <p>
                기존 방식에서는 인도네시아에서 감사관과 감시관을 요청하고
                한국으로 배정하는 절차가 필요하지만, NU한국위원회는 내부에 이미
                배치된 감사관과 감시관을 바로 활용합니다. 이를 통해 배정
                과정에서의 시간을 절약할 수 있습니다.{" "}
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>공장 감사 진행 </h3>
              <p>
                기존 방식에서는 인도네시아에서 감사관과 감시관을 요청하고
                한국으로 배정하는 절차가 필요하지만, NU한국위원회는 내부에 이미
                배치된 감사관과 감시관을 바로 활용합니다. 이를 통해 배정
                과정에서의 시간을 절약할 수 있습니다.{" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>빠른 소통 및 피드백</h3>
              <p>
                감사 결과와 발견된 사항에 대해 즉시 공장 측에 피드백을
                제공합니다. 감사 중에 발견된 문제점이나 개선 사항에 대해
                실시간으로 소통하여 즉각적인 조치가 이루어질 수 있도록
                지원합니다.{" "}
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>빠른 인증</h3>
              <p>
                모든 감사 절차가 완료되고 모든 기준을 충족한 경우, 신속하게 할랄
                인증을 발급합니다. NU한국위원회의 내부 감사관과 감시관을 통해
                진행됨으로써 전체 과정이 효율적이고 빠르게 완료됩니다. 이와 같이
                NU한국위원회를 통한 할랄 인증 서비스는 기존 방식에 비해 시간과
                비용을 절약하고, 보다 신속한 피드백과 인증 발급을 가능하게
                합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
