import css from "../../css/component/main/_index.module.scss";
import sliderImg2 from "../../assets/images/slider7.png";
import sliderImg1 from "../../assets/images/slider6.png";
import { SectionTitle } from "../main/component/sectionTitle";
export const NuPage = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider5 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>NU한국위원회</h1>
              <h3>
                한국의 우수한 제품을 전 세계로, NU한국위원회의 비전을
                살펴보세요.
              </h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="NU한국위원회 소개" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>NU한국위원회</h1>
            <p className={css.infoBoxWrapper}>
              NU한국위원회는 한국의 우수한 제품을 전 세계로, 특히{" "}
              <b className={css.green}>인도네시아</b>와 같은 주요 시장에
              수출하는 데 앞장서고 있습니다.
              <br /> 우리는 <b className={css.green}>할랄 인증 절차를 간소화</b>
              하고, 고객이 직면하는 여러가지 <b>어려움을 해결하는 데 최선</b>을
              다하고 있습니다
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.imgRowWrapper}>
              <img src={sliderImg1} />
              <img src={sliderImg2} />
            </div>
            <p className={css.contentWrapper}>
              2024년 10월 18일부터 인도네시아에서는 식품, 화장품, 건강식품
              등등에 대한 할랄 인증이 의무화되었으며, 수입 제품에 대해서는 최대
              2년의 유예 기간이 부여되었습니다. 이에 따라 2026년 10월 17일까지는
              할랄 인증 없이 인도네시아 시장에 진출할 수 있지만, 그 이후로는
              반드시 할랄 인증이 필요합니다. 이러한 변화에 맞춰 저희
              NU한국위원회는 지속 가능한 신 할랄 인증을 통해 특히, 인도네시아를
              포함한 전 세계 20억 무슬림을 대상으로 무역 판로를 확대하고, 현지
              홍보와 마케팅 시스템을 구축한 회사입니다 또한, 이 과정에서 할랄
              인증, 유통, 현지화 등의 종합 서비스를 제공하여 국내 기업들이
              안정적으로 할랄 시장에 쉽게 접근할 수 있는 환경을 마련하고, 한국의
              첨단 기술과 품질을 가진 제품들이 무슬림 시장에서 지속 가능한
              경쟁력을 확보할 수 있도록 돕고 있습니다. 이를 통해 NU한국위원회는
              전 세계 20억 무슬림에게 신뢰할 수 있는 제품과 서비스를 제공하는
              것을 목표로 하고 있습니다.
            </p>
          </div>
          <div className={css.sectionFirstContentWrapper}>
            <h1>VISION</h1>
            <p className={css.infoBoxWrapper}>
              NU한국위원회는 한국과 이슬람 국가 간의 경제 및 문화적 교류를 통해
              <b>상호 이해와 협력을 증진</b>시키고자 합니다. <br />
              이를 위해 우리는 <b className={css.green}>세 가지 핵심 목표</b>를
              추구하고 있습니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.checkIcon} /> 간소화
              </h3>
              <p>
                우리는 한국 기업이 인도네시아를 포함한 이슬람 국가로의 진출을
                더욱 용이하게 할 수 있도록, 복잡한 각종 인증 절차를 간소화하고
                지원하는 데 최선을 다하고 있습니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.peopleIcon} /> 경제 협력
              </h3>
              <p>
                우리는 한국의 뛰어난 제품과 기술을 인도네시아 시장에 소개하고,
                양국 간의 경제 협력을 강화하는 데 중점을 두고 있습니다. 이를
                통해 양국 기업이 상호 이익을 얻을 수 있는 기회를 창출하고자
                합니다.{" "}
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>
                <span className={css.smailIcon} /> 문화 교류
              </h3>
              <p>
                경제적 협력뿐만 아니라, 국가 간의 문화 교류를 통해 서로에 대한
                이해와 존중을 증진시키고자 합니다.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
