const getJwt = () => {
  const loginJwt = window.sessionStorage.getItem("Authorization");
  return loginJwt && JSON.parse(loginJwt);
};
const logout = () => {
  window.sessionStorage.removeItem("Authorization");
  window.sessionStorage.removeItem("AuthorizationRefresh");
};
const setStorage = (setList: Array<{ key: string; value: string }>) => {
  setList.map((data) => {
    window.sessionStorage.setItem(data.key, data.value);
  });
  return "success";
};

const getRefreshJwt = () => {
  const loginJwt = window.localStorage.getItem("AuthorizationRefresh");
  return loginJwt;
};
const getStorage = (keys: Array<string>) => {
  const resObj = {} as { [key: string]: any };
  for (let i = 0; i < keys.length; i++) {
    const res = window.sessionStorage.getItem(keys[i]);
    try {
      resObj[keys[i]] = JSON.parse(res ? res : "null");
    } catch (e) {
      resObj[keys[i]] = res;
    }
  }
  return resObj;
};
export { getJwt, setStorage, getStorage, getRefreshJwt, logout };
