import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "./component/dateSelector";
import { CompanyInfo } from "../wrapper/header/header";
import {
  getUserInfo,
  updateUserInfo,
} from "../../utils/request/user/auth/user";
import { getFile } from "../../utils/request/user/auth/file";
import { IdInfoWrapper } from "../../pages/login/login";
import { AddressData } from "../../pages/login/join";
declare const daum: any;

function CompanyInfoPage() {
  const [edit, setEdit] = useState(false);
  const [userInfo, setUserInfo] = useState<CompanyInfo>({
    idx: 0,
    businessId: "",
    companyName: "",
    managerName: "",
    phoneNumber: "",
    email: "",
    faxNumber: "",
    businessFileUrl: null,
    postNumber: "",
    address: "",
    addressDetail: "",
    adminName: null,
    salesPersonName: "",
  });
  const [updateUserInfoData, setUpdateUserInfoData] = useState<{
    companyName: string | null;
    managerName: string | null;
    phone: string | null;
    fax: string | null;
    pw: string | null;
    orgPw: string | null;
    file: File | null;
    postNumber: string | null;
    address: string | null;
    detailAddress: string | null;
  }>({
    companyName: null,
    managerName: null,
    phone: null,
    fax: null,
    pw: null,
    orgPw: null,
    file: null,
    postNumber: null,
    address: null,
    detailAddress: null,
  });
  useEffect(() => {
    getUserInfo().then((res) => {
      setUserInfo(res.res);
      setUpdateUserInfoData({
        companyName: res.res.companyName,
        managerName: res.res.managerName,
        phone: res.res.phoneNumber,
        fax: res.res.faxNumber,
        pw: null,
        orgPw: null,
        file: null,
        postNumber: res.res.postNumber,
        address: res.res.address,
        detailAddress: res.res.addressDetail,
      });
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>
          <span className={css.leftArrowIcon} />
          회사정보
        </h1>
      </header>
      <section className={css["contract-section"]}>
        {!edit ? (
          <div className={css.cardWrapper}>
            <div
              className={
                css["contract-card"] + " " + css.detail + " " + css.company
              }
            >
              <div className={css.stateWrapper}>
                <h2>업체 기본 정보</h2>
              </div>
              <div className={css.alertWrapper + " " + css.companyInfo}>
                <div className={css.alertTextWrapper}>
                  <span>회사명</span>
                  <span className={css.count}>{userInfo.companyName}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>주소</span>
                  <span className={css.count}>
                    [{userInfo.postNumber}] {userInfo.address}{" "}
                    {userInfo.addressDetail}
                  </span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>전화번호</span>
                  <span className={css.count}>{userInfo.phoneNumber}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>팩스</span>
                  <span className={css.count}>{userInfo.faxNumber || "-"}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>이메일</span>
                  <span className={css.count}>{userInfo.email}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>담당자</span>
                  <span className={css.count}>{userInfo.managerName}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>사업자등록번호</span>
                  <span className={css.count}>{userInfo.businessId}</span>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>사업자등록증</span>
                  <div className={css.changeButtonInputWrapper}>
                    <span className={css.count}>
                      {userInfo.businessFileUrl &&
                        userInfo.businessFileUrl.substring(
                          userInfo.businessFileUrl.lastIndexOf("/") + 1
                        )}
                    </span>
                    <button
                      onClick={() => {
                        if (userInfo.businessFileUrl)
                          getFile({ path: userInfo.businessFileUrl });
                      }}
                      className={css.button}
                    >
                      다운로드
                    </button>
                  </div>{" "}
                </div>
                <div className={css.alertTextWrapper}>
                  <span>영업자</span>
                  <span className={css.count}>{userInfo.salesPersonName}</span>
                </div>{" "}
                <div className={css.alertTextWrapper}>
                  <button onClick={() => setEdit(true)} className={css.button}>
                    수정하기{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={css.cardWrapper}>
            <div
              className={
                css["contract-card"] + " " + css.detail + " " + css.company
              }
            >
              <div className={css.stateWrapper}>
                <h2>정보 수정</h2>
              </div>
              <div className={css.alertWrapper + " " + css.companyInfo}>
                <div className={css.alertTextWrapper}>
                  <span>회사명</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          companyName: e.target.value,
                        });
                      }}
                      defaultValue={updateUserInfoData.companyName || ""}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>우편번호</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      readOnly
                      value={
                        updateUserInfoData.postNumber
                          ? updateUserInfoData.postNumber
                          : userInfo.postNumber
                      }
                    />
                    <button
                      onClick={() => {
                        new daum.Postcode({
                          oncomplete: function (data: AddressData) {
                            setUpdateUserInfoData({
                              ...updateUserInfoData,
                              postNumber: data.zonecode,
                              address: data.roadAddress,
                            });
                          },
                        }).open();
                        alert(
                          "모바일 환경에서 우편번호 검색창이 열리지 않을 경우 브라우저 팝업창을 허용해주세요."
                        );
                      }}
                      className={css.button}
                    >
                      우편번호 검색{" "}
                    </button>
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>주소</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      readOnly
                      value={
                        updateUserInfoData.address
                          ? updateUserInfoData.address
                          : userInfo.address
                      }
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>상세주소</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          detailAddress: e.target.value,
                        });
                      }}
                      defaultValue={
                        updateUserInfoData.detailAddress
                          ? updateUserInfoData.detailAddress
                          : userInfo.addressDetail
                      }
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>전화번호</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          phone: e.target.value,
                        });
                      }}
                      defaultValue={updateUserInfoData.phone || ""}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>팩스</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          fax: e.target.value,
                        });
                      }}
                      defaultValue={updateUserInfoData.fax || ""}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>담당자</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          managerName: e.target.value,
                        });
                      }}
                      defaultValue={updateUserInfoData.managerName || ""}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>사업자등록증 변경</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onChange={(e) => {
                        if (e.target.files)
                          setUpdateUserInfoData({
                            ...updateUserInfoData,
                            file: e.target.files[0],
                          });
                      }}
                      type="file"
                    />
                  </div>
                </div>{" "}
                <IdInfoWrapper>
                  사업자등록증 변경 시에만 올바른 파일을 업로드해주세요. 파일은
                  jpg, png, pdf 형식만 가능합니다.
                </IdInfoWrapper>
                <div className={css.alertTextWrapper}>
                  <span>기존 비밀번호</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onBlur={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          orgPw: e.target.value == "" ? null : e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>{" "}
                <IdInfoWrapper>
                  비밀번호 변경 시에만 기존 비밀번호를 입력해주세요.
                </IdInfoWrapper>
                <div className={css.alertTextWrapper}>
                  <span>새 비밀번호</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onBlur={(e) => {
                        setUpdateUserInfoData({
                          ...updateUserInfoData,
                          pw: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <span>새 비밀번호 확인</span>
                  <div className={css.changeButtonInputWrapper}>
                    <input
                      onBlur={(e) => {
                        if (e.target.value != updateUserInfoData.pw) {
                          alert("비밀번호가 일치하지 않습니다.");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={css.alertTextWrapper}>
                  <button
                    onClick={() => setEdit(false)}
                    className={css.button + " " + css.red}
                  >
                    취소
                  </button>
                  <button
                    onClick={() => {
                      updateUserInfo(updateUserInfoData).then((res) => {
                        if (res.state == "200") {
                          alert("수정되었습니다.");
                          setEdit(false);
                        } else {
                          alert("수정에 실패했습니다.");
                        }
                      });
                    }}
                    className={css.button}
                  >
                    저장
                  </button>
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </section>
    </div>
  );
}

export default CompanyInfoPage;
