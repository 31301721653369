import { downloadFile, getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { auth, noAuth } from "../../../setting/setting";

const url = auth + "/file?path=";
const jwt = () => getJwt();

// 파일 가져오기

export const getFile = (info: { path: string }) => {
  return downloadFile(url + info.path, {
    Authorization: jwt(),
    "Content-Type": "application/json",
  });
};
