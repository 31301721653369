import css from "../../../css/component/main/_index.module.scss";
import { SectionTitle } from "../../main/component/sectionTitle";
import halalProcess from "../../../assets/images/halalProcess.png";
import logo from "../../../assets/images/BPJPH.png";
export const HalalPage2 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>할랄 인증 절차</h1>
              <h3>할랄 인증 절차를 소개합니다.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="할랄인증 절차" description="" />
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄인증 절차</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={halalProcess}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>
                구비서류 <br />
                (할랄인증)
              </h3>
              <p>
                <b className={css.green}>
                  **모든 서류는 국문과 영문 첨부 바랍니다.
                </b>
                <span>1. Application Letter (할랄 인증 신청서)</span>
                <span>
                  2. Registration Form (할랄 인증 등록 제품 현황-포맷 사용)
                </span>
                <span>
                  {" "}
                  3. Copy of spanusiness License (제조사의 사업자 등록증 사본){" "}
                </span>
                <span>
                  4. Copy of Halal Supervisor determination letter ( 신청
                  위임장, 할랄담당자의 여권사본, 이력서)
                </span>
                <span>
                  {" "}
                  5. Name and type of product (인증 대상 제품 목록 및 할랄 인증
                  품목 유형){" "}
                </span>
                <span>
                  6. List of products and materials (인증 대상 재료 목록 및
                  현황)
                </span>
                <span>
                  {" "}
                  7. Products manufacturing process (인증 대상의 제조 공정도){" "}
                </span>
                <span>
                  8. Halal Assurance System Document (할랄안전관리시스템의
                  매뉴얼)
                </span>
                <span> 9. 연장시 이전 인증서 사본</span>
                <span> 10. 기 인도네시아 등록 제품 경우 허가서 사본</span>
              </p>
            </div>
            <div
              className={
                css.contentInfoWrapper + " " + css.year + " " + css.green
              }
            >
              <h3>주요 유의사항 </h3>
              <p>
                <span>1. 1년에 1회 할랄 관련 활동(훈련, 교육등)</span>
                <span> 2. 2년에 1회 중간 감사</span>
                <span>
                  {" "}
                  3. FATWA에서 확정서 발급 후 BPJPH 에서 최종 할랄 인증을
                  완료하고 할랄 증서를 발급합니다. 온라인 상에 등록 되고 SIHALAL
                  온라인을 통해 다운로드 받을 수 있습니다.{" "}
                </span>
                <span>
                  4. 할랄 검사기관(LPH) 소속의 할랄 감사관(Auditor)이 현장
                  실사를 나갑니다. 접수한 업체의 할랄 감독관(Penyelia)과 함께
                  서류와 실제 현장을 대조하고 확인합니다.{" "}
                </span>
                <span>
                  5. 할랄 검사기관(LPH) 에서 업체에서 수집한 샘플을 상세
                  분석합니다. 분석된 자료는 서류화 되어 BPJPH 로 전달 됩니다.
                  보통 근무 일수 기준 15일 이상 소요 됩니다.
                </span>
                <span>
                  {" "}
                  6. LPH에서 회사 규모, 제품에 따라 심사비용 및
                  할랄감사관(AUDITOR) 파견 비용등에 대한 견적을 내어 BPJPH를
                  통해 업체로 전달합니다.{" "}
                </span>
                <span>
                  7. 할랄 인증 신청 회사는 견적에 따른 심사 비용을 납부 후 할랄
                  인증 접수확인증을 발급받습니다.
                </span>
              </p>
            </div>
            <div className={css.contentInfoWrapper + " " + css.year}>
              <h3>주요 심사 내용</h3>
              <p>
                <b className={css.row}>
                  <img src={logo} />
                  BPJPH(LPH선정)
                  <br /> (LPPOM MUI, Sucofindo, Surveyor Indonesia 중 선택 가능)
                </b>
                <span>• 하람성분(돼지, 알코올 등) 포함 여부 확인 </span>
                <span>• 할랄 재료 문서의 완결성(공정도, 스펙 등) </span>
                <span>• 제조/설비 세척, 청소, 혼입 관리(교차 오염방지) </span>
                <span>• 할랄 교육과 훈련정책(계획 및 시행 등)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
