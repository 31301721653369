import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "./component/dateSelector";
import { useSearchParams } from "react-router-dom";
import {
  getBpomDocs,
  getHalalDocs,
  getProductBpomPostList,
  getProductConcertInfo,
  getProductHalalPostList,
  getProductInfo,
  insertBpomDocs,
  insertBpomPost,
  insertHalalDocs,
  insertHalalPost,
  updateBpomDocFile,
  updateHalalDocFile,
} from "../../utils/request/user/auth/product";
import { EtcConcertingCount } from "./dashboard";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
  textSplitAndReturnComponent,
} from "../../utils/utils";
import { getFile } from "../../utils/request/user/auth/file";
import { getTotalPage } from "../../pages/community/notice";
export const docList = [
  {
    type: 1,
    title: "1. 사업자등록증",
    info: "회사정보의 사업자 등록증을 수정해주세요.\n수정된 사업자등록증과 같은 사업자등록증을 업로드해주세요. \n관할 세무서 또는 국세청 홈택스에서 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 2,
    title: "2. 생산허가서 또는 영업허가서(영업신고증)",
    info: "정부24에서 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 3,
    title: "3. 공장등록증",
    info: "정부24에서 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 4,
    title: "4. 자사제품표준서",
    info: "제조업자(수출업자)에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 5,
    title: "5. 제조공정도",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 6,
    title: "6. 제품사진 및 표시사항",
    info: "제조업자(수출업자)에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
    otherInfo: (isHalal: boolean) => [
      {
        title: "기타 서류 (zip 파일 업로드 요망)",
        info: `"*Batch Coding System
*라벨디자인 (인도네시아어)"`,
      },
      {
        title: "기타 정보 (zip 파일 업로드 요망)",
        info: `"*Batch Coding System
제품 코드의 샘플을 제공하고 제품에 적용되는 생산 코드의 의미를 설명
*라벨디자인
- 제품 이름, 성분 목록, 사이즈, 양, 중량, 제품의 유효 기간, 공급자 또는 포장업자의 성명 및 주소, 제조업체 및 제조국, 인도네시아 수입자 또는 유통 업체의 이름과 주소, 사용설명서, 주의사항, 배치 번호, 신고 번호 포함"`,
      },
    ],
  },
  {
    type: 7,
    title: "7. 시험성적서",
    info: `관련 연구 기관 (예: KOTITI)에 요청하여 발급받으실 수 있습니다.`,
    otherInfo: (isHalal: boolean) => [
      {
        title: "완제품 시험성적서 목록 (zip 파일 업로드 요망)",
        info: `- 제품성분표(INCI)
- 분석증명서(COA)
- Non animal test
- Safety Assessment
- Stability test Report
- Fragrance (화장품 일 경우)
*잔류에탄올 시험성적서 (ISO17025 보유하는 분석 기관)
*돼지종 시험성적서
**분석기관에 의뢰 시 실제 생산라인에서 생산된 제품으로 의뢰하여야 한다.`,
      },
      {
        title: "완제품 분석증명서 목록 (zip 파일 업로드 요망)",
        info: `분석증명서 (COA) 
- 물리적 분석
- 화학 분석
- 미생물분석
- 중금속 함량 분석`,
      },
    ],
  },
  {
    type: 8,
    title: "8.	포장재 시험성적서, 수질검사성적서",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 9,
    title: "9.	할랄 실무자 교육 수료증",
    info: "본 위원회로 문의 부탁드립니다.",
    otherInfo: (isHalal: boolean) => [
      {
        title: "할랄 실무자 교육 수료증 정보",
        info: `인증 전 교육수료가 완료되지 않은 경우, 할랄인증서 발급 후 3개월 이내에 교육을 완료해야함
갱신인증(유효기간 3년)의 경우도 연 1회, 안내에 따라 할랄실무자교육 이수 필요함`,
      },
    ],
  },
  {
    type: 10,
    title: "10.	HACCP, ISO, GMP, FSSC등 기타 인증서 사본",
    info: "해당 인증기관 (CGMP : 식품의약품안전처)에서 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 11,
    title: "11.	경영검토 보고서 또는 회계감사보고서(작년 기준)",
    info: ``,
    otherInfo: (isHalal: boolean) => [
      {
        title: "보고서 정보",
        info: `*자산부채비율이 33% 이상인 기업, 수익에 대한 영업외 금리수입의 비율이 5% 이상인 기업 및 총자산에 대한 외상매출금의 비율이 45% 이상인 기업은 샤리아지수의 자격요건에서 제외된다.`,
      },
    ],
  },
  {
    type: 12,
    title: "12.	할랄관리기준서(HAS Manual) & 할랄방침",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.\n 갱신 시 내부할랄교육 기록물철 첨부가 필요합니다.(추가 서류 첨부 시 zip 파일 업로드 요망)",
  },
  {
    type: 13,
    title: "13.	방충방서 보고서 및 사용된 약품 MSDS 자료",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 14,
    title:
      "14.	세척관리기준서 및 생산라인(공정)에 사용된 세제, 세정제 등 세척 관련 제품들의 MSDS 자료",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.",
    fileUrl: "",
  },
  {
    type: 15,
    title: "15.	사용재료 목록 ",
    info: "제조업자에게 요청하여 발급받으실 수 있습니다.\n사용된 모든 원료를 빠짐없이 정확하게 기재해야 합니다. (1차, 2차 등 모든 원 료 및 공급처를 반드시 기재해야 함)",
  },
  {
    type: 16,
    title: "16.	원재료 관련서류 ",
    info: `동물성 유래성분 사용 시 할랄인증서 필수 (우유, 달걀 제외)
    *원/부재료, 첨가제, 가공보조제의 제조공정도, 품목제조보고서, 시험성적서, 수입신고필증, 돼지미사용시설증명서, 에탄올증명서 등 유래 및 출처가 확인 가능한 문서가 필요합니다.
    구비 서류 종류 상세 정보는 추가 정보를 확인하세요.`,
    otherInfo: (isHalal: boolean) => [
      {
        title: "사용 소재가 할랄인증서를 가지고 있는 경우",
        info: `소재의 할랄인증서 (유효기간 확인 후 접수 요망)
사용된 원료의 품목제조보고서, 제품표준서
수입신고필증 및 수입신고확인증
사용된 원료의 시험성적서, MSDS`,
      },
      {
        title: "사용 소재가 할랄인증서를 가지고 있지 않은 경우",
        info: `사용된 원료의 품목제조보고서, 제품표준서
수입신고필증 및 수입신고확인증
사용된 원료의 제조공정도
사용된 원료의 시험성적서, MSDS
농산물: 잔류농약ㆍ중금속 시험성적서
수산물: 방사능ㆍ중금속 시험성적서`,
      },
      {
        title: "화장품일 경우 중금속 기준 한도 가이드라인",
        info: `수은 : Mercury (Hg)	NMT 1ppm (1mg/kg or 1mg/L)
납 : Lead (Pb)	NMT 20ppm (20mg/kg or 20mg/L)
비소 : Aresenic(As)	NMT 5ppm (5mg/kg or 5mg/L)
카드뮴 : Cadmium(Cd)	NMT 5ppm (5mg/kg or 5mg/L)
NMT = Not more than(미만)`,
      },
      {
        title:
          "화장품일 경우 미생물 기준 한도 가이드라인 (3세 이하의 어린이 / 눈 주위 혹은 점막용 화장품)",
        info: `호기성 중온성 총균성 (박테리아, 효모 & 곰팡이)	: =< 500 cfu/g or cfu/ml	
녹농균 (P. Aeruginosa) ,황색 포도상구균 (S.Aureus), 칸디다 알비칸스 (C.Albicans) : 	Absent in 0.1g or 0.1ml test sample`,
      },

      {
        title: "화장품일 경우 미생물 기준 한도 가이드라인",
        info: `호기성 중온성 총균성 (박테리아, 효모 & 곰팡이)	: =< 1000 cfu/g or cfu/ml
녹농균 (P. Aeruginosa) ,황색 포도상구균 (S.Aureus), 칸디다 알비칸스 (C.Albicans) : 	Absent in 0.1g or 0.1ml test sample`,
      },
      {
        title: "화장품일 경우 트레이스 기준 한도 가이드라인",
        info: `1,4- Dioxane	NMT 10mg/kg or 10mg/L (10ppm)`,
      },
      {
        title: "제조공정 중 효소ㆍ미생물을 사용할 경우",
        info: `기원을 확인할 수 있는 서류
제조ㆍ추출공정도
배지ㆍ배양액에 대한 원료 리스트 및 원료 관련 서류`,
      },
    ],
  },
  {
    type: 17,
    title: "17.	기타서류",
    info: "",
  },
];
const docObject = docList.reduce((acc: any, doc) => {
  acc[doc.type] = doc;
  return acc;
}, {});
interface ProductDetails {
  id: number; // 제품 ID
  userIdx: number; // 사용자 인덱스
  insertDate: string; // 등록 날짜 (ISO 형식)
  productName: string; // 제품 이름
  halalState: number; // 할랄 상태 (true/false)
  bpomState: number; // BPOM 상태 (true/false)
  productInfoUrl: string; // 제품 정보 URL
  productIngredientAnalysisUrl: string; // 제품 성분 분석 URL
  isNewProduct: boolean; // 신규 제품 여부
  productUniqueId: string | null; // 제품 고유 ID (null 가능)
  halalCertificationDate: string | null; // 할랄 인증 날짜 (null 가능)
  bpomCertificationDate: string | null; // BPOM 인증 날짜 (null 가능)
  halalExpiryDate: string | null; // 할랄 인증 만료 날짜 (null 가능)
  bpomExpiryDate: string | null; // BPOM 인증 만료 날짜 (null 가능)
  halalCertificationNumber: string | null; // 할랄 인증 번호 (null 가능)
  bpomCertificationNumber: string | null; // BPOM 인증 번호 (null 가능)
  halalCertificateUrl: string | null; // 할랄 인증서 URL (null 가능)
  bpomCertificateUrl: string | null; // BPOM 인증서 URL (null 가능)
  salesAmount: number; // 판매 금액
}
interface StateCount {
  confirmationStatusZeroCount: number; // 확인 상태 0의 개수
  typeOneStateZeroOrOneCount: number; // 타입 1이며 상태가 0 또는 1인 개수
}
interface DocState {
  state: number; // 상태 값 (예: 0, 1, 2 등)
  count: number; // 해당 상태의 문서 개수
}
interface ProductConcertState {
  halalPostState: StateCount; // 할랄 게시물 상태
  bpomPostState: StateCount; // BPOM 게시물 상태
  bpomDocState: Array<DocState>; // BPOM 문서 상태 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
  halalDocState: Array<DocState>; // 할랄 문서 상태 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
  etcConcertingStateCount: Array<EtcConcertingCount>; // 기타 컨서팅 상태 개수 (빈 배열로 정의, 구체적 데이터 타입이 명확하지 않음)
}
interface PostItem {
  id: number; // 문서 ID
  productIdx: number; // 제품 인덱스
  title: string; // 문서 제목
  content: string; // 문서 내용
  confirmationStatus: number; // 확인 상태 (0: 미확인, 1: 확인 등)
  type: number; // 문서 타입 (예: 3)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
  insertDate: string; // 삽입 날짜 (ISO 8601 형식)
}
function ProductDetail() {
  const [totalPages, setTotalPages] = useState<number>(0);

  const [popupState, setPopupState] = useState({ docType: 0, state: false });
  const [popupPostState, setPopupPostState] = useState<{
    post: PostItem;
    state: boolean;
    type: number;
  }>({
    post: {
      id: 0,
      productIdx: 0,
      title: "",
      content: "",
      confirmationStatus: 0,
      type: 0,
      state: 0,
      insertDate: "",
    },
    state: false,
    type: 0,
  });
  const [popupQnaWriteState, setPopupQnaWriteState] = useState(false);
  const [searchParams] = useSearchParams();
  const [productInfo, setProductInfo] = useState<ProductDetails>();
  const [productConcertState, setProductConcertState] =
    useState<ProductConcertState>();
  const [halalPostList, setHalalPostList] = useState<Array<PostItem>>([]);
  const [bpomPostList, setBpomPostList] = useState<Array<PostItem>>([]);
  const [searchType, setSearchType] = useState(0);
  const count = 10;
  const [searchData, setSearchData] = useState<{
    idx: number;
    confirmationStatus: string;
    state: string;
    type: string;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
  }>({
    idx: Number(searchParams.get("idx")),
    confirmationStatus: "",
    state: "",
    type: "",
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    start: 0,
    count: count,
  });
  useEffect(() => {
    getProductConcertInfo({ productIdx: Number(searchParams.get("idx")) }).then(
      (data) => {
        setProductConcertState(data.res);
      }
    );
    getProductInfo({ productIdx: Number(searchParams.get("idx")) }).then(
      (data) => {
        setProductInfo(data.res);
      }
    );
    searchAction();
  }, []);
  const searchAction = async (searchDataForce?: {
    idx: number;
    confirmationStatus: string;
    state: string;
    type: string;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
  }) => {
    let totalPageTemp = 0;
    if (searchType == 2 || searchType == 0) {
      const bpom = await getProductBpomPostList(
        searchDataForce ? searchDataForce : searchData
      );
      setBpomPostList(bpom.res);
      totalPageTemp += getTotalPage(bpom, count);
    }
    if (searchType == 1 || searchType == 0) {
      const halal = await getProductHalalPostList(
        searchDataForce ? searchDataForce : searchData
      );
      setHalalPostList(halal.res);
      totalPageTemp += getTotalPage(halal, count);
    }
    setTotalPages(totalPageTemp);
  };
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>
          <span
            onClick={() => {
              window.history.back();
            }}
            className={css.leftArrowIcon}
          />
          제품상세보기
        </h1>
      </header>
      <section className={css["contract-section"]}>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>{productInfo?.productName}</h2>
              <span>
                {productInfo &&
                  getDateStringByDate(new Date(productInfo?.insertDate))}{" "}
                접수
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className={css["contract-section"]}>
        <div className={css.cardWrapper}>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>
                할랄
                {productInfo?.halalState == 0 ? (
                  <span className={css.bedge + " " + css.white}>대기중</span>
                ) : productInfo?.halalState == 0 ? (
                  <span className={css.bedge + " " + css.blue}>진행중</span>
                ) : (
                  <span className={css.bedge + " " + css.green}>완료</span>
                )}
              </h2>
              <span>{getTodayDateString()} UPDATE</span>
            </div>
            <div className={css.alertWrapper}>
              <div className={css.alertTextWrapper}>
                <span>확인 필요 게시글</span>
                <span className={css.count}>
                  {
                    productConcertState?.halalPostState
                      .confirmationStatusZeroCount
                  }
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.red}>
                <span>문제발견</span>
                <span className={css.count}>
                  {
                    productConcertState?.halalPostState
                      .typeOneStateZeroOrOneCount
                  }
                  건
                </span>
              </div>
            </div>
            <div className={css.stateWrapper}>
              <h2>할랄 서류</h2>
            </div>
            <div className={css.alertWrapper}>
              <div className={css.alertTextWrapper}>
                <span>등록됨</span>
                <span className={css.count}>
                  {productConcertState &&
                  !isNaN(
                    productConcertState?.halalDocState[0]?.count +
                      (productConcertState?.halalDocState[1]
                        ? productConcertState?.halalDocState[1].count
                        : 0) +
                      (productConcertState?.halalDocState[2]
                        ? productConcertState?.halalDocState[2].count
                        : 0)
                  )
                    ? productConcertState?.halalDocState[0]?.count +
                      (productConcertState?.halalDocState[1]
                        ? productConcertState?.halalDocState[1].count
                        : 0) +
                      (productConcertState?.halalDocState[2]
                        ? productConcertState?.halalDocState[2].count
                        : 0)
                    : 0}
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.red}>
                <span>문제발견</span>
                <span className={css.count}>
                  {productConcertState?.halalDocState[1]
                    ? productConcertState.halalDocState[1].count
                    : 0}
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.green}>
                <span>완료</span>
                <span className={css.count}>
                  {productConcertState?.halalDocState[2]
                    ? productConcertState.halalDocState[2].count
                    : 0}
                  건
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                setPopupState({ docType: 0, state: true });
              }}
              className={css.button + " " + css.strock}
            >
              <span className={css.dashboardUploadIcon + " " + css.green} />
              서류 업로드
            </button>
          </div>
          <div className={css["contract-card"] + " " + css.detail}>
            <div className={css.stateWrapper}>
              <h2>
                BPOM
                {productInfo?.bpomState == 0 ? (
                  <span className={css.bedge + " " + css.white}>대기중</span>
                ) : productInfo?.bpomState == 1 ? (
                  <span className={css.bedge + " " + css.blue}>진행중</span>
                ) : (
                  <span className={css.bedge + " " + css.green}>완료</span>
                )}
              </h2>
              <span>{getTodayDateString()}UPDATE</span>
            </div>
            <div className={css.alertWrapper}>
              <div className={css.alertTextWrapper}>
                <span>확인 필요 게시글</span>
                <span className={css.count}>
                  {
                    productConcertState?.bpomPostState
                      .confirmationStatusZeroCount
                  }
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.red}>
                <span>문제발견</span>
                <span className={css.count}>
                  {
                    productConcertState?.bpomPostState
                      .typeOneStateZeroOrOneCount
                  }
                  건
                </span>
              </div>
            </div>
            <div className={css.stateWrapper}>
              <h2>BPOM 서류</h2>
            </div>
            <div className={css.alertWrapper}>
              <div className={css.alertTextWrapper}>
                <span>등록됨</span>
                <span className={css.count}>
                  {productConcertState &&
                  !isNaN(
                    productConcertState?.bpomDocState[0]?.count +
                      (productConcertState?.bpomDocState[1]
                        ? productConcertState?.bpomDocState[1].count
                        : 0) +
                      (productConcertState?.bpomDocState[2]
                        ? productConcertState?.bpomDocState[2].count
                        : 0)
                  )
                    ? productConcertState?.bpomDocState[0]?.count +
                      (productConcertState?.bpomDocState[1]
                        ? productConcertState?.bpomDocState[1].count
                        : 0) +
                      (productConcertState?.bpomDocState[2]
                        ? productConcertState?.bpomDocState[2].count
                        : 0)
                    : 0}
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.red}>
                <span>문제발견</span>
                <span className={css.count}>
                  {productConcertState?.bpomDocState[1]
                    ? productConcertState?.bpomDocState[1].count
                    : 0}
                  건
                </span>
              </div>
              <div className={css.alertTextWrapper + " " + css.green}>
                <span>완료</span>
                <span className={css.count}>
                  {productConcertState?.bpomDocState[2]
                    ? productConcertState?.bpomDocState[2].count
                    : 0}
                  건
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                setPopupState({ docType: 1, state: true });
              }}
              className={css.button + " " + css.strock}
            >
              <span className={css.dashboardUploadIcon + " " + css.green} />
              서류 업로드
            </button>
          </div>
        </div>
      </section>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>등록날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchData.startDate,
                endDate: searchData.endDate,
              }}
              setData={(data: any) => {
                const newSearchData = { ...searchData };
                setSearchData({ ...newSearchData, ...data });
              }}
            />
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  setSearchType(Number(e.target.value));
                }}
                className={css.input}
              >
                <option value={0}>분야 전체</option>
                <option value={1}>할랄</option>
                <option value={2}>BPOM</option>
              </select>
              <select
                onChange={(e) => {
                  setSearchData({
                    ...searchData,
                    confirmationStatus: e.target.value,
                  });
                }}
                className={css.input}
              >
                <option value={""}>확인 전체</option>
                <option value={0}>대기</option>
                <option value={1}>확인</option>
                <option value={1}>완료</option>
              </select>

              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="내용으로 검색"
              ></input>
              <button
                onClick={() => {
                  searchAction();
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              {/**
              <button
                onClick={() => {
                  setPopupQnaWriteState(true);
                  searchAction();
                }}
                style={{ marginLeft: "auto", alignSelf: "stretch" }}
                className={css["button"] + " " + css.strock}
              >
                문의등록
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 60 }}>
                <div className={css.sortWrapper}>
                  번호
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              {/** th 필터 순서 바꾸기 기능 넣기  */}
              <th style={{ width: 100 }}>
                <div className={css.sortWrapper}>
                  작성일자
                  <span className={css.dashboardSortButton} />
                </div>
              </th>
              <th style={{ width: 60 }}>분야</th>
              <th style={{ width: 60 }}>유형</th>
              <th>제목</th>
              <th style={{ width: 100 }}>확인 상태</th>
            </tr>
          </thead>
          <tbody>
            {halalPostList.map((post, index) => (
              <RowItem
                key={index}
                idx={(searchData.start / 10) * 10 + index + 1}
                type={0}
                post={{ ...post, id: index + 1 }}
                setPopupPostState={(post) => {
                  setPopupPostState({ post: post, state: true, type: 0 });
                }}
              />
            ))}
            {bpomPostList.map((post, index) => (
              <RowItem
                key={index}
                type={1}
                idx={(searchData.start / 10) * 10 + index + 1}
                post={{ ...post, id: halalPostList.length + index }}
                setPopupPostState={(post) => {
                  setPopupPostState({ post: post, state: true, type: 1 });
                }}
              />
            ))}
            {bpomPostList?.length == 0 && halalPostList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={6}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={totalPages}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            searchAction({ ...searchData, start: (page - 1) * 10 });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <ProductDocsAddPopup
        productIdx={Number(searchParams.get("idx"))}
        docType={popupState.docType}
        state={popupState.state}
        cancelAction={() => {
          setPopupState({ docType: 0, state: false });
        }}
      />
      <ProductPostPopup
        post={popupPostState.post}
        state={popupPostState.state}
        type={popupPostState.type}
        cancelAction={() => {
          setPopupPostState({ ...popupPostState, state: false });
        }}
      />
      <NewProductQnaPopup
        productIdx={Number(searchParams.get("idx"))}
        state={popupQnaWriteState}
        cancelAction={() => {
          setPopupQnaWriteState(false);
          searchAction();
        }}
      />
    </div>
  );
}
const RowItem = ({
  type,
  post,
  setPopupPostState,
  idx,
}: {
  idx: number;
  type: number;
  post: PostItem;
  setPopupPostState: (post: PostItem) => void;
}) => {
  //post.type 유형 (0: 일반안내, 1: 문제안내, 2: 심사완료안내, 3: 사용자문의, 4: 문의답변)
  const renderedElement = () => {
    switch (post.type) {
      case 0: // 일반안내
        return (
          <span className={`${css.bedge} ${css.white} ${css.middle}`}>
            일반안내
          </span>
        );
      case 1: // 문제안내
        return (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            문제안내
          </span>
        );
      case 2: // 심사완료안내
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            심사완료안내
          </span>
        );
      case 3: // 사용자문의
        return (
          <span className={`${css.bedge} ${css.white} ${css.middle}`}>
            사용자문의
          </span>
        );
      case 4: // 문의답변
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            문의답변
          </span>
        );
    }
  };
  const renderedConfirmationStatus = () => {
    switch (post.confirmationStatus) {
      case 0: // 확인필요
        return post.type == 3 ? (
          <span className={`${css.bedge} ${css.white} ${css.middle}`}>
            관리자확인대기
          </span>
        ) : (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            확인필요
          </span>
        );
      case 1: // 확인완료
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            확인완료
          </span>
        );
      case 2: // 해결완료
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            해결완료
          </span>
        );
    }
  };
  return (
    <tr>
      <td className={css.center}>{idx}</td>
      <td className={css.center}>
        {getDateStringByDate(new Date(post.insertDate))}
      </td>
      <td className={css.center}>
        <span>{type == 0 ? "할랄" : "BPOM"}</span>
      </td>
      <td className={css.center}>{renderedElement()}</td>
      <td>
        <span
          onClick={() => {
            setPopupPostState(post);
          }}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {post.title}
        </span>
      </td>
      <td className={css.center}>{renderedConfirmationStatus()}</td>
    </tr>
  );
};
interface Document {
  id: number; // 문서 ID
  productIdx: number; // 제품 인덱스
  docType: number; // 문서 유형 (예: 1)
  fileUrl: string; // 파일 URL
  fileName: string; // 파일 이름
  alertContent: string | null; // 알림 내용 (null 가능)
  state: number; // 상태 (예: 0: 비활성, 1: 활성 등)
  insertDate: string; // 생성 날짜 (ISO 8601 형식)
  updateDate: string; // 업데이트 날짜 (ISO 8601 형식)
}
export const ProductDocsAddPopup = ({
  state,
  cancelAction,
  docType,
  productIdx,
}: {
  docType: number;
  state: boolean;
  cancelAction: any;
  productIdx: number;
}) => {
  const [searchData, setSearchData] = React.useState("");
  const [uploadedDocList, setUploadedDocList] = React.useState<Array<Document>>(
    []
  );
  const docAction = () => {
    if (docType == 0) {
      getHalalDocs({ productIdx: productIdx }).then((data) => {
        setUploadedDocList(data.res);
      });
    } else {
      getBpomDocs({ productIdx: productIdx }).then((data) => {
        setUploadedDocList(data.res);
      });
    }
  };
  useEffect(() => {
    docAction();
  }, [docType]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>{docType == 0 ? "할랄" : "BPOM"}서류등록</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"] + " " + css.row}>
            <input
              onChange={(e) => setSearchData(e.target.value)}
              type="text"
              placeholder="서류명으로 검색 .."
            />
          </div>

          <div className={css.overflowSection}>
            {[
              ...uploadedDocList.map((item) => ({ ...item, isUploaded: true })),
              ...docList.map((item) => ({ ...item, isUploaded: false })),
            ]
              .sort((a: any, b: any) => {
                const docTypeA = a.isUploaded ? a.docType : a.type;
                const docTypeB = b.isUploaded ? b.docType : b.type;
                return docTypeA - docTypeB;
              })
              .filter((item: any) => {
                if (item.isUploaded) {
                  // uploadedDocList의 필터 조건
                  return docObject[item.docType].title.includes(searchData);
                } else {
                  // docList의 필터 조건
                  const docTypes: number[] = uploadedDocList.map(
                    (doc) => doc.docType
                  );
                  return (
                    item.title.includes(searchData) &&
                    !docTypes.includes(item.type)
                  );
                }
              })
              .map((item: any, index) => {
                if (item.isUploaded) {
                  // uploadedDocList 렌더링
                  const data = {
                    ...docObject[item.docType],
                    ...item,
                  };
                  return (
                    <DocsFileItem
                      productIdx={productIdx}
                      docType={docType}
                      key={`uploaded-${index}`}
                      title={data.title}
                      otherInfo={
                        typeof data.otherInfo === "function"
                          ? data.otherInfo(docType == 0)
                          : data.otherInfo
                      }
                      info={data.info}
                      fileData={item}
                      action={docAction}
                    />
                  );
                } else {
                  // docList 렌더링
                  return (
                    <DocsItem
                      action={docAction}
                      docType={docType}
                      type={item.type}
                      key={`doc-${index}`}
                      title={item.title}
                      otherInfo={
                        typeof item.otherInfo === "function"
                          ? item.otherInfo(docType == 0)
                          : item.otherInfo
                      }
                      productIdx={productIdx}
                      info={item.info}
                    />
                  );
                }
              })}
          </div>
          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
const DocsMoreInfoItem = ({ item }: { item: any }) => {
  const [more, setMore] = useState(false);

  return (
    <section className={css["notice-section"]}>
      <h2 onClick={() => setMore(!more)}>{item.title}</h2>
      {more && (
        <ul>
          {item.info.split("\n").map((data: string, i: number) => (
            <li key={i}>{data}</li>
          ))}
        </ul>
      )}
    </section>
  );
};
const DocsItem = ({
  docType,
  title,
  info,
  otherInfo,
  type,
  productIdx,
  action,
}: {
  docType: number;
  type: number;
  title: string;
  info: string;
  productIdx: number;
  otherInfo?: Array<{ title: string; info: string }>;
  action: any;
}) => {
  const [more, setMore] = useState(false);
  const [file, setFile] = useState<File>(new File([], ""));
  const thenAction = (data: any) => {
    if (data.state == "200") {
      alert("서류가 등록되었습니다.");
      setMore(false);
      setFile(new File([], ""));
      action();
      const fileInput: any = document.getElementById(
        "doc-file-item-" + type + "-" + docType
      );
      fileInput.value = ""; // 파일 입력 초기화
    } else {
      alert(data.errMessage);
    }
  };
  return (
    <div className={css.docsCard}>
      <header onClick={() => setMore(!more)} className={css.header}>
        <div className={css.cardTitleWrapper}>
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            미등록
          </span>
          <h3 className={css.tab}>{title}</h3>
        </div>
        <span className={css.downArrowIcon}></span>
      </header>
      {more && (
        <>
          {info != "" && (
            <section className={css["notice-section"]}>
              <h2>{title} 안내</h2>
              <ul>
                {info.split("\n").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </section>
          )}
          {otherInfo && (
            <>
              <h3> 서류제출 추가 정보 </h3>
              <div className={css.moreWrapper}>
                {otherInfo?.map((item, index) => (
                  <>
                    <DocsMoreInfoItem
                      item={item}
                      key={title + "-other-info-" + index}
                    />
                  </>
                ))}
              </div>
            </>
          )}
          <div className={css["form-group"]}>
            <input
              onChange={(e) => {
                if (e.target.files) {
                  setFile(e.target.files[0]);
                }
              }}
              id={"doc-file-item-" + type + "-" + docType}
              className={css.input}
              type="file"
              placeholder="JPG, PNG, PDF, ZIP 파일만 가능"
            />
          </div>
          <section className={css["notice-section"]}>
            <h2>파일 업로드 안내</h2>
            <ul>
              <li>
                JPG, PNG, PDF, ZIP 파일만 가능합니다. 형식을 준수해주세요.
              </li>
            </ul>
          </section>

          <div className={css["button-group"]}>
            <button
              onClick={() => {
                if (!file) {
                  alert("파일이 업로드되지 않았습니다.");
                  return;
                }
                if (docType == 0) {
                  insertHalalDocs({
                    file: file,
                    docType: type,
                    productIdx: productIdx,
                  }).then(thenAction);
                } else {
                  insertBpomDocs({
                    file: file,
                    docType: type,
                    productIdx: productIdx,
                  }).then(thenAction);
                }
              }}
              className={css["button"]}
            >
              업로드 완료
            </button>
            <button
              onClick={() => alert("양식을 다운로드 합니다.")}
              className={css["button"] + " " + css.strock}
            >
              양식다운로드
            </button>
          </div>
        </>
      )}
    </div>
  );
};
const DocsFileItem = ({
  title,
  info,
  otherInfo,
  docType,
  fileData,
  productIdx,
  action,
}: {
  productIdx: number;
  docType: number;
  title: string;
  info: string;
  otherInfo?: Array<{ title: string; info: string }>;
  fileData: Document;
  action: () => void;
}) => {
  const [more, setMore] = useState(false);
  const [file, setFile] = useState<any>();
  const thenAction = (data: any) => {
    if (data.state == "200") {
      alert("서류가 수정되었습니다.");
      action();
      const fileInput: any = document.getElementById(
        "doc-file-item-" + fileData.id + "-" + docType
      );
      fileInput.value = ""; // 파일 입력 초기화
    } else {
      alert(data.errMessage);
    }
  };
  const renderState = (state: number) => {
    switch (state) {
      case 0: // 검토중
        return (
          <span className={`${css.bedge} ${css.blue} ${css.middle}`}>
            검토중
          </span>
        );
      case 1: // 문제발견
        return (
          <span className={`${css.bedge} ${css.red} ${css.middle}`}>
            문제발견
          </span>
        );
      case 2: // 등록완료
        return (
          <span className={`${css.bedge} ${css.green} ${css.middle}`}>
            등록완료
          </span>
        );
      default: // 알 수 없는 상태
        return (
          <span className={`${css.bedge} ${css.gray} ${css.middle}`}>
            알 수 없는 상태
          </span>
        );
    }
  };
  return (
    <div className={css.docsCard}>
      <header onClick={() => setMore(!more)} className={css.header}>
        <div className={css.cardTitleWrapper}>
          {renderState(fileData.state)}
          <h3 className={css.tab}>{title}</h3>
        </div>
        <span className={css.downArrowIcon}></span>
      </header>
      <h3
        onClick={() => getFile({ path: fileData.fileUrl })}
        style={{ cursor: "pointer", color: "blue", fontWeight: 500 }}
      >
        업로드된 파일 : {fileData.fileName}
      </h3>
      {fileData.alertContent && (
        <section className={css["notice-section"]}>
          <h2>서류 문제 안내</h2>
          <ul>
            {fileData.alertContent?.split("\n").map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      )}
      {more && (
        <>
          {info != "" && (
            <section className={css["notice-section"]}>
              <h2>{title} 안내</h2>
              <ul>
                {info.split("\n").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </section>
          )}
          {otherInfo && (
            <>
              <h3> 서류제출 추가 정보 </h3>
              <div className={css.moreWrapper}>
                {otherInfo?.map((item, index) => (
                  <>
                    <DocsMoreInfoItem
                      item={item}
                      key={title + "-other-info-" + index}
                    />
                  </>
                ))}
              </div>
            </>
          )}
          <section className={css["notice-section"]}>
            <h2>
              JPG, PNG, PDF, ZIP 파일만 10MB 업로드 가능합니다. 형식을
              준수해주세요.
            </h2>
          </section>

          <div className={css["form-group"]}>
            <input
              id={"doc-file-item-" + fileData.id + "-" + docType}
              onChange={(e) => {
                setFile(e.target.files);
              }}
              className={css.input}
              type="file"
              placeholder="JPG, PNG, PDF 파일만 가능"
            />
          </div>
          <section className={css["notice-section"]}>
            <h2>파일 업로드 안내</h2>
            <ul>
              <li>
                JPG, PNG, PDF, ZIP 파일만 10MB 가능합니다. 형식을 준수해주세요.
              </li>
            </ul>
          </section>

          <div className={css["button-group"]}>
            <button
              onClick={() => {
                if (!file) {
                  alert("파일이 업로드되지 않았습니다.");
                  return;
                }
                if (docType == 0) {
                  updateHalalDocFile({
                    file: file[0],
                    docIdx: fileData.id,
                    productIdx: productIdx,
                  }).then(thenAction);
                } else {
                  updateBpomDocFile({
                    file: file[0],
                    docIdx: fileData.id,
                    productIdx: productIdx,
                  }).then(thenAction);
                }
              }}
              className={css["button"]}
            >
              파일 수정 완료
            </button>
            <button
              onClick={() => alert("양식을 다운로드 합니다.")}
              className={css["button"] + " " + css.strock}
            >
              양식다운로드
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export const NewProductQnaPopup = ({
  state,
  cancelAction,
  productIdx,
}: {
  state: boolean;
  cancelAction: any;
  productIdx: number;
}) => {
  const [qnaInfo, setQnaInfo] = useState<{
    productIdx: number;
    title: string;
    content: string;
  }>({
    title: "",
    content: "",
    productIdx: productIdx,
  });
  const [qnaType, setQnaType] = useState(0);
  useEffect(() => {
    setQnaInfo({
      title: "",
      content: "",
      productIdx: productIdx,
    });
  }, [productIdx]);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>문의등록</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>문의등록 안내</h2>
            <ul>
              <li>문의등록 시 답변은 최대 3일 소요됩니다.</li>
              <li>
                긴급한 문의사항은 사이드바에 표시된 CS 담당자에게 문의해주세요.
              </li>
            </ul>
          </section>

          <div className={css["form-group"]}>
            <label>문의유형</label>
            <select
              onChange={(e) => setQnaType(Number(e.target.value))}
              className={css.input}
            >
              <option value={0}>할랄</option>
              <option value={1}>BPOM</option>
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>제목</label>
            <input
              onChange={(e) =>
                setQnaInfo({ ...qnaInfo, title: e.target.value })
              }
              type="text"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>내용</label>
            <textarea
              onChange={(e) =>
                setQnaInfo({ ...qnaInfo, content: e.target.value })
              }
              className={css.input}
            />
          </div>

          <button
            onClick={async () => {
              if (qnaInfo.title == "" || qnaInfo.content == "") {
                alert("제목과 내용을 입력해주세요.");

                return;
              }
              if (qnaType == 0) {
                await insertHalalPost(qnaInfo);
              } else {
                await insertBpomPost(qnaInfo);
              }
              alert(
                (qnaType == 0 ? "할랄" : "BPOM") + "문의등록이 완료되었습니다. "
              );
              cancelAction();
            }}
            className={css["button"]}
          >
            문의등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
export const ProductPostPopup = ({
  post,
  type,
  state,
  cancelAction,
}: {
  type: number;
  post: PostItem;
  state: boolean;
  cancelAction: any;
}) => {
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>상세보기</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>인증유형</label>
            <span>{type == 0 ? "할랄인증" : "BPOM인증"}</span>
          </div>

          <div className={css["form-group"]}>
            <label>제목</label>
            <span>{post.title}</span>
          </div>

          <div className={css["form-group"]}>
            <label>내용</label>
            <p>{textSplitAndReturnComponent(post.content)}</p>{" "}
          </div>

          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProductDetail;
