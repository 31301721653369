import { getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { auth, noAuth } from "../../../setting/setting";

const url = auth + "/contact";
const jwt = () => getJwt();
export const insertContactDashboard = (info: {
  title: string; // 제목
  content: string; // 내용
  contactType: number; // 연락 유형
}) => {
  return getPostData({
    path: `${url}/insert`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
export const getContactDashboardList = (params: {
  startDate: string; // 시작 날짜 (YYYY-MM-DD 형식)
  endDate: string; // 종료 날짜 (YYYY-MM-DD 형식)
  start: number; // 페이징 시작
  count: number; // 페이징 개수
}) => {
  return getData({
    path: `${url}/list`,
    params: params,
    header: { Authorization: jwt() },
  });
};
