import css from "../../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../../pages/main/component/pageNav";
import DateSelector from "../../../dashboardPages/main/component/dateSelector";
import { useNavigate } from "react-router";
import { getProductListByUser } from "../../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
} from "../../../utils/utils";
import {
  getSalesLevelList,
  insertSalesPerson,
  updateSalesPersonInfo,
} from "../../../utils/request/admin/sales";
import { getSalesPersonList } from "../../../utils/request/user/noauth/reg";
interface Organization {
  name: string; // 이름
  type: string; // 타입 (예: "0")
  percent: number; // 퍼센트 값
  idx: number; // 인덱스
  parent_idx: number | null; // 부모 인덱스 (null 가능)
  parentPercent: number | null; // 부모 퍼센트 (null 가능)
  level_idx: number; // 레벨 인덱스
  oldParentPercent: number | null; // 이전 부모 퍼센트 (null 가능)
  total_withdraw_amount: number; // 총 인출 금액
  sale_amount: number; // 판매 금액
  levelName: string | null; // 레벨 이름 (null 가능)
  insert_date: string; // 삽입 날짜 (ISO 8601 형식)
}
export function SalesSearch() {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<{
    concertType: "전체" | "할랄" | "BPOM";
    state: number;
    startDate: string;
    endDate: string;
    start: number;
    count: number;
    search: string;
  }>({
    startDate: getDatePrevMonth(),
    endDate: getNextDay(),
    concertType: "전체",
    state: 0,
    start: 0,
    count: 10,
    search: "",
  });
  const [newSalesPopup, setNewSalesPopup] = useState(false);
  const [salesList, setSalesList] = useState<Array<Organization>>();
  const [salesPopup, setSalesPopup] = useState<{
    state: boolean;
    data: Organization;
  }>({
    state: false,
    data: {
      idx: 0,
      name: "",
      type: "",
      percent: 0,
      level_idx: 0,
      parent_idx: null,
      parentPercent: null,
      oldParentPercent: null,
      total_withdraw_amount: 0,
      sale_amount: 0,
      levelName: null,
      insert_date: "",
    },
  });
  const [salesPersonIdx, setSalesPersonIdx] = useState<Organization>();

  useEffect(() => {
    getSalesPersonList(
      salesPersonIdx ? { salesPersonIdx: salesPersonIdx.idx } : null
    ).then((res) => {
      setSalesList(res.res);
    });
  }, [salesPersonIdx]);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>영업사원 관리</h1>
        <button
          onClick={() => {
            setNewSalesPopup(true);
          }}
          className={css["button"] + " " + css.shadow}
        >
          <span className={css.dashboardPlusIcon + " " + css.white} />새
          영업사원 등록
        </button>
      </header>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th className={css.center}>사원번호</th>
              <th className={css.center}>삽입 날짜</th>
              <th className={css.center}>이름</th>
              <th className={css.center}>레벨</th>
              <th className={css.center}>정산률</th>
              <th className={css.center}>누적 영업 금액</th>
              <th className={css.center}>조직 유형</th>
              <th className={css.center}>산하보기</th>
            </tr>
          </thead>
          <tbody>
            {salesList?.map((sales, idx) => (
              <RowItem
                setSalesPersonIdx={setSalesPersonIdx}
                setSalesPopup={(e) => setSalesPopup({ state: true, data: e })}
                sales={sales}
                key={"sales-" + idx}
              />
            ))}
            {salesList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={2}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getProductListByUser({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setSalesList(res.res);
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
      <EditSalesAddPopup
        state={salesPopup.state}
        cancelAction={() =>
          setSalesPopup({ state: false, data: salesPopup.data })
        }
        sales={salesPopup.data}
      />
      <NewSalesAddPopup
        state={newSalesPopup}
        cancelAction={() => setNewSalesPopup(false)}
      />
    </div>
  );
}
const RowItem = ({
  sales,
  setSalesPopup,
  setSalesPersonIdx,
}: {
  sales: Organization;
  setSalesPopup: (data: Organization) => void;
  setSalesPersonIdx: (data: Organization) => void;
}) => {
  return (
    <tr>
      <td className={css.center}>{sales.idx}</td>
      <td className={css.center}>
        {" "}
        {getDateStringByDate(new Date(sales.insert_date))}
      </td>
      <td>
        <a
          style={{ cursor: "pointer", color: "blue" }}
          onClick={(e) => {
            e.preventDefault();
            setSalesPopup(sales);
          }}
        >
          {sales.name}
        </a>
      </td>
      <td className={css.center}>{sales.levelName}</td>
      <td className={css.center}>{sales.percent / 100}%</td>
      <td className={css.center}>
        {sales.total_withdraw_amount !== null
          ? sales.total_withdraw_amount.toLocaleString()
          : "N/A"}
      </td>
      <td className={css.center}>
        {sales.type == "0" ? (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            본부
          </span>
        ) : sales.type == "1" ? (
          <span className={css.bedge + " " + css.blue + " " + css.middle}>
            지부
          </span>
        ) : (
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            사원
          </span>
        )}
      </td>
      <td className={css.center}>
        <button
          onClick={() => {
            setSalesPersonIdx(sales);
          }}
          className={css["button"]}
        >
          산하보기
        </button>{" "}
      </td>
    </tr>
  );
};
export const NewSalesAddPopup = ({
  state,
  cancelAction,
}: {
  state: boolean;
  cancelAction: any;
}) => {
  const [salesPersonIdx, setSalesPersonIdx] = useState<Organization>();
  const [salesList, setSalesList] = useState<Array<Organization>>();
  const [salesList2, setSalesList2] = useState<Array<Organization>>();
  useEffect(() => {
    getSalesPersonList(null).then((res) => {
      setSalesList(res.res);
    });
    if (salesPersonIdx) {
      getSalesPersonList({ salesPersonIdx: salesPersonIdx.idx }).then((res) => {
        setSalesList2(res.res);
      });
    }
  }, [salesPersonIdx]);
  const [selectedLevel, setSelectedLevel] = useState<LevelInfo>();
  const [levelList, setLevelList] = useState<Array<LevelInfo>>();
  const [user, setUser] = useState<{
    parentIdx: number | null;
    levelIdx: number;
    name: string;
    type: number;
  }>({ type: 0, name: "", parentIdx: null, levelIdx: 0 });
  useEffect(() => {
    getSalesLevelList().then((res) => {
      setLevelList(res.res);
    });
  }, []);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>새 영업사원 추가</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <section className={css["notice-section"]}>
            <h2>영업사원 추가 안내 </h2>
            <ul>
              <li>사원 타입은 본부와 지부를 꼭 선택해주세요.</li>
              <li>지부 타입은 본부를 꼭 선택해주세요.</li>
            </ul>
          </section>

          <div className={css["form-group"]}>
            <label>이름</label>
            <input
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
              }}
              type="text"
              placeholder=""
            />
          </div>
          <div className={css["form-group"]}>
            <label>등급</label>
            <select
              onChange={(e) =>
                setUser({ ...user, levelIdx: JSON.parse(e.target.value).idx })
              }
              className={css.input}
            >
              {levelList?.map((level, idx) => (
                <option key={"level-" + idx} value={JSON.stringify(level)}>
                  {level.name}
                </option>
              ))}
            </select>
          </div>
          <div className={css["form-group"]}>
            <label>타입</label>
            <select
              onChange={(e) => {
                setUser({ ...user, type: Number(e.target.value) });
              }}
              className={css.input}
            >
              <option value="0">본부</option>
              <option value="1">지부</option>
              <option value="2">사원</option>
            </select>
          </div>
          {(user.type == 1 || user.type == 2) && (
            <div className={css["form-group"]}>
              <label>본부 선택</label>
              <select
                onChange={(e) => {
                  setUser({
                    ...user,
                    parentIdx: JSON.parse(e.target.value).idx,
                  });
                  setSalesPersonIdx(JSON.parse(e.target.value));
                }}
                className={css.input}
              >
                {salesList?.map((sales, idx) => (
                  <option
                    key={"sales-new-" + idx}
                    value={JSON.stringify(sales)}
                  >
                    {sales.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {user.type == 2 && (
            <div className={css["form-group"]}>
              <label>지부 선택</label>
              <select
                onChange={(e) => {
                  setUser({
                    ...user,
                    parentIdx: JSON.parse(e.target.value).idx,
                  });
                  setSalesPersonIdx(JSON.parse(e.target.value));
                }}
                className={css.input}
              >
                {salesList2?.slice(1, -1)?.map((sales, idx) => (
                  <option
                    key={"sales-new-" + idx}
                    value={JSON.stringify(sales)}
                  >
                    {sales.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className={css["form-group"]}>
            <label>선택된 부모 영업사원</label>
            <span>{salesPersonIdx?.name}</span>
          </div>
          <button
            onClick={() => {
              insertSalesPerson({ ...user }).then((res) => {
                if (res.message) {
                  alert("영업사원이 등록되었습니다.");
                } else {
                  alert(
                    "영업사원 등록에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                  );
                }
              });
              cancelAction();
            }}
            className={css["button"]}
          >
            제품등록완료
          </button>
        </div>
      </div>
    </div>
  );
};
interface LevelInfo {
  name: string; // 레벨 이름
  percent: number; // 퍼센트 값
  idx: number; // 인덱스
  parentPercent: number; // 부모 퍼센트
  oldParentPercent: number; // 이전 부모 퍼센트
}
export const EditSalesAddPopup = ({
  state,
  cancelAction,
  sales,
}: {
  state: boolean;
  cancelAction: any;
  sales: Organization;
}) => {
  const [selectedLevel, setSelectedLevel] = useState<LevelInfo>();
  const [levelList, setLevelList] = useState<Array<LevelInfo>>();
  useEffect(() => {
    getSalesLevelList().then((res) => {
      setLevelList(res.res);
    });
  }, []);
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>영업사원 정보수정</h1>
          <div
            onClick={(e) => {
              cancelAction();
            }}
            className={css["closeIcon"]}
          />
        </div>
        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            <label>사원 이름</label>
            <span>{sales.name}</span>
          </div>

          <div className={css["form-group"]}>
            <label>타입</label>
            {sales.type == "0" ? (
              <span className={css.bedge + " " + css.green + " " + css.middle}>
                본부
              </span>
            ) : sales.type == "1" ? (
              <span className={css.bedge + " " + css.blue + " " + css.middle}>
                지부
              </span>
            ) : (
              <span className={css.bedge + " " + css.white + " " + css.middle}>
                사원
              </span>
            )}{" "}
          </div>

          <div className={css["form-group"]}>
            <label>레벨</label>
            <span>{sales.levelName}</span>
          </div>
          <div className={css["form-group"]}>
            <label>레벨 재설정</label>
            <select
              onChange={(e) => setSelectedLevel(JSON.parse(e.target.value))}
              className={css.input}
            >
              {levelList?.map((level, idx) => (
                <option key={"level-" + idx} value={JSON.stringify(level)}>
                  {level.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => {
                if (selectedLevel) {
                  updateSalesPersonInfo({
                    idx: sales.idx,
                    name: sales.name,
                    parentIdx: Number(sales.parent_idx),
                    levelIdx: selectedLevel.idx,
                  }).then((res) => {
                    if (res.message) {
                      alert("영업사원의 등급이 변경되었습니다.");
                    } else {
                      alert(
                        "영업사원의 등급 변경에 실패하였습니다. 잠시 후 다시 시도해주세요 ."
                      );
                    }
                  });
                }
              }}
              className={css["button"]}
            >
              선택된 등급으로 변경
            </button>
          </div>

          <div className={css["form-group"]}>
            <label>정산률</label>
            <span>{sales.percent / 100}%</span>
          </div>
          <div className={css["form-group"]}>
            <label>등록 날짜</label>
            <span>
              {sales.insert_date !== null ? sales.insert_date : "N/A"}
            </span>
          </div>

          <div className={css["form-group"]}>
            <label>총 영업금액</label>
            <span>
              {sales.total_withdraw_amount !== null
                ? sales.total_withdraw_amount.toLocaleString()
                : "N/A"}
            </span>
          </div>

          <button
            onClick={() => {
              cancelAction();
            }}
            className={css["button"]}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
