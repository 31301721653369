import css from "../../css/component/main/_index.module.scss";

export const Faq = () => {
  return (
    <div className={css["wrapper"]}>
      <div
        className={css.sliderWrapper + " " + css.slider7 + " " + css.content}
      >
        <div>
          <div className={css.titleWrapper}>
            <h1>FAQ</h1>
            <h3>Nahdlatul Ulama (NU)의 자주 묻는 질문을 정리했습니다.</h3>
          </div>
        </div>
      </div>
      <div className={css["sectionWrapper"]}>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 할랄 인증에 따른 비용은 얼마나 될까요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 할랄 인증은 수출대상 국가, 제품의 유형, 제조공정, 인증 요청
              품목 수, 인증기간 등에 따라 인증 비용에 차이가 있습니다.
              일반적으로 할랄 인증에 따른 비용은 기본적인 심사비용(접수비,
              문서심사, 현장심사, 인증비)과 품목당 인증비용으로 나누어지게
              됩니다.
            </p>
            <p>
              특히, 할랄 인증의 경우에는 제품의 유형별 인증이 아니라 품목별
              인증규격으로 규정되어 있습니다. 따라서 할랄 인증비용은 사전에
              전문심사위원과 상담을 통하여 협의 후 검토하시는 것이 좋으며, 사전
              상담의 경우 별도의 상담료 및 추가로 지불하는 비용은 일체 없습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 국내 유통되는 기존 생산설비에서 할랄제품 생산이 가능한가요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 기존 생산되는 설비를 이용하여 할랄 인증이 가능합니다. 그러나
              국내에서 생산되는 일부 생산설비의 경우 여러 품목을 같이 사용할 수
              있다는 안내 문구가 있는데 그 점에 유의를 해야 합니다. 이와 같은
              경우에는 지자체 위생과 또는 관련 부서와 협의하여 할랄 인증을 위해
              문구 내용의 삭제를 요청하시면 가능합니다.
            </p>
            <p>
              또한, 비할랄 제품을 생산한 설비에서는 할랄관련 제품을 생산할 수가
              없어 중복하여 설비를 사용할 수 없으므로, 별도의 설비를 갖추든지
              아니면 모든 제품을 할랄을 받고 필요에 따라 제품에 별도 표기를 해서
              사용할 수 있습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 할랄인증은 식품 관련 제품에만 사용이 가능한가요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 이슬람국가에 수출을 진행하기 위해서는 할랄 인증을 받아야
              합니다. 할랄은 생산과정만 인증을 받는 것이 아닙니다. 생산과정뿐
              아니라 원료에서부터 가공, 생산, 보관, 유통 등 모든 과정에서 할랄
              인증을 받아야 합니다.
            </p>
            <p>
              또한, 할랄은 교육, 금융, 제약, 호텔, 병원, 음식점, 식품, 설비,
              건강보조식품, 화장품, 의류 및 기타 공산품 등 다양한 분야에
              적용되어 할랄 인증이 이루어지고 있으며, 제품의 유형에 따른 각
              품목별 인증을 획득해야 합니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 인도네시아 할랄 화장품 시장은 어떻게 됩니까?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 인도네시아 할랄 화장품 시장 규모 또한 빠르게 성장하고 있습니다.
              Statista 자료에 따르면 인도네시아 할랄 시장품 매출은 2022년에 56억
              달러였으며, 2025년에는 71억 달러, 2028에는 92억 달러로 전망하고
              있습니다.
            </p>
            <p>
              그리고 인도네시아 할랄 화장품 시장의 규모도 점차 확대되고 있고,
              인도네시아는 2022년에 전 세계에서 다섯번째로 많이 할랄 화장품을
              수입한 국가로, 이는 인도네시아 할랄 화장품 시장의 큰 규모를
              나타내는 중요한 지표입니다. 산업 발전 측면에서도 인도네시아 할랄
              화장품 시장의 성장세는 기대됩니다. 2022년에는 화장품 산업에
              종사하는 기업 수는 913개였으나, 2023년에 1010개로 증가했다고
              인도네시아 경제조정부는 밝혔습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 인도네시아에 수출하기 위해서는 어떻게 해야 됩니까?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 인도네시아에 화장품을 수출하기 위해서는 인도네시아 식약청인
              BPOM에 등록을 해야 합니다. 이는 수입 화장품의 품질과 효능을
              점검하고, 안전한 소비자 제품의 유통을 보장하기 위한 절차입니다.
              수입사는 BPOM 기관에서 유통 허가 및 수입허가를 받아야 하며,
              수출업체는 제품의 성분, 제조 방법, 효능 및 안전성 데이터를
              준비하고, 제품의 포장 및 라벨링이 인도네시아의 규정을 준수하도록
              해야 합니다. BPOM 등록은 온라인으로 신청할 수 있습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 기존 할랄 인증과 현재 할랄 인증의 차이점은 무엇인가요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 2019년 전까지는 인도네시아에서 할랄 인증이 필수가 아니며,
              할랄은 인도네시아 정부가 아닌 인증 업무를 진행하는 종교기관에서
              관장해서 제품의 할랄 여부를 정부에서 통제하지 않고 있었습니다.
              현재는 종교부 산하 할랄보장청 (BPJPH)이 그 실무를 담당하고
              있습니다.
            </p>
            <p>
              2019년 전까지는 할랄 인증 신청부터 발급 전까지의 전 과정을
              LPPOM-MUI (Lembaga Pengkajian Pangan Obat-obatan dan Kosmetika
              Majelis Ulama Indonesia : 인도네시아 식품, 의약품, 화장품
              평가기관)라는 기관에서 수행해왔다면, 2019년 10월 17일부터
              LPPOM-MUI는 할랄 감사원 인증 발급, 할랄성(halalness)에 대한 작업,
              LPH(할랄 감사 기관의) 인허가 작업, 그리고 할랄 제품
              보장청(BPJPH)이 의뢰하는 제품의 할랄여부 검사 및 시험을 지원하는
              할랄 검사기관(LPH)로서의 업무를 수행하게 되었습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 인도네시아 유통되는 제품 대상 할랄인증이 필수인가요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 인도네시아 '할랄제품보장법'에 따라 인도네시아에 유통되는 식품,
              화장품 및 소비재 제품 대상 할랄인증이 필수입니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 인도네시아 할랄인증 신청은 어떻게 할 수 있나요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 한국 소재의 회사는 Nahdlatu Ulama(NU)가 지정한 한국 지사를 통해
              신청접수를 할 수 있습니다. 신청서와 제품 및 원료에 대한 정보를
              제출해 주셔야 하며, 관련 신청서류 및 절차 안내는 NU 한국지사로
              연락을 주시면 안내를 자세히 받으실 수 있습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 인도네시아 할랄 인증 취득까지의 소요기간은 얼마나 걸리나요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 평균적으로 5~7개월 정도 소요되며, 신규 혹은 갱신, 또는 요청하는
              제반서류에 대한 피드백 처리에 따라 소요기간은 가감될 수 있습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 할랄 제품 보증 시스템 외부교육은 어떤 교육인가요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 인도네시아 할랄인증절차 및 할랄제품보증시스템 규정에 대한
              이해도를 높일 수 있는 교육으로, 내부교육자, 내부감사자 등
              할랄관리팀 인원의 역량을 보증하기 위해 필요한 교육입니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 인도네시아 할랄인증을 받으려면 할랄전용시설이 있어야 하나요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 제품이 생산되는 공장 환경에 따라 할랄전용시설이 요구되기도 또는
              요구되지 않기도 합니다. 당 위원회에서 제공하는 예비실사 신청을
              통해 제품, 원료 및 생산시설에 대한 검토가 가능합니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 할랄인증 제품에 할랄인증을 받은 원료만 사용해야 하나요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 모든 사용원료가 할랄인증을 받은 원료일 필요는 없습니다.
              HAS23000에는 원료 기준 상 할랄인증서가 있어야만 사용가능한 원료를
              다음과 같이 규정하고 있습니다.
            </p>
            <ul>
              <li>동물성 원료</li>
              <li>향료</li>
              <li>할랄 지위를 추적하기 어려운 치즈, 유청, 유당</li>
              <li>생산공정 및 하부원료가 복잡한 원료 등</li>
            </ul>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>
              Q: 인도네시아 할랄 인증 취득 후 수출가능한 국가는 어디인가요?
            </span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 기업에서 수출국가별로 포장재를 담당하는 기관에 반드시 사전에
              확인절차가 필요합니다. 다만, 인증 신청 시 UAE 및 Gulf 국가용
              MUI할랄확정서 접수를 별도로 하시면 BPJPH할랄확정서 취득 후
              인도네시아, UAE, Gulf국가로 수출이 가능합니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: 할랄인증은 제품 또는 음식에만 사용이 가능한가요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 이슬람국가에 수출을 진행하기 위해서는 할랄 인증이 필요합니다.
              생산과정만 인증을 받는 것이 아니라, 원료에서 가공, 생산, 보관,
              유통 등 모든 과정에서 할랄 인증을 받아야 합니다.
            </p>
            <p>
              또한, 할랄은 교육, 금융, 제약, 호텔, 병원, 음식점, 식품, 설비,
              건강보조식품, 화장품, 의류 및 기타 공산품 등 다양한 분야에
              적용되어 할랄 인증이 이루어지고 있으며, 제품의 유형에 따른 각
              품목별 인증을 획득해야 합니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: BPOM이란?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: BPOM ‘Badan pengawas Obat dan Makanan’로 인도네시아 식약청의
              약자입니다. 일반가공식품, 화장품, 건강기능식품, 의약품 등 제품에
              대한 평가 및 허가 업무를 담당하고 있습니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: BPOM 등록은 필수 인가요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: 네, 인도네시아로 제품을 수출 및 유통하기 위해서는 BPOM 등록 및
              허가를 받아야 합니다.
            </p>
          </div>
        </div>
        <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
          <div className={css["title-wrapper"]}>
            <span>Q: BPOM등록과 할랄 인증 중 어느 것을 먼저 해야 하나요?</span>
          </div>
          <div className={css["content-wrapper"]}>
            <p>
              A: BPOM 등록 신청 시 제품명, 패키지 디자인, 할랄 인증서 보유 유무
              등에 대한 심사 또한 진행되며 할랄 인증서 제출이 요청되는 경우가
              많으므로 할랄 인증 취득 후 BPOM에 등록하는 걸 권장합니다.
            </p>
          </div>
        </div>
        {
          <>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q1: 할랄 인증이란 무엇인가요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A1: 할랄 인증은 이슬람교에서 허용된 제품과 서비스에 대해
                  인증하는 제도입니다. 음식, 음료, 화장품, 의약품 등 다양한
                  제품이 할랄 인증을 받을 수 있으며, 이는 제품이 이슬람 율법에
                  따라 생산되고 처리되었음을 의미합니다.
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>
                  Q2: 인도네시아에서 할랄 인증이 중요한 이유는 무엇인가요?
                </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A2: 인도네시아는 세계 최대의 무슬림 인구를 가진 국가로, 할랄
                  인증은 제품의 신뢰성과 시장 경쟁력을 높이는 중요한 요소입니다.
                  할랄 인증을 받은 제품은 무슬림 소비자들 사이에서 선호되며,
                  시장 진입 장벽을 낮추는 역할을 합니다.
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>
                  Q3: NU한국위원회는 할랄 인증 과정에서 어떤 도움을 제공하나요?{" "}
                </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A3: NU한국위원회는 할랄 인증을 위한 전 과정을 지원합니다.
                  신청서 작성, 서류 준비, 현장 감사 준비 및 대응, 인증 기관과의
                  소통 등 모든 단계를 돕고, 신속하고 효율적인 인증 절차를
                  보장합니다.{" "}
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q4: BPOM 인증이란 무엇인가요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A4: BPOM(Badan Pengawas Obat dan Makanan) 인증은 인도네시아
                  식품의약청이 제공하는 인증으로, 식품, 의약품, 화장품, 건강
                  보조식품 등이 인도네시아 규정에 적합하다는 것을 확인하는
                  제도입니다.
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q5: BPOM 인증을 받아야 하는 이유는 무엇인가요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A5: BPOM 인증은 인도네시아 시장에서 제품을 판매하기 위한 필수
                  요건입니다. 이는 제품의 안전성과 품질을 보장하며, 소비자
                  신뢰를 높이고 법적 규제를 준수하기 위해 필요합니다.
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>
                  Q6: NU한국위원회는 BPOM 인증 과정에서 어떤 도움을 제공하나요?{" "}
                </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A6: NU한국위원회는 BPOM 인증을 위한 종합적인 컨설팅 서비스를
                  제공합니다. 신청서 작성, 제품 샘플 준비, 서류 제출, 현장 감사
                  준비 및 대응, BPOM 기관과의 소통 등 모든 단계를 지원하여
                  인증을 진행합니다.{" "}
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q7: NU한국위원회의 컨설팅 비용은 어떻게 되나요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A7: 컨설팅 비용은 제공되는 서비스의 범위와 복잡성에 따라
                  달라집니다. 초기 상담을 통해 고객의 요구를 파악한 후, 맞춤형
                  견적을 제공합니다.
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q8: 할랄 인증과 BPOM 인증을 동시에 받을 수 있나요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A8: 네, 할랄 인증과 BPOM 인증은 동시에 받을 수 있으며,
                  NU한국위원회는 두 인증 절차를 모두 지원할 수 있는 전문성을
                  갖추고 있습니다.{" "}
                </p>
              </div>
            </div>
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>
                  Q9: 할랄 인증과 BPOM 인증을 받는 데 걸리는 시간은 얼마나
                  되나요?
                </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A9: 인증 절차의 소요 시간은 제품의 종류와 준비된 서류의
                  완성도에 따라 다릅니다. 일반적으로 할랄 인증은 몇 주에서 몇
                  달, BPOM 인증은 몇 달 정도 소요될 수 있습니다. NU한국위원회는
                  절차를 신속하게 진행하기 위해 최선을 다합니다.
                </p>
              </div>
            </div>{" "}
            <div className={css["qna-wrapper"] + " " + css["dark-gray"]}>
              <div className={css["title-wrapper"]}>
                <span>Q10: 인증을 받은 후에도 사후 관리가 필요한가요? </span>
              </div>
              <div className={css["content-wrapper"]}>
                <p>
                  A10: 네, 인증을 받은 후에도 정기적인 사후 관리와 업데이트가
                  필요합니다. 할랄 인증과 BPOM 인증 모두 정기적인 갱신과
                  재검토가 필요하며, NU한국위원회는 사후 관리 서비스도
                  제공합니다.
                </p>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};
