import styled from "styled-components";

export const BaseInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--25, 10px);
  border-radius: 4px;
  border: 1px solid #d2d2d9;
  background: #fff;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--4, 6px);
`;
export const BaseInput = styled.input<{ size?: string }>`
  color: var(--gray-900, #111928);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: var(--2, 8px) 0;
  ${({ size }) => size && "font-size:" + size + ";"}
`;

export const BaseTextAreaInput = styled.textarea<{ size?: string }>`
  color: var(--gray-900, #111928);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: var(--2, 8px) 0;
  height: 240px;
  ${({ size }) => size && "font-size:" + size + ";"}
`;
export const BaseSelect = styled.select<{ size?: string }>`
  color: var(--gray-900, #111928);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: var(--2, 8px) 0;
  ${({ size }) => size && "font-size:" + size + ";"}
`;
