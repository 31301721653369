import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import css from "../../../css/component/dashboard/_index.module.scss";

const DateSelector = ({
  setData,
  initialDate,
}: {
  setData: (data: any) => void;
  initialDate: { startDate: string; endDate: string };
}) => {
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({ ...initialDate });
  useEffect(() => {
    setData(selectedDate);
  }, [selectedDate]);
  return (
    <>
      <div className={css.dateSearchWrapper}>
        <div className={css.inputBaseWrapper}>
          <DatePicker
            className={css.inputBase}
            dateFormat="yyyy-MM-dd" // 날짜 형태
            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
            minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
            selected={new Date(selectedDate.startDate)}
            onChange={(date) => {
              if (date)
                setSelectedDate({
                  ...selectedDate,
                  startDate: date.toLocaleDateString("en-CA"),
                });
            }}
          />
        </div>
      </div>
      <div className={css.dateSearchWrapper}>
        <div className={css.inputBaseWrapper}>
          <DatePicker
            className={css.inputBase}
            dateFormat="yyyy-MM-dd" // 날짜 형태
            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
            minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
            selected={new Date(selectedDate.endDate)}
            onChange={(date) => {
              if (date)
                setSelectedDate({
                  ...selectedDate,
                  endDate: date.toLocaleDateString("en-CA"),
                });
            }}
          />
        </div>
      </div>
    </>
  );
};
export default DateSelector;
