import { useEffect } from "react";
import css from "../../css/component/main/_index.module.scss";
import { SectionTitle } from "../main/component/sectionTitle";
declare global {
  interface Window {
    daum: any;
  }
}
export const Map = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="NU한국위원회 오시는 길" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>NU한국위원회 오시는 길</h1>
            <p className={css.infoBoxWrapper}>
              주소 :서울 서초구 주흥16길 14 <br />
            </p>
          </div>
          <KakaoMap />
        </div>
      </div>
    </>
  );
};

const KakaoMap = () => {
  useEffect(() => {
    // 다수의 지도 퍼가기 서비스를 위해, 설치 스크립트는 최초 1회만 실행
    const scriptLoader = document.createElement("script");
    scriptLoader.charset = "UTF-8";
    scriptLoader.className = "daum_roughmap_loader_script";
    scriptLoader.src =
      "https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js";
    document.body.appendChild(scriptLoader);

    scriptLoader.onload = () => {
      // 지도 생성 스크립트 실행
      new window.daum.roughmap.Lander({
        timestamp: "1732069941005",
        key: "2ma9d",
        mapWidth: "100%",
        mapHeight: "100%",
      }).render();
    };

    return () => {
      document.body.removeChild(scriptLoader);
    };
  }, []);

  return (
    <div
      style={{ width: "100%", height: "400px" }}
      id="daumRoughmapContainer1732069941005"
      className="root_daum_roughmap root_daum_roughmap_landing"
    ></div>
  );
};

export default KakaoMap;
