import { useEffect, useState } from "react";
import css from "../../css/component/main/_index.module.scss";
import { PageNation } from "../main/component/pageNav";
import { getNoticePostList } from "../../utils/request/user/noauth/post";
import { NoticeData } from "./noticeView";
import { NoticeItem } from "../main/component/noticeItem";
import { getDateStringByDate } from "../../utils/utils";
export const RowItem = ({ notice }: { notice: NoticeData }) => {
  return (
    <tr className={css["table-row"] + " " + css["data"]}>
      <td className={css["item"] + " " + css["center"]}>
        <span className={css["gray"]}>{notice.id}</span>
      </td>
      <td className={css["item"] + " " + css["left"]}>
        <span className={css["gray"]}>
          {getDateStringByDate(new Date(notice.insertDate))}
        </span>
      </td>
      <td className={css["item"] + " " + css["left"]}>
        <a href={"/community/notice/view?idx=" + notice.id}>{notice.title}</a>
      </td>
    </tr>
  );
};
export const getTotalPage = (res: any, count: number) => {
  return res.message.match(/,(\d+)/)[1] / count
    ? Number(Math.ceil(res.message.match(/,(\d+)/)[1] / count))
    : 1;
};
export const Notice = () => {
  const count = 4;
  const [searchData, setSearchData] = useState<{
    start: number;
    count: number;
  }>({
    start: 0,
    count: count,
  });
  const [noticeList, setNoticeList] = useState<Array<NoticeData>>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  useEffect(() => {
    getNoticePostList(searchData).then((res) => {
      setNoticeList(res.res);

      setTotalPages(getTotalPage(res, count));
    });
  }, [searchData]);
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider7 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>공지사항</h1>
              <h3>Nahdlatul Ulama (NU)의 공지사항을 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css["is-row-scroll"]}>
            <table className={css["table-wrapper"]}>
              <thead>
                <tr className={css["table-row"]}>
                  <th
                    className={css["item"] + " " + css["center"]}
                    style={{ width: "10%" }}
                  >
                    <span>번호</span>
                  </th>
                  <th
                    style={{ width: "20%" }}
                    className={css["item"] + " " + css["left"]}
                  >
                    <span>날짜</span>
                  </th>
                  <th
                    className={css["item"] + " " + css["left"]}
                    style={{ width: "60%" }}
                  >
                    <span>제목</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((notice: NoticeData, idx: number) => {
                  return <RowItem notice={notice} key={idx} />;
                })}
              </tbody>
            </table>
          </div>

          <PageNation
            totalPages={totalPages}
            setCurrentPage={(page) => {
              setSearchData({ ...searchData, start: (page - 1) * count });
            }}
            currentPage={searchData.start / count + 1}
          />
        </div>
      </div>
    </>
  );
};
