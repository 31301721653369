import { downloadFile, getData, getPostData } from "../../axios/axiosUtil";
import { getJwt } from "../../sessionStorage/getAsync";
import { auth, baseUrl, noAuth } from "../../setting/setting";

const jwt = () => getJwt();

const BASE_URL = baseUrl + "/api/admin/manage/user";

// 1. Retrieve user list
export const getUserList = (params: {
  searchType?: string;
  search?: string;
  adminName?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  start?: number;
  count?: number;
}) => {
  return getData({
    path: `${BASE_URL}/list`,
    params,
    header: { Authorization: jwt() },
  });
};

// 2. Change user contract state
export const changeUserConcertingState = (data: {
  idx: number;
  contractStartDate: string;
  contractEndDate: string;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/concert/state`,
    data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 3. Change user CS admin
export const changeUserCsAdmin = (data: {
  idx: number;
  csAdminIdx: number;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/admin`,
    data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 4. Change user sales person
export const changeUserSalesPerson = (data: {
  idx: number;
  salesPersonIdx: number;
}) => {
  return getPostData({
    path: `${BASE_URL}/update/person`,
    data,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
