import css from "../../css/component/header/_index.module.scss";
import { useState } from "react";
import { getJwt, logout } from "../../utils/sessionStorage/getAsync";
import { log } from "console";

export const Header = () => {
  const [menu, setMenu] = useState<
    "UN" | "CENTER" | "CONCERT" | "CUMMUNITY" | "CUSTOMER" | null
  >(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <div className={css["header"]}>
      <a href="/">
        <div className={css["logo"]} />
      </a>
      <ul className={mobileMenu ? css.active : ""}>
        <li>
          <a
            onClick={(e) => {
              setMenu("UN");
            }}
          >
            NU한국위원회
          </a>
          <div
            className={menu == "UN" ? css["active"] : ""}
            onMouseLeave={() => {
              setMenu(null);
            }}
          >
            <ul>
              <li>
                <a href="/nu">NU한국위원회</a>
              </li>
              <li>
                <a href="/nu/global">Nahdlatul Ulama</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a
            onClick={() => {
              setMenu("CENTER");
            }}
          >
            인증센터
          </a>
          <div
            className={menu == "CENTER" ? css["active"] : ""}
            onMouseLeave={() => {
              setMenu(null);
            }}
          >
            <ul>
              <li>
                <a href="/info/halal">Halal인증소개</a>
              </li>
              <li>
                <a href="/info/halal/process">Halal인증절차</a>
              </li>
              <li>
                <a href="/info/halal/status">Halal인증시장</a>
              </li>
            </ul>
            <hr />
            <ul>
              <li>
                <a href="/info/bpom">
                  BPOM(식약청) <br />
                  등록 소개
                </a>
              </li>
              <li>
                <a href="/info/bpom2">
                  BPOM(식약청) <br />
                  등록 절차
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a
            onClick={() => {
              setMenu("CONCERT");
            }}
          >
            컨설팅센터
          </a>
          <div
            className={menu == "CONCERT" ? css["active"] : ""}
            onMouseLeave={() => {
              setMenu(null);
            }}
          >
            <span>인도네시아 진출</span>
            <ul>
              <li>
                <a href="/concert">인도네시아 개요</a>
              </li>
              <li>
                <a href="/concert/issue">진출 필수요소 및 문제점</a>
              </li>
              <li>
                <a href="/concert/program">컨설팅 프로그램</a>
              </li>
              <li>
                <a href="/concert/nu">NU한국위원회 인증 차별점</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a
            onClick={() => {
              setMenu("CUMMUNITY");
            }}
          >
            커뮤니티
          </a>
          <div
            className={menu == "CUMMUNITY" ? css["active"] : ""}
            onMouseLeave={() => {
              setMenu(null);
            }}
          >
            <ul>
              <li>
                <a href="/community/notice">공지사항</a>
              </li>
              <li>
                <a href="/community/nu">인도네시아 뉴스</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a
            onClick={() => {
              setMenu("CUSTOMER");
            }}
          >
            고객센터
          </a>
          <div
            className={menu == "CUSTOMER" ? css["active"] : ""}
            onMouseLeave={() => {
              setMenu(null);
            }}
          >
            <ul>
              <li>
                <a href="/customer">1:1문의</a>
              </li>
              <li>
                <a href="/customer/faq">자주묻는질문(FAQ)</a>
              </li>
              <li>
                <a href="/customer/map">오시는길</a>
              </li>
            </ul>
          </div>
        </li>
        {getJwt() != "" && getJwt() != null ? (
          <>
            <a
              style={{
                whiteSpace: "nowrap",
                marginRight: 12,
                color: "#017534",
              }}
              href="/dashboard"
            >
              대시보드{" "}
            </a>
            <a
              style={{ whiteSpace: "nowrap" }}
              href="/login"
              onClick={(e) => {
                logout();
              }}
            >
              로그아웃
            </a>
          </>
        ) : (
          <a style={{ whiteSpace: "nowrap" }} href="/login">
            로그인
          </a>
        )}
      </ul>
      <div
        onClick={() => {
          setMobileMenu(!mobileMenu);
        }}
        className={css.ham}
      />
    </div>
  );
};
