import { useNavigate } from "react-router";
import CreateDoubleMenu from "./sideBarItem/double";
import CreateSingleMenu from "./sideBarItem/single";
import { useEffect, useState } from "react";
import { getUserCsAdminInfo } from "../../../utils/request/user/auth/user";
import { logout } from "../../../utils/sessionStorage/getAsync";
const menuObj: any = [
  /*
  {
    isDoubleMenu: true,
    title: { key: "my-info", url: "/mypage", iconKey: "user" },
    depth: 2,
    list: [
      { key: "my-info", url: "/mypage", iconKey: "user" },
      { key: "my-info-edit", url: "/mypage/edit", iconKey: "edit" },
    ],
  },*/

  {
    isDoubleMenu: false,
    title: { key: "현황표", url: "/dashboard", iconKey: "" },
    list: [],
  },

  {
    isDoubleMenu: false,
    title: { key: "제품등록", url: "/dashboard/product/add", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "제품관리", url: "/dashboard/product", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "인증완료 제품", url: "/dashboard/verifyed", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "1:1문의", url: "/dashboard/contact", iconKey: "" },
    list: [],
  },
  {
    isDoubleMenu: false,
    title: { key: "회사정보", url: "/dashboard/company", iconKey: "" },
    list: [],
  },
];
const mobileNoneObj: any = [
  {
    isDoubleMenu: false,
    title: {
      key: "NU한국위원회바로가기",
      url: "/",
      iconKey: "",
    },
    list: [],
  },
];
export const SideBar = ({
  url,
  css,
  setOpenSideBar,
  openSideBar,
}: {
  openSideBar: boolean;
  setOpenSideBar: () => void;
  url: string;
  css: any;
}) => {
  const navigate = useNavigate();
  const [userAdminInfo, setUserAdminInfo] = useState({
    name: "담당자 지정 대기중",
    email: "담당자 지정 대기중",
    phone: "담당자 지정 대기중",
  });
  useEffect(() => {
    getUserCsAdminInfo().then((res) => {
      if (res.state == "200") {
        setUserAdminInfo(
          res.res
            ? res.res
            : {
                name: "담당자 지정 대기중",
                email: "담당자 지정 대기중",
                phone: "담당자 지정 대기중",
              }
        );
      }
    });
  }, []);
  return (
    <div
      className={
        css.sideBarWrapper +
        " " +
        (!openSideBar ? css.closed : css.mobileActive)
      }
      id="mobile-side-bar"
    >
      <div
        onClick={() => {
          navigate("/dashboard");
        }}
        style={{ cursor: "pointer" }}
        className={css.logo}
      />
      <div className={css["user-info-card"]}>
        <div className={css["role"]}>
          <span className={css["icon"] + " " + css.peopleIcon}></span>
          <span> CS 업무 담당자</span>
        </div>
        <div className={css["user-details"]}>
          <p className={css["user-name"]}>
            <span>{userAdminInfo.name}</span>
          </p>
          <p className={css["contact-info"]}>연락처 : {userAdminInfo.phone}</p>
          <p className={css["contact-info"]}>이메일 : {userAdminInfo.email}</p>
        </div>
      </div>
      <div className={css.menuWrapper}>
        {mobileNoneObj.map((data: any) => {
          if (data.isDoubleMenu == true && data.depth) {
            return (
              <>
                {CreateDoubleMenu(
                  css,
                  "/",
                  data.depth ? data.depth : 1,
                  data.title,
                  data.list
                )}
              </>
            );
          } else {
            return <>{CreateSingleMenu(css, "/", data.title)}</>;
          }
        })}
      </div>

      <div className={css.menuWrapper}>
        {menuObj.map((data: any) => {
          if (data.isDoubleMenu == true && data.depth) {
            return (
              <>
                {CreateDoubleMenu(
                  css,
                  "/",
                  data.depth ? data.depth : 1,
                  data.title,
                  data.list
                )}
              </>
            );
          } else {
            return <>{CreateSingleMenu(css, "/", data.title)}</>;
          }
        })}{" "}
      </div>

      <div className={css["role"] + " " + css.pcNone}>
        <span style={{ marginTop: "-24px" }} onClick={() => setOpenSideBar()}>
          메뉴 닫기
        </span>
      </div>
      <div className={css["role"] + " " + css.pcNone}>
        <span
          style={{ padding: 0, color: "blue", marginTop: "-24px" }}
          onClick={() => {
            navigate("/login");
            logout();
          }}
        >
          로그아웃
        </span>
      </div>
    </div>
  );
};
