import styled from "styled-components";

export const LoginInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 16px);
  width: 33%;
  @media (max-width: 600px) {
    width: 100%;
    gap: var(--4, 8px); /* 좁은 간격 */
    align-items: center; /* 가운데 정렬 */
  }
`;
export const LoginButton = styled.button`
  border-radius: 6px;
  border: 1px solid #fdfeff;
  background: #303033;
  display: flex;
  padding: var(--2, 12px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  width: 100%;
`;
export const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
export const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 33%;
`;
