import { useEffect, useState } from "react";
import css from "../../css/component/dashboard/_index.module.scss";
import {
  getAlertByIdxList,
  getUserDashboardInfo,
} from "../../utils/request/user/auth/product";
import { useNavigate } from "react-router";
export interface DashboardData {
  halalComplete: number; // 할랄 인증 완료 수
  waitAll: number; // 대기 중인 전체 항목 수
  completeAll: number; // 완료된 전체 항목 수
  etcConcertList: Array<EtcConcertingCount>; // 기타 콘서트 목록 (빈 배열이므로 any[]로 처리)
  alertList: Array<AlertItemData>; // 알림 목록 (빈 배열이므로 any[]로 처리)
  totalProductCount: number; // 총 제품 수
  bpomComplete: number; // BPOM 인증 완료 수
}
export interface EtcConcertingCount {
  type: number; // etc_concerting 테이블의 type
  state: number; // etc_concerting 테이블의 state
  count: number; // 그룹화된 count 값
}

export function transformArrayToObject(
  data: EtcConcertingCount[]
): Record<number, Record<number, number>> {
  const result: Record<number, Record<number, number>> = {};
  let dataTemp = data;
  if (data.length === 0) {
    dataTemp = [
      { type: 0, state: 0, count: 0 },
      { type: 0, state: 1, count: 0 },
      { type: 1, state: 0, count: 0 },
      { type: 1, state: 1, count: 0 },
      { type: 2, state: 0, count: 0 },
      { type: 2, state: 1, count: 0 },
      { type: 3, state: 0, count: 0 },
      { type: 3, state: 1, count: 0 },
    ];
  }
  dataTemp.forEach((item) => {
    if (!result[item.type]) {
      result[item.type] = {}; // type을 키로 갖는 객체 생성
    }
    result[item.type][item.state] = item.count; // state를 키로 count 값 설정
  });

  return result;
}
export interface AlertItemData {
  comment: string;
  idx_list: string;
  method_type: string;
}
export interface AlertDetailData {
  id: number; // 고유 식별자
  userIdx: number; // 사용자 인덱스
  state: number; // 상태값
  content: string; // 알림 내용
  methodType: number; // 메서드 유형 (0: Halal 문제, 1: BPOM 문제 등)
  insertDate: string; // 삽입 날짜 (ISO 8601 형식)
}
export const AlertDetailListPopup = ({
  alertList,
  type,
  state,
  cancelAction,
}: {
  type: number;
  alertList: Array<AlertDetailData>;
  state: boolean;
  cancelAction: any;
}) => {
  return (
    <div
      style={{ position: "fixed" }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains(css.popupWrapper)) {
          cancelAction();
        }
      }}
      className={css.popupWrapper + " " + (state ? css.active : null)}
    >
      <div className={css["popup-wrapper"]}>
        <div className={css["popup-title"]}>
          <h1>알림상세보기</h1>
          <div onClick={() => cancelAction()} className={css["closeIcon"]} />
        </div>

        <div
          style={{ maxHeight: "70svh", overflowY: "scroll" }}
          className={css["popup-content"]}
        >
          <div className={css["form-group"]}>
            {alertList.map((item, idx) => (
              <AlertItem
                onClick={() => {}}
                css={css}
                key={"alert-" + idx}
                isPopup={true}
                alert={{
                  comment: item.content,
                  idx_list: item.id.toString(),
                  method_type: item.methodType.toString(),
                }}
              />
            ))}
          </div>
          <button onClick={() => cancelAction()} className={css["button"]}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
export const AlertItem = ({
  alert,
  css,
  onClick,
  isPopup,
}: {
  alert: AlertItemData;
  css: any;
  onClick: (data: Array<AlertDetailData>) => void;
  isPopup?: boolean;
}) => {
  const renderBadge = (methodType: string) => {
    const type = parseInt(methodType);
    switch (type) {
      case 0:
        return (
          <span className={`${css["bedge"]} ${css.red} ${css.small}`}>
            Halal 문제
          </span>
        );
      case 1:
        return (
          <span className={`${css["bedge"]} ${css.red} ${css.small}`}>
            BPOM 문제
          </span>
        );
      case 2:
        return (
          <span className={`${css["bedge"]} ${css.blue} ${css.small}`}>
            Halal 문제 해결
          </span>
        );
      case 3:
        return (
          <span className={`${css["bedge"]} ${css.blue} ${css.small}`}>
            BPOM 문제 해결
          </span>
        );
      case 4:
        return (
          <span className={`${css["bedge"]} ${css.white} ${css.small}`}>
            일반 알림
          </span>
        );
      case 5:
        return (
          <span className={`${css["bedge"]} ${css.blue} ${css.small}`}>
            기타 컨설팅 시작
          </span>
        );
      case 6:
        return (
          <span className={`${css["bedge"]} ${css.green} ${css.small}`}>
            기타 컨설팅 완료
          </span>
        );
      case 7:
        return (
          <span className={`${css["bedge"]} ${css.green} ${css.small}`}>
            Halal 인증 완료
          </span>
        );
      case 8:
        return (
          <span className={`${css["bedge"]} ${css.green} ${css.small}`}>
            BPOM 인증 완료
          </span>
        );
    }
  };
  return (
    <div
      onClick={() => {
        getAlertByIdxList({
          idxList: alert.idx_list.split(",").map((item) => parseInt(item)),
        }).then((res) => {
          onClick(res.res);
        });
      }}
      className={css["alert-item"] + " " + css.important}
    >
      <span className={css.titleWrapper}>
        {renderBadge(alert.method_type)}
        <span>{alert.comment.replace(/\[\d+\]/g, "")}</span>
        {isPopup && (
          <a
            style={{ cursor: "pointer", color: "blue" }}
            href={
              "/dashboard/product/view?idx=" +
              (alert.comment.match(/\[\d+\]/g) &&
                alert.comment
                  .match(/\[\d+\]/g)
                  ?.toString()
                  .replace("[", "")
                  .replace("]", ""))
            }
          >
            바로가기
          </a>
        )}
      </span>
    </div>
  );
};
export const concertTypeList = ["인허가", "언어변환", "촬영", "디자인"];
function Dashboard() {
  const navigete = useNavigate();
  const [dashboardInfo, setDashboardInfo] = useState<DashboardData>();
  const [alertPopup, setAlertPopup] = useState({
    state: false,
    alertData: [] as Array<AlertDetailData>,
  });
  useEffect(() => {
    getUserDashboardInfo().then((res) => {
      setDashboardInfo({
        ...res.res,
        etcConcertList:
          res.res.etcConcertList.length > 0
            ? res.res.etcConcertList
            : [
                { type: 0, state: 0, count: 0 },
                { type: 0, state: 1, count: 0 },
                { type: 1, state: 0, count: 0 },
                { type: 1, state: 1, count: 0 },
                { type: 2, state: 0, count: 0 },
                { type: 2, state: 1, count: 0 },
                { type: 3, state: 0, count: 0 },
                { type: 3, state: 1, count: 0 },
              ],
      });
    });
  }, []);
  return (
    <div className={css.dashboard}>
      <section className={css["alert-section"]}>
        <h2>최신 알림</h2>
        <div className={css.alertListWrapper}>
          {dashboardInfo?.alertList && dashboardInfo?.alertList.length > 0 ? (
            dashboardInfo?.alertList.map(
              (alert: AlertItemData, idx: number) => (
                <AlertItem
                  onClick={(data: Array<AlertDetailData>) =>
                    setAlertPopup({ state: true, alertData: data })
                  }
                  css={css}
                  key={"alert-" + idx}
                  alert={alert}
                />
              )
            )
          ) : (
            <div className={css["alert-item"] + " " + css.important}>
              <span className={css.titleWrapper}>
                <span>알림이 없습니다.</span>
              </span>
            </div>
          )}
        </div>
      </section>
      <AlertDetailListPopup
        alertList={alertPopup.alertData}
        type={0}
        state={alertPopup.state}
        cancelAction={() => {
          setAlertPopup({ state: false, alertData: [] });
        }}
      />
      <div className={css.row}>
        <section className={css["contract-section"]}>
          <h2>컨설팅 계약</h2>
          <div className={css.cardWrapper}>
            <div className={css["contract-card"]}>
              <span>
                <span className={css.dashboardPlusIcon + " " + css.icon} />
                대기중
              </span>
              <span className={css.count}>{dashboardInfo?.waitAll}건</span>
            </div>
            <div className={css["contract-card"] + " " + css.complete}>
              <span>
                <span
                  className={css.icon + " " + css.dashboardDoubleCheckIcon}
                />
                완료
              </span>
              <span className={css.count}>{dashboardInfo?.completeAll}건</span>
            </div>
          </div>
        </section>

        <section className={css["contract-section"]}>
          <h2>할랄 가능여부</h2>
          <div className={css.cardWrapper}>
            <div className={css["contract-card"]}>
              <span>
                <span className={css.xIcon + " " + css.icon} />
                불가능
              </span>
              <span className={css.count}>
                {dashboardInfo &&
                  dashboardInfo?.totalProductCount -
                    dashboardInfo.halalComplete}
                건
              </span>
            </div>
            <div className={css["contract-card"] + " " + css.complete}>
              <span>
                <span
                  className={css.icon + " " + css.dashboardDoubleCheckIcon}
                />
                가능
              </span>
              <span className={css.count}>
                {dashboardInfo && dashboardInfo.halalComplete}건
              </span>
            </div>
          </div>
        </section>
      </div>{" "}
      <div className={css.row}>
        <section className={css["contract-section"]}>
          <h2>할랄</h2>
          <div className={css.cardWrapper}>
            <div className={css["contract-card"]}>
              <span>
                <span className={css.dashboardPlusIcon + " " + css.icon} />
                대기중
              </span>
              <span className={css.count}>
                {dashboardInfo &&
                  dashboardInfo?.totalProductCount -
                    dashboardInfo.halalComplete}
                건
              </span>
            </div>
            <div className={css["contract-card"] + " " + css.pending}>
              <span>
                <span className={css.icon + " " + css.dashboardCheckIcon} />
                진행중
              </span>
              <span className={css.count}>{dashboardInfo && 0}건</span>
            </div>
            <div className={css["contract-card"] + " " + css.complete}>
              <span>
                <span
                  className={css.icon + " " + css.dashboardDoubleCheckIcon}
                />{" "}
                완료
              </span>
              <span className={css.count}>
                {dashboardInfo && dashboardInfo.halalComplete}건
              </span>
            </div>
          </div>
        </section>
        <section className={css["contract-section"]}>
          <h2>BPOM</h2>
          <div className={css.cardWrapper}>
            <div className={css["contract-card"]}>
              <span>
                <span className={css.dashboardPlusIcon + " " + css.icon} />
                대기중
              </span>
              <span className={css.count}>
                {dashboardInfo &&
                  dashboardInfo?.totalProductCount - dashboardInfo.bpomComplete}
                건
              </span>
            </div>
            <div className={css["contract-card"] + " " + css.pending}>
              <span>
                <span className={css.icon + " " + css.dashboardCheckIcon} />{" "}
                진행중
              </span>
              <span className={css.count}>{dashboardInfo && 0}건</span>
            </div>
            <div className={css["contract-card"] + " " + css.complete}>
              <span>
                <span
                  className={css.icon + " " + css.dashboardDoubleCheckIcon}
                />{" "}
                완료
              </span>
              <span className={css.count}>
                {dashboardInfo && dashboardInfo.bpomComplete}건
              </span>
            </div>
          </div>
        </section>
      </div>
      <section className={css["contract-section"] + " " + css["row"]}>
        <h2 style={{ alignSelf: "flex-start" }}>기타 계약</h2>
        <div className={css.row}>
          {dashboardInfo &&
            Object.keys(
              transformArrayToObject(dashboardInfo.etcConcertList)
            ).map((item: string, index: number) => {
              return (
                <div className={css["misc-card"]}>
                  <p>{concertTypeList[Number(item)]}</p>
                  <div>
                    <span>진행중</span>
                    <span className={css.count}>
                      {
                        transformArrayToObject(dashboardInfo.etcConcertList)[
                          Number(item)
                        ][0]
                      }
                      건
                    </span>
                  </div>
                  <div className={css.complete}>
                    <span>완료</span>
                    <span className={css.count}>
                      {
                        transformArrayToObject(dashboardInfo.etcConcertList)[
                          Number(item)
                        ][1]
                      }
                      건
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
