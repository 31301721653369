import { useEffect, useState } from "react";
import css from "../../css/component/main/_index.module.scss";
import { PageNation } from "../main/component/pageNav";
import { getNuNoticePostList } from "../../utils/request/user/noauth/post";
import { NoticeData } from "../community/noticeView";
import { NoticeItem } from "../main/component/noticeItem";
import { getTotalPage } from "../community/notice";

export const NuPage3 = () => {
  const count = 4;
  const [totalPages, setTotalPages] = useState<number>(0);

  const [searchData, setSearchData] = useState<{
    start: number;
    count: number;
  }>({
    start: 0,
    count: count,
  });
  const [noticeList, setNoticeList] = useState<Array<NoticeData>>([]);
  useEffect(() => {
    getNuNoticePostList(searchData).then((res) => {
      setNoticeList(res.res);
      setTotalPages(getTotalPage(res, count));
    });
  }, [searchData]);
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider7 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>인도네시아 뉴스</h1>
              <h3>인도네시아의 새 소식을 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <div className={css.noticeListWrapper + " " + css.row}>
            {noticeList.map((notice) => (
              <NoticeItem
                href={"/community/nu/view" + "?idx=" + notice.id}
                notice={notice}
                key={"notice-" + notice.id}
              />
            ))}
            {noticeList.length % 4 !== 0 &&
              Array.from({ length: noticeList.length % 4 }).map((_, index) => (
                <div key={"null-" + index} className={css.noticeWrapper}>
                  {}
                </div>
              ))}
          </div>
          <PageNation
            totalPages={totalPages}
            setCurrentPage={(page) => {
              setSearchData({ ...searchData, start: (page - 1) * count });
            }}
            currentPage={searchData.start / count + 1}
          />
        </div>
      </div>
    </>
  );
};
