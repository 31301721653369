import styled from "styled-components";
import { LoginButton } from "../styled/login";
import {
  BaseInput,
  BaseInputWrapper,
  BaseSelect,
  BaseTextAreaInput,
} from "../styled/input";
const BaseInputWrapperHeight = styled(BaseInputWrapper)`
  min-height: 42px;
  padding: 0 10px;
`;
export const BaseInputWithLabel = ({
  label,
  placeholder,
  onChange,
  isPw,
  value,
  onBlur,
}: {
  label: string;
  placeholder: string;
  onChange?: (data: any) => void;
  onBlur?: (data: any) => void;
  isPw?: boolean;
  value?: string;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <BaseInputWrapperHeight>
        <BaseInput
          onChange={onChange}
          onBlur={onBlur}
          size="16px"
          type={isPw ? "password" : "text"}
          id="name"
          value={value}
          placeholder={placeholder}
        />
      </BaseInputWrapperHeight>
    </BaseInputWithLabelWrapper>
  );
};
export const BaseTextAreaInputWithLabel = ({
  label,
  placeholder,
  onChange,
}: {
  label: string;
  onChange?: (data: any) => void;
  placeholder: string;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <BaseInputWrapperHeight>
        <BaseTextAreaInput
          onChange={onChange}
          size="16px"
          id="name"
          placeholder={placeholder}
        />
      </BaseInputWrapperHeight>
    </BaseInputWithLabelWrapper>
  );
};

export const BaseSelectWithLabel = ({
  label,
  placeholder,
}: {
  label: string;
  placeholder: string;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <BaseInputWrapperHeight>
        <BaseSelect size="16px" id="name">
          <option>할랄</option>
          <option>BPOM</option>
          <option>인허가</option>
          <option>촬영</option>
          <option>언어변환</option>
          <option>디자인</option>
        </BaseSelect>
      </BaseInputWrapperHeight>
    </BaseInputWithLabelWrapper>
  );
};
export const BaseDataSelectWithLabel = ({
  label,
  list,
  onChange,
}: {
  label: string;
  list?: Array<any>;
  onChange?: (e: any) => void;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <BaseInputWrapperHeight>
        <BaseSelect onChange={onChange} size="16px" id="name">
          {list?.map((item) => (
            <option value={JSON.stringify(item)}>{item.name}</option>
          ))}
        </BaseSelect>
      </BaseInputWrapperHeight>
    </BaseInputWithLabelWrapper>
  );
};
export const BaseFileInputWithLabel = ({
  label,
  placeholder,
  onChange,
}: {
  label: string;
  placeholder: string;
  onChange?: (e: any) => void;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <BaseInputWrapperHeight>
        <BaseInput
          onChange={onChange}
          size="16px"
          type="file"
          id="name"
          placeholder={placeholder}
        />
      </BaseInputWrapperHeight>
    </BaseInputWithLabelWrapper>
  );
};

export const BaseInputWithLabelWithButton = ({
  label,
  placeholder,
  buttonText,
  onClick,
  alertText,
  onChange,
  value,
}: {
  label: string;
  placeholder: string;
  buttonText?: string;
  alertText?: string;
  onClick?: () => void;
  onChange?: (e: any) => void;
  value?: string;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <AlertWrapper>
        <BaseInputButtonWrapper>
          <BaseInputWrapperHeight>
            <BaseInput
              onChange={onChange}
              size="16px"
              type="text"
              id="name"
              value={value}
              placeholder={placeholder}
            />
          </BaseInputWrapperHeight>
          <InputButton
            onClick={() => {
              if (onClick) onClick();
            }}
          >
            <LoginButtonText>{buttonText}</LoginButtonText>
          </InputButton>
        </BaseInputButtonWrapper>
        <AlertText>{alertText} </AlertText>
      </AlertWrapper>
    </BaseInputWithLabelWrapper>
  );
};

export const BaseInputWithLabelWithAlert = ({
  label,
  placeholder,
  buttonText,
  onClick,
  alertText,
  onChange,
  value,
}: {
  label: string;
  placeholder: string;
  buttonText?: string;
  alertText?: string;
  onClick?: () => void;
  onChange?: (e: any) => void;
  value?: string;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <AlertWrapper>
        <BaseInputButtonWrapper>
          <BaseInputWrapperHeight>
            <BaseInput
              onChange={onChange}
              size="16px"
              type="text"
              id="name"
              placeholder={placeholder}
              value={value}
            />
          </BaseInputWrapperHeight>
          <AlertTextStyled color={alertText == "일치" ? true : false}>
            {alertText}{" "}
          </AlertTextStyled>
        </BaseInputButtonWrapper>
      </AlertWrapper>
    </BaseInputWithLabelWrapper>
  );
};
export const BaseInputNoLabelWithButton = ({
  placeholder,
}: {
  placeholder: string;
}) => {
  return (
    <BaseInputButtonWrapper>
      <BaseInputWrapperHeight>
        <BaseInput
          size="16px"
          type="text"
          id="name"
          placeholder={placeholder}
        />
      </BaseInputWrapperHeight>
      <InputButton>
        <LoginButtonText>인증번호 전송</LoginButtonText>
      </InputButton>
    </BaseInputButtonWrapper>
  );
};
export const BaseInputWithLabelWithTime = ({
  label,
  placeholder,
  onChange,
  onClick,
}: {
  label: string;
  placeholder: string;
  onChange?: (data: any) => void;
  onClick?: () => void;
}) => {
  return (
    <BaseInputWithLabelWrapper>
      <BaseInputLabelText>{label}</BaseInputLabelText>
      <AlertWrapper>
        <BaseInputButtonWrapper>
          <BaseInputWrapperHeight>
            <BaseInput
              onChange={onChange}
              size="16px"
              type="text"
              id="name"
              placeholder={placeholder}
            />
            {/*<VerifyCodeTimeText>5:00</VerifyCodeTimeText>*/}
          </BaseInputWrapperHeight>{" "}
          <InputButton onClick={onClick}>
            <LoginButtonText>인증 완료</LoginButtonText>
          </InputButton>
        </BaseInputButtonWrapper>
        {/**
        <AlertText>
          인증번호가 발송 완료되었습니다. 이메일을 확인해주세요. *(일반 메일함 /
          스팸 메일함 확인)
        </AlertText> */}
      </AlertWrapper>
    </BaseInputWithLabelWrapper>
  );
};

const AlertText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;
const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--3, 6px);
  width: 100%;
`;
const VerifyCodeTimeText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
`;
const BaseInputButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: var(--3, 12px);
  width: 100%;
`;
const InputButton = styled.button`
  width: fit-content;
  border-radius: var(--rounded, 4px);
  border: 1px solid #fdfeff;
  background: #017534;
  padding: var(--2, 8px) var(--3, 12px);
`;
const BaseInputWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--3, 12px);
  width: 100%;
`;
const BaseInputLabelText = styled.span`
  color: #303033;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
`;

const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const AlertTextStyled = styled(AlertText)<{ color?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  color: ${(props) => (props.color ? "black" : "red")};
`;
