import { useNavigate } from "react-router";
import css from "../../../css/component/main/_index.module.scss";
import { baseUrl } from "../../../utils/setting/setting";
import { NoticeData } from "../../community/noticeView";

export const NoticeItem = ({
  notice,
  href,
  imgData,
  onlyTitle,
}: {
  notice: NoticeData;
  href: string;
  imgData?: any;
  onlyTitle?: boolean;
}) => {
  const navigate = useNavigate();
  if (onlyTitle) {
    return (
      <div className={css.noticeWrapper}>
        <h3>{notice.title}</h3>
      </div>
    );
  }
  return (
    <div className={css.noticeWrapper}>
      <div className={css.noticeTitleWrapper}>
        <img
          onClick={(e) => {
            e.preventDefault();
            navigate(href);
          }}
          src={
            !imgData
              ? baseUrl +
                "/api/image?path=" +
                notice.imgUrl?.split(",")[notice.imgUrl?.split(",").length - 1]
              : imgData
          }
          alt="notice"
        />
        <h3
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            navigate(href);
          }}
        >
          {notice.title}
        </h3>
      </div>
    </div>
  );
};
