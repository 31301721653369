import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Outlet, Route, Routes } from "react-router";
import { UrlContextProvider } from "./context/url";
import "./App.css";
import { Main } from "./pages/main/main";
import { NuPage } from "./pages/nu/postNU";
import { NuPage2 } from "./pages/nu/postNU2";
import { NuPage3 } from "./pages/nu/postNU3";
import { Login } from "./pages/login/login";
import { Header } from "./components/header/header";
import { FindLoginInfo } from "./pages/login/findInfo";
import { Join } from "./pages/login/join";
import { Halal } from "./pages/info/halal/halal";
import { HalalPage2 } from "./pages/info/halal/halalPage2";
import { HalalPage3 } from "./pages/info/halal/halalPage3";
import { Bpom } from "./pages/info/bpom/bpom";
import { Concert } from "./pages/concert/concert";
import { Concert2 } from "./pages/concert/concert2";
import { Concert3 } from "./pages/concert/concert3";
import { Concert4 } from "./pages/concert/concert4";
import { Notice } from "./pages/community/notice";
import { NoticeView, NuNoticeView } from "./pages/community/noticeView";
import { Faq } from "./pages/customer/faq";
import { Map } from "./pages/customer/map";
import { Contact } from "./pages/customer/contact";
import { Footer } from "./components/header/footer";
import Wrapper from "./dashboardPages/wrapper/wrapper";
import Dashboard from "./dashboardPages/main/dashboard";
import ProductSearch from "./dashboardPages/main/product";
import ProductDetail from "./dashboardPages/main/productDetail";
import ProductVerifyedSearch from "./dashboardPages/main/productVerifyed";
import DashboardContact from "./dashboardPages/main/contact";
import CompanyInfoPage from "./dashboardPages/main/companyInfo";
import { DashboardLogin } from "./dashboardPages/login/login";
import ProductAdd from "./dashboardPages/main/productAdd";
import { AdminDashboardLogin } from "./adminDashboardPages/login/login";
import { AdminSearch } from "./adminDashboardPages/main/adminManage/admin";
import { UserSearch } from "./adminDashboardPages/main/userManage/user";
import { SalesSearch } from "./adminDashboardPages/main/salesManage/sales";
import AdminWrapper from "./adminDashboardPages/wrapper/wrapper";
import AdminProductSearch from "./adminDashboardPages/main/product";
import AdminProductDetail from "./adminDashboardPages/main/productDetail";
import { Bpom2 } from "./pages/info/bpom/bpom2";
import { SalesWithdrawSearch } from "./adminDashboardPages/main/salesWithdrawManage/salesWithdraw";
const MainRoute = () => {
  return (
    <>
      <Route
        path="/"
        element={
          <>
            <Main />
          </>
        }
      ></Route>
      <Route
        path="/login"
        element={
          <>
            <Login />
          </>
        }
      ></Route>
      <Route
        path="/find"
        element={
          <>
            <FindLoginInfo />
          </>
        }
      ></Route>
      <Route
        path="/join"
        element={
          <>
            <Join />
          </>
        }
      ></Route>
    </>
  );
};
const CommunityRoute = () => {
  return (
    <>
      <Route
        path="/community/notice"
        element={
          <>
            <Notice />
          </>
        }
      ></Route>
      <Route
        path="/community/notice/view"
        element={
          <>
            <NoticeView />
          </>
        }
      ></Route>
      <Route
        path="/community/nu"
        element={
          <>
            <NuPage3 />
          </>
        }
      ></Route>
      <Route
        path="/community/nu/view"
        element={
          <>
            <NuNoticeView />
          </>
        }
      ></Route>
    </>
  );
};
const CustomerRoute = () => {
  return (
    <>
      <Route
        path="/customer"
        element={
          <>
            <Contact />
          </>
        }
      ></Route>
      <Route
        path="/customer/faq"
        element={
          <>
            <Faq />
          </>
        }
      ></Route>
      <Route
        path="/customer/map"
        element={
          <>
            <Map />
          </>
        }
      ></Route>
    </>
  );
};
const InfoHalalRoute = () => {
  return (
    <>
      <Route
        path="/info/halal"
        element={
          <>
            <Halal />
          </>
        }
      ></Route>{" "}
      <Route
        path="/info/halal/process"
        element={
          <>
            <HalalPage2 />
          </>
        }
      ></Route>
      <Route
        path="/info/halal/status"
        element={
          <>
            <HalalPage3 />
          </>
        }
      ></Route>
    </>
  );
};
const InfoBpomRoute = () => {
  return (
    <>
      <Route
        path="/info/bpom"
        element={
          <>
            <Bpom />
          </>
        }
      ></Route>
      <Route
        path="/info/bpom2"
        element={
          <>
            <Bpom2 />
          </>
        }
      ></Route>
    </>
  );
};
const NuRoute = () => {
  return (
    <>
      <Route
        path="/nu"
        element={
          <>
            <NuPage />
          </>
        }
      ></Route>
      <Route
        path="/nu/global"
        element={
          <>
            <NuPage2 />
          </>
        }
      ></Route>
    </>
  );
};
const ConcertRoute = () => {
  return (
    <>
      <Route
        path="/concert"
        element={
          <>
            <Concert />
          </>
        }
      ></Route>
      <Route
        path="/concert/issue"
        element={
          <>
            <Concert2 />
          </>
        }
      ></Route>
      <Route
        path="/concert/program"
        element={
          <>
            <Concert3 />
          </>
        }
      ></Route>
      <Route
        path="/concert/nu"
        element={
          <>
            <Concert4 />
          </>
        }
      ></Route>
    </>
  );
};
const DashboardRoute = () => {
  return (
    <>
      <Route
        path="/dashboard/login"
        element={
          <>
            <DashboardLogin />
          </>
        }
      ></Route>
      <Route path="/dashboard" element={<Wrapper />}>
        <Route
          path="/dashboard"
          element={
            <>
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="/dashboard/product"
          element={
            <>
              <ProductSearch />
            </>
          }
        ></Route>
        <Route
          path="/dashboard/product/add"
          element={
            <>
              <ProductAdd />
            </>
          }
        ></Route>
        <Route
          path="/dashboard/verifyed"
          element={
            <>
              <ProductVerifyedSearch />
            </>
          }
        ></Route>
        <Route
          path="/dashboard/product/view"
          element={
            <>
              <ProductDetail />
            </>
          }
        ></Route>{" "}
        <Route
          path="/dashboard/contact"
          element={
            <>
              <DashboardContact />
            </>
          }
        ></Route>
        <Route
          path="/dashboard/company"
          element={
            <>
              <CompanyInfoPage />
            </>
          }
        ></Route>
      </Route>
    </>
  );
};
const AdminDashboardRoute = () => {
  return (
    <>
      <Route
        path="/admin/dashboard/login"
        element={
          <>
            <AdminDashboardLogin />
          </>
        }
      ></Route>
      <Route path="/admin/dashboard" element={<AdminWrapper />}>
        <Route
          path="/admin/dashboard"
          element={
            <>
              <AdminSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/user"
          element={
            <>
              <UserSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/sales"
          element={
            <>
              <SalesSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/sales/withdraw"
          element={
            <>
              <SalesWithdrawSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/product"
          element={
            <>
              <AdminProductSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/verifyed"
          element={
            <>
              <ProductVerifyedSearch />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/product/view"
          element={
            <>
              <AdminProductDetail />
            </>
          }
        ></Route>{" "}
        <Route
          path="/admin/dashboard/contact"
          element={
            <>
              <DashboardContact />
            </>
          }
        ></Route>
        <Route
          path="/admin/dashboard/company"
          element={
            <>
              <CompanyInfoPage />
            </>
          }
        ></Route>
      </Route>
    </>
  );
};
function App() {
  return (
    <BrowserRouter>
      <React.StrictMode>
        <UrlContextProvider>
          <Routes>
            {DashboardRoute()}
            {AdminDashboardRoute()}

            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              {MainRoute()}
              {NuRoute()}
              {InfoHalalRoute()}
              {InfoBpomRoute()}
              {ConcertRoute()}
              {CommunityRoute()}
              {CustomerRoute()}
            </Route>
          </Routes>
        </UrlContextProvider>
      </React.StrictMode>
    </BrowserRouter>
  );
}

export default App;
