import css from "../../css/component/main/_index.module.scss";
import bpomFoodProcess from "../../assets/images/concert/status.png";
import indoPerson from "../../assets/images/concert/indoPerson.png";
import concert1 from "../../assets/images/concert/concert1.png";
import concert2 from "../../assets/images/concert/concert2.png";
import concert3 from "../../assets/images/concert/concert3.png";
import concert4 from "../../assets/images/concert/concert4.png";
import concert5 from "../../assets/images/concert/concert5.png";
import concert6 from "../../assets/images/concert/concert6.png";
import concert7 from "../../assets/images/concert/concert7.png";
import concert8 from "../../assets/images/concert/concert8.png";
import bpomBeautyProcess from "../../assets/images/concert/indonesiaMarket.png";
import cart from "../../assets/images/concert/cart1.png";
import indonesiaMarketProduct from "../../assets/images/concert/indonesiaMarketProduct.png";
import halalharamProduct from "../../assets/images/concert/halalharamProduct.png";
import { SectionTitle } from "../main/component/sectionTitle";
export const Concert3 = () => {
  return (
    <>
      <div className={css["wrapper"]}>
        <div
          className={css.sliderWrapper + " " + css.slider6 + " " + css.content}
        >
          <div>
            <div className={css.titleWrapper}>
              <h1>인도네시아 진출 컨설팅</h1>
              <h3>Nahdlatul Ulama (NU)의 차별화된 컨설팅 기법을 살펴보세요.</h3>
            </div>
          </div>
        </div>
        <div className={css["sectionWrapper"]}>
          <SectionTitle title="컨설팅 프로그램" description="" />
          <div className={css.sectionFirstContentWrapper}>
            <h1>기업의 제품에 대한 진출 성공가능 제품 선별 및 컨설팅</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert1}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>
          <div className={css.sectionFirstContentWrapper}>
            <h1>각종 정부지원자금소개</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert2}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              각종 정부지원자금을 기업에 맞춤형으로 매칭시켜 드리는 전문
              서비스를 제공합니다. AT, KOTRA 등 여러 인증 및 지원 제도로 기업의
              특성과 필요에 맞게 연결하여, 보다 수월한 수출 환경을 조성하는 데
              도움을 드립니다. 이를 통해 기업의 수출 성공률을 높이고, 글로벌
              시장에서 경쟁력을 강화할 수 있도록 지원합니다.
            </p>
          </div>{" "}
          <div className={css.sectionFirstContentWrapper}>
            <h1>할랄인증, BPOM인증</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert3}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionFirstContentWrapper}>
            <h1>당사만의 비즈니스적 자원과 인프라를 통한 진출 전략 개요</h1>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert4}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionFirstContentWrapper}>
            <h1>유통채널 제공</h1>{" "}
            <p className={css.infoBoxWrapper}>
              인도네시아 이슬람 최대 종파 NU(Nahdlatul Ulama)와의 협력을 통해
              강력한 유통채널을 제공합니다.
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert5}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionTwoContentWrapper}>
            <p className={css.contentWrapper}>
              NU는 인도네시아의 이슬람 종파로서 추정 회원수가 약 1억 3000만 명.
              인도네시아의 정치, 문화, 사회 등 전반에 영향력을 행사하고 있으며
              당사는 밀접한 업무 협업을 통해 한국-인도네시아의 산업발전에
              도모하는 협약을 체결{" "}
            </p>
          </div>{" "}
          <div className={css.sectionFirstContentWrapper}>
            <h1>매출향상 및 합리적 배분</h1>{" "}
            <p className={css.infoBoxWrapper}>
              K-POP의 막강한 영향력으로 한국의 셀럽 미디어 AI를 통한 홍보로 제품
              인지도를 높이고 매출상승에 직접적인 영향을 미칩니다.{" "}
            </p>
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            <div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert6}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div>
          </div>{" "}
          <div className={css.sectionFirstContentWrapper}>
            <h1>투자유치 및 사업확장에 대한 종합 컨설팅 맞춤형 솔루션 제공</h1>{" "}
          </div>
          <div className={css.sectionTwoContentWrapper + " " + css.year}>
            {/**<div style={{ height: "auto" }} className={css.imgRowWrapper}>
              <img
                src={concert7}
                style={{ objectFit: "initial", height: "auto" }}
              />
            </div> */}
          </div>{" "}
          <div className={css.sectionTwoContentWrapper}>
            <div className={css.contentInfoWrapper}>
              <h3>1. 기업 평가 및 분석 </h3>
              <p>
                NU한국위원회는 우수한 제품과 서비스를 보유한 회사를 대상으로
                철저한 평가와 분석을 수행합니다. 이 과정에서는 기업의 강점과
                약점을 면밀히 파악하고, 시장 위치, 재무 상태, 경영 전략, 성장
                가능성을 종합적으로 검토합니다. 이를 통해 기업이 갖춘 경쟁력을
                확인하고, 투자 유치와 사업 확장을 위한 기본적인 전략을
                마련합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>2. 투자 유치 전략 수립</h3>
              <p>
                평가와 분석 결과를 바탕으로, NU한국위원회는 기업에 맞춤형 투자
                유치 전략을 수립합니다. 이 단계에서는 기업의 비전과 목표에
                부합하는 잠재 투자자들을 식별하고, 벤처 캐피탈(VC),
                액셀러레이터(AC), 엔젤 투자자 등 다양한 투자 경로를 통해 자본을
                유치할 계획을 세웁니다. 기업의 사업 모델, 성장 가능성, 수익성
                등을 강조한 투자 제안서를 작성하여 투자자들에게 매력적으로
                어필할 수 있도록 합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>3. 투자자 발굴 및 유치</h3>
              <p>
                NU한국위원회는 광범위한 네트워크와 경험을 활용하여 적합한
                투자자들을 발굴하고, 이들과의 연결을 주선합니다. 투자자들과의
                미팅과 프레젠테이션을 통해 기업의 비전과 성장 가능성을 설명하고,
                투자 유치에 필요한 협상을 진행합니다. 이 과정에서 투자자들의
                요구와 기대를 충족시키기 위해 협상 전략을 조정하며, 최종적으로
                투자 계약을 체결합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>4. 사업 확장 계획 실행</h3>
              <p>
                투자 유치에 성공하면, NU한국위원회는 기업의 사업 확장을 위한
                구체적인 실행 계획을 수립하고 이를 실행에 옮깁니다. 신규 시장
                진출, 제품 라인 확장, 연구 개발 투자, 마케팅 강화 등 다양한
                전략을 통해 기업의 성장을 도모합니다. NU한국위원회는 이 과정에서
                지속적인 모니터링과 피드백을 제공하여 사업 확장이 원활하게
                이루어질 수 있도록 지원합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>5. 기업 가치 상승</h3>
              <p>
                사업 확장과 동시에 기업의 재무 상태와 운영 성과를 개선하여 기업
                가치를 상승시킵니다. 이를 위해 NU한국위원회는 효율적인 자원
                관리, 비용 절감, 수익 극대화 전략을 도입합니다. 또한, 기업의
                브랜드 인지도를 높이고, 고객 만족도를 증대시키기 위한 다양한
                마케팅 및 홍보 활동을 전개합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>6. 상장 준비 및 실행</h3>
              <p>
                기업 가치가 충분히 상승하고 재무 상태가 안정되면, NU한국위원회는
                기업의 상장을 준비합니다. 상장을 위한 법적, 재무적 준비 사항을
                점검하고, 관련 규정을 준수하기 위한 내부 프로세스를 정비합니다.
                상장 신청서 작성, 주관사 선정, 투자자 대상 로드쇼 등 상장을 위한
                모든 절차를 지원합니다. 상장 후에도 지속적인 성장과 성과 유지를
                위해 필요한 전략적 조언과 지원을 계속 제공합니다.
              </p>
            </div>
            <div className={css.contentInfoWrapper}>
              <h3>7. 자본 시장 진출</h3>
              <p>
                최종적으로, 기업은 성공적으로 상장하여 자본 시장에 진출하게
                됩니다. 상장을 통해 확보한 자본을 바탕으로 더 큰 성장 기회를
                모색하며, 글로벌 시장에서 경쟁력을 강화할 수 있습니다.
                NU한국위원회는 기업이 상장 후에도 지속적으로 성장할 수 있도록
                장기적인 전략적 파트너로서 함께 합니다.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
