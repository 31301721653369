import styled from "styled-components";
import css from "../../css/component/main/_index.module.scss";
import { BaseInputWithLabel } from "../../pages/login/component/BaseInputWithLabel";
import { SectionTitle } from "../../pages/main/component/sectionTitle";
import { useNavigate } from "react-router";
import { useState } from "react";
import { login } from "../../utils/request/admin/login";
import { LoginInputWrapper } from "../../pages/login/styled/login";

export const AdminDashboardLogin = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    id: "",
    pw: "",
  });
  return (
    <div className={css["wrapper"]}>
      <div className={css["sectionWrapper"]}>
        <div
          onClick={() => {
            navigate("/");
          }}
          className={css.logo}
        />
        <SectionTitle title="관리자 대시보드 로그인" description="" />
        <LoginInputWrapper>
          <BaseInputWithLabel
            onChange={(e) => setInfo({ ...info, id: e.target.value })}
            label="id"
            placeholder="id"
          />
          <BaseInputWithLabel
            isPw={true}
            onChange={(e) => setInfo({ ...info, pw: e.target.value })}
            label="PASSWORD"
            placeholder="PASSWORD"
          />
          <button
            onClick={() => {
              login(info).then((res) => {
                if (res) {
                  navigate("/admin/dashboard");
                } else {
                  alert("로그인 실패");
                }
              });
            }}
            className={css.button + " " + css.fullWidth}
          >
            로그인
          </button>
        </LoginInputWrapper>
      </div>
    </div>
  );
};
const JoinInfoWrapper = styled.div`
  display: flex;
  padding-top: 28px;
  justify-content: center;
  align-items: center;
  gap: var(--3, 12px);
  flex-direction: column;
  width: 100%;
`;
const JoinInfoText = styled.span`
  color: #303033;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
`;
const JoinLinkTextWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--3, 0px);
  text-decoration: none;
`;
const JoinLinkText = styled.span`
  color: #5d1d96;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const JoinLinkTextIcon = styled.div`
  filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(5705%)
    hue-rotate(270deg) brightness(96%) contrast(93%);
  width: 18px;
  height: 18px;
`;
const LoginButton = styled.button`
  border-radius: 36px;
  border: 1px solid #fdfeff;
  background: #017534;
  display: flex;
  padding: var(--2, 12px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  width: 100%;
`;
const LoginButtonText = styled.span`
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
`;
const LoginInfoFindButtonListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--6, 28px);
  width: 100%;
`;
const LoginInfoFindButton = styled.a`
  border: none;
  background: none;
  text-decoration: none;
  padding: 6px 0;
`;
const LoginInfoFindButtonText = styled.span`
  color: var(--gray-500, var(--gray-500, #6b7280));
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
`;
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 33%;
`;
