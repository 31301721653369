import { getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { auth, noAuth } from "../../../setting/setting";

const url = auth + "/product";
const jwt = () => getJwt();

// 사용자 대시보드 정보 조회
export const getUserDashboardInfo = () => {
  return getData({
    path: `${url}/get/dashboard`,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 인증 완료 제품 목록 조회
export const getUserVerifiedProductList = (info: {
  concertType: string;
  startDate: string;
  endDate: string;
  userIdx: number;
  start: number;
  count: number;
}) => {
  return getPostData({
    path: `${url}/get/verified/list`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 사용자용 제품 목록 조회
export const getProductListByUser = (info: {
  concertType: "전체" | "할랄" | "BPOM";
  state: number;
  startDate: string;
  endDate: string;
  start: number;
  count: number;
  search: string;
}) => {
  return getPostData({
    path: `${url}/get/product/list`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 제품 추가
export const insertProduct = (info: {
  infoFile: File;
  infoFile2: File;
  productName: string;
}) => {
  const formData = new FormData();
  formData.append("infoFile", info.infoFile);
  formData.append("infoFile2", info.infoFile2);
  formData.append("productName", info.productName);

  return getPostData({
    path: `${url}/insert/product`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// 제품 컨설팅 정보 조회
export const getProductConcertInfo = (info: { productIdx: number }) => {
  return getData({
    path: `${url}/get/product/info`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
export const getProductInfo = (info: { productIdx: number }) => {
  return getData({
    path: `${url}/get/product/info/name`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 인증 게시물 목록 조회
export const getProductBpomPostList = (info: {
  idx: number;
  confirmationStatus: string;
  state: string;
  type: string;
  startDate: string;
  endDate: string;
  start: number;
  count: number;
}) => {
  return getPostData({
    path: `${url}/get/product/bpom/post/list`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 인증 게시물 목록 조회
export const getProductHalalPostList = (info: {
  idx: number;
  confirmationStatus: string;
  state: string;
  type: string;
  startDate: string;
  endDate: string;
  start: number;
  count: number;
}) => {
  return getPostData({
    path: `${url}/get/product/halal/post/list`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
export const updateBpomDocFile = (info: {
  file: File;
  productIdx: number;
  docIdx: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docIdx", info.docIdx.toString());

  return getPostData({
    path: `${url}/update/product/bpom/doc`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// Halal 문서 파일 업데이트
export const updateHalalDocFile = (info: {
  file: File;
  productIdx: number;
  docIdx: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docIdx", info.docIdx.toString());

  return getPostData({
    path: `${url}/update/product/halal/doc`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// Halal Post 단일 조회
export const getHalalPostByIdx = (info: { idx: number }) => {
  return getPostData({
    path: `${url}/get/product/halal/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM Post 단일 조회
export const getBpomPostByIdx = (info: { idx: number }) => {
  return getPostData({
    path: `${url}/get/product/bpom/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 인증 게시물 추가
export const insertHalalPost = (info: {
  productIdx: number;
  title: string;
  content: string;
}) => {
  return getPostData({
    path: `${url}/insert/product/halal/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 인증 게시물 추가
export const insertBpomPost = (info: {
  productIdx: number;
  title: string;
  content: string;
}) => {
  return getPostData({
    path: `${url}/insert/product/bpom/post`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// Halal 문서 추가
export const insertHalalDocs = (info: {
  file: File;
  productIdx: number;
  docType: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docType", info.docType.toString());

  return getPostData({
    path: `${url}/insert/product/halal/doc`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// BPOM 문서 추가
export const insertBpomDocs = (info: {
  file: File;
  productIdx: number;
  docType: number;
}) => {
  const formData = new FormData();
  formData.append("file", info.file);
  formData.append("productIdx", info.productIdx.toString());
  formData.append("docType", info.docType.toString());

  return getPostData({
    path: `${url}/insert/product/bpom/doc`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// Halal 문서 리스트 조회
export const getHalalDocs = (info: { productIdx: number }) => {
  return getData({
    path: `${url}/get/product/halal/doc/list`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// BPOM 문서 리스트 조회
export const getBpomDocs = (info: { productIdx: number }) => {
  return getData({
    path: `${url}/get/product/bpom/doc/list`,
    params: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
export const getAlertByIdxList = (info: { idxList: Array<number> }) => {
  return getPostData({
    path: `${url}/get/alert/list`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};
