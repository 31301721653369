import { getData, getPostData } from "../../../axios/axiosUtil";
import { getJwt } from "../../../sessionStorage/getAsync";
import { auth, noAuth } from "../../../setting/setting";

const url = auth + "/user";
const jwt = () => getJwt();

// 사용자 정보 조회
export const getUserInfo = () => {
  return getData({
    path: `${url}/get/info`,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 사용자 파일 업데이트
export const updateUserInfoFile = (info: { file: any }) => {
  const formData = new FormData();
  formData.append("file", info.file);

  return getPostData({
    path: `${url}/update/file`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// 사용자 정보 업데이트
export const updateUserInfo = (info: {
  companyName: string | null;
  managerName: string | null;
  phone: string | null;
  fax: string | null;
  pw: string | null;
  orgPw: string | null;
  file: File | null;
  postNumber: string | null;
  address: string | null;
  detailAddress: string | null;
}) => {
  const formData = new FormData();

  if (info.companyName !== null)
    formData.append("companyName", info.companyName);
  if (info.managerName !== null)
    formData.append("managerName", info.managerName);
  if (info.phone !== null) formData.append("phone", info.phone);
  if (info.fax !== null) formData.append("fax", info.fax);
  if (info.pw !== null) formData.append("pw", info.pw);
  if (info.orgPw !== null) formData.append("orgPw", info.orgPw);
  if (info.file !== null) formData.append("file", info.file);
  if (info.postNumber !== null) formData.append("postNumber", info.postNumber);
  if (info.address !== null) formData.append("address", info.address);
  if (info.detailAddress !== null)
    formData.append("detailAddress", info.detailAddress);

  return getPostData({
    path: `${url}/update/info`,
    data: formData,
    header: { Authorization: jwt(), "Content-Type": "multipart/form-data" },
  });
};

// 사용자 비밀번호 업데이트
export const updateUserPassword = (info: { orgPw: string; pw: string }) => {
  return getPostData({
    path: `${url}/update/password`,
    data: info,
    header: { Authorization: jwt(), "Content-Type": "application/json" },
  });
};

// 사용자 CS 매니저 정보 조회
export const getUserCsAdminInfo = () => {
  return getData({
    path: `${url}/get/info/myadmin`,
    header: { Authorization: jwt() },
  });
};
