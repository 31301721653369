import { Link } from "react-router-dom";
import { onClickAddRemoveCss } from "../../../../utils/utils";

const CreateDoubleMenu = (
  css: any,
  url: string,
  depth: number,
  title: { key: string; url: string; iconKey: string },
  list: Array<{ key: string; url: string; iconKey: string }>
) => {
  return (
    <div className={css.elemWrapper}>
      <div
        onClick={() => {
          onClickAddRemoveCss("side-bar-sub-menu-" + title.key, css.active);
        }}
        className={
          css.sideBarElem + " " + (url.includes(title.url) && css.active)
        }
      >
        <div className={css[title.iconKey + "Icon"] + " " + css.menuIcon} />
        <span>{title.key}</span>
        <div className={css.sideBarMenuArrow}></div>
      </div>
      <div
        id={"side-bar-sub-menu-" + title.key}
        className={css.sideBarElemTwoDepth + " " + css["count" + depth]}
      >
        {list.map((data, index) => {
          return (
            <Link key={title.key + "-menu-" + index} to={data.url}>
              <div className={css.sideBarElem}>
                <div
                  className={css[data.iconKey + "Icon"] + " " + css.menuIcon}
                />
                <span>{data.key}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default CreateDoubleMenu;
