import css from "../../css/component/dashboard/_index.module.scss";
import React, { useEffect, useState } from "react";
import { PageNation } from "../../pages/main/component/pageNav";
import DateSelector from "../../dashboardPages/main/component/dateSelector";
import { useNavigate } from "react-router";
import { getProductListByUser } from "../../utils/request/user/auth/product";
import {
  getDatePrevMonth,
  getDateStringByDate,
  getNextDay,
  getTodayDateString,
} from "../../utils/utils";
import { getProductListByAdmin } from "../../utils/request/admin/product";
import { getFile } from "../../utils/request/admin/file";
interface Product {
  contractStartDate: string | null; // 계약 시작 날짜
  contractEndDate: string | null; // 계약 종료 날짜
  email: string; // 이메일 주소
  productIdx: number; // 제품 인덱스
  csAdminIdx: number | null; // 고객 서비스 관리자 인덱스
  phoneNumber: string; // 전화번호
  managerName: string; // 담당자 이름
  companyName: string; // 회사 이름
  productIngredientAnalysisUrl: string; // 제품 성분 분석 URL
  productInfoUrl: string; // 제품 정보 URL
  halalState: number; // 할랄 상태
  bpomState: number; // BPOM 상태
  productName: string; // 제품 이름
  salesAmount: number; // 판매 금액
  businessId: string; // 사업자 ID
  salesManagerIdx: number; // 영업 관리자 인덱스
  salesPersonName: string; // 영업 담당자 이름
  csManagerName: string | null; // 고객 서비스 관리자 이름
  isNew: number; // 신규 여부 (1: 신규, 0: 기존)
  insertDate: string;
}

function AdminProductSearch() {
  const navigate = useNavigate();
  const [searchDateData, setSearchDateData] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: getDatePrevMonth(), endDate: getNextDay() });
  const [searchData, setSearchData] = useState<{
    concertType: string;
    state: number;
    searchType: string;
    search: string;
    contractStartDate?: string;
    contractEndDate?: string;
    csManager?: string;
    start: number;
    count: number;
  }>({
    concertType: "전체",
    state: 0,
    searchType: "제품명",
    search: "",
    start: 0,
    count: 10,
  });
  const [productList, setProductList] = useState<Array<Product>>();
  useEffect(() => {
    getProductListByAdmin(searchData).then((res) => {
      setProductList(res.res);
    });
  }, []);
  return (
    <div className={css.productSearch}>
      <header className={css.header}>
        <h1>제품검색</h1>
        <button
          onClick={() => {
            navigate("/dashboard/product/add");
          }}
          className={css["button"] + " " + css.shadow}
        >
          <span className={css.dashboardPlusIcon + " " + css.white} />
          새제품 등록
        </button>
      </header>
      <section className={css["filter-section"]}>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>접수날짜</span>
            <DateSelector
              initialDate={{
                startDate: searchDateData.startDate,
                endDate: searchDateData.endDate,
              }}
              setData={(data: any) => {
                setSearchDateData({
                  ...data,
                });
              }}
            />{" "}
          </div>
          <div className={css["status-filters"]}>
            <button
              onClick={() => {
                setSearchData({
                  ...searchData,
                  contractStartDate: searchDateData.startDate,
                  contractEndDate: searchDateData.endDate,
                });
                alert(
                  searchDateData.startDate +
                    "~" +
                    searchDateData.endDate +
                    "으로 컨설팅 검색 기준 기간이 설정되었습니다."
                );
              }}
              className={css["button"]}
            >
              기간 설정
            </button>
            <button
              onClick={() => {
                const searchDataTemp = { ...searchData };
                delete searchDataTemp.contractStartDate;
                delete searchDataTemp.contractEndDate;
                setSearchData({
                  ...searchDataTemp,
                });
                alert("기간이 초기화되었습니다.");
              }}
              className={css["button"] + " " + css.strock}
            >
              기간 초기화
            </button>{" "}
          </div>
        </div>
        <div className={css.filters}>
          <div className={css["status-filters"]}>
            <span>검색기준</span>
            <div className={css.filterSelectWrapper}>
              <select
                onChange={(e) => {
                  const value = e.target.value as
                    | "전체"
                    | "업체명"
                    | "사업자등록번호"
                    | "제품명";
                  setSearchData({
                    ...searchData,
                    searchType: value,
                  });
                }}
                className={css.input}
              >
                <option value={"전체"}>업체명/사업자등록번호/제품명</option>
                <option value={"업체명"}>업체명</option>
                <option value={"사업자등록번호"}>사업자등록번호</option>
                <option value={"제품명"}>제품명</option>
              </select>
              <select
                onChange={(e) => {
                  const value = e.target.value as "전체" | "할랄" | "BPOM";
                  setSearchData({
                    ...searchData,
                    concertType: value,
                  });
                }}
                className={css.input}
              >
                <option value={"전체"}>분야 전체</option>
                <option value={"할랄"}>할랄</option>
                <option value={"BPOM"}>BPOM</option>
                {/*
                <option>인허가</option>
                <option>촬영</option>
                <option>언어변환</option>
                <option>디자인</option>*/}
              </select>
              <select
                onChange={(e) => {
                  const value = e.target.value as "0" | "1";
                  setSearchData({
                    ...searchData,
                    state: Number(value),
                  });
                }}
                className={css.input}
              >
                <option value="0">진행</option>
                <option value="1">완료</option>
              </select>
              <input
                style={{ width: 340 }}
                className={css.input}
                placeholder="제품명으로 검색"
                onChange={(e) => {
                  setSearchData({ ...searchData, search: e.target.value });
                }}
              ></input>
              <button
                onClick={() => {
                  getProductListByAdmin({ ...searchData, start: 0 }).then(
                    (res) => {
                      setProductList(res.res);
                    }
                  );
                }}
                className={css["button"]}
              >
                <span className={css.dashboardSearchIcon + " " + css.white} />
                검색
              </button>
              {/**
              <button className={css["button"] + " " + css.strock}>
                <span className={css.dashboardResetIcon} />
                필터 초기화
              </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className={css["table-section"]}>
        <table>
          <thead>
            <tr>
              <th>제품번호</th>

              <th>등록일</th>
              <th>제품명</th>
              <th>성분 분석 보고서 URL</th>
              <th>회사명</th>
              <th>사업자 등록번호</th>
              <th>담당자 이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>판매량</th>
              <th>할랄 상태</th>
              <th>BPOM 상태</th>
              <th>신규 여부</th>
              <th>영업 담당자 이름</th>
              <th>CS 담당자 이름</th>
              <th>계약 시작일</th>
              <th>계약 종료일</th>
            </tr>
          </thead>
          <tbody>
            {productList?.map((product, idx) => (
              <RowItem
                idx={product.productIdx}
                product={{
                  ...product,
                }}
                key={"product-" + idx}
              />
            ))}
            {productList?.length == 0 && (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={5}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <div className={css.pageNavWrapper}>
        <PageNation
          totalPages={100}
          setCurrentPage={(page) => {
            setSearchData({ ...searchData, start: (page - 1) * 10 });
            getProductListByAdmin({
              ...searchData,
              start: (page - 1) * 10,
            }).then((res) => {
              setProductList(res.res);
            });
          }}
          currentPage={searchData.start / 10 + 1}
        />
      </div>
    </div>
  );
}
const RowItem = ({ product, idx }: { product: Product; idx: number }) => {
  return (
    <tr>
      <td className={css.center}>{idx}</td>
      <td className={css.center}>
        {getDateStringByDate(new Date(product.insertDate))}
      </td>
      <td className={css.center}>
        <a
          href={
            "/admin/dashboard/product/view?idx=" +
            product.productIdx +
            "&company=" +
            product.companyName
          }
          style={{ cursor: "pointer", color: "blue" }}
        >
          {product.productName}
        </a>
      </td>
      <td className={css.center}>
        <a
          onClick={(e) => {
            e.preventDefault();
            product.productIngredientAnalysisUrl &&
              getFile({
                path: product.productIngredientAnalysisUrl,
              });
          }}
          href={product.productIngredientAnalysisUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer", color: "blue" }}
        >
          성분 보고서 다운로드
        </a>
      </td>
      <td className={css.center}>{product.companyName}</td>
      <td className={css.center}>{product.businessId}</td>
      <td className={css.center}>{product.managerName}</td>
      <td className={css.center}>{product.phoneNumber}</td>
      <td className={css.center}>{product.email}</td>
      <td className={css.center}>{product.salesAmount}</td>
      <td className={css.center}>
        {product.halalState === 0 ? (
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            대기중
          </span>
        ) : product.halalState === 1 ? (
          <span className={css.bedge + " " + css.blue + " " + css.middle}>
            진행중
          </span>
        ) : (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            완료
          </span>
        )}
      </td>
      <td className={css.center}>
        {product.bpomState === 0 ? (
          <span className={css.bedge + " " + css.white + " " + css.middle}>
            대기중
          </span>
        ) : product.bpomState === 1 ? (
          <span className={css.bedge + " " + css.blue + " " + css.middle}>
            진행중
          </span>
        ) : (
          <span className={css.bedge + " " + css.green + " " + css.middle}>
            완료
          </span>
        )}
      </td>
      <td className={css.center}>{product.isNew === 1 ? "신규" : "기존"}</td>
      <td className={css.center}>{product.salesPersonName}</td>
      <td className={css.center}>
        {product.csManagerName ? product.csManagerName : "담당자 없음"}
      </td>
      <td className={css.center}>
        {product.contractStartDate ? product.contractStartDate : "미등록"}
      </td>
      <td className={css.center}>
        {product.contractEndDate ? product.contractEndDate : "미등록"}
      </td>
    </tr>
  );
};

export default AdminProductSearch;
